import { useState, useContext } from "react";
import { callApi, getAttApiKey } from "../../common/utils";
import { AppContext } from "../../context";

const DevelopmentTesting = () => {
  const context = useContext(AppContext);
  console.log(context.userAttribs);

  const [byline, setByline] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [attributionApiTest, setAttributionApiTest] = useState("autocomplete");
  const [apiAuthors, setApiAuthors] = useState([]);
  const [updateApiAuthor, setUpdateApiAuthor] = useState({});
  const [changedUpdateApiAuthor, setChangedUpdateApiAuthor] = useState({});

  // Attribution Api Endpoint
  const attributionApiKey = getAttApiKey();

  const setChangedAttribute = (attribute, v) => {
    let a = Object.assign({}, changedUpdateApiAuthor);
    a[attribute] = v;
    setChangedUpdateApiAuthor(a);
    console.log(a);
  };

  const submitListAuthors = async (e) => {
    e.preventDefault();

    let params = {};

    let lastUpdated = document.getElementById("aApiListAuthorsLastUpdated").value;
    let authorType = document.getElementById("aApiListAuthorsType").value;
    let active = document.getElementById("aApiListAuthorsActive").value;
    let authorised = document.getElementById("aApiListAuthorsAuthorised").value;
    let authorSource = document.getElementById("aApiListAuthorsSource").value;
    let authorSearch = document.getElementById("aApiListAuthorsSearch").value;

    if (lastUpdated.trim() !== "") params.lastUpdated = lastUpdated;
    if (authorType !== "") params.authorType = authorType;
    if (active !== "") params.active = active === "true";
    if (authorised !== "") params.authorised = authorised === "true";
    if (authorSource !== "") params.authorSource = authorSource;
    if (authorSearch.trim() !== "") params.search = authorSearch;

    let listAuthorResponse = await callApi({
      path: "authorapi/authors",
      headers: {
        "x-api-key": attributionApiKey,
      },
      method: "GET",
      params: params,
      id: true,
    });
    setApiResponse(JSON.stringify(listAuthorResponse.content, null, 4));
  };

  const fetchAapiSearchData = async (e) => {
    e.preventDefault();
    const searchTerm = byline;
    if (!searchTerm) {
      alert("No byline text entered!");
      return;
    }
    let bylineResponse = await callApi({
      path: "authorapi/authors/suggest",
      headers: {
        "x-api-key": attributionApiKey,
      },
      params: {
        byline: searchTerm,
        showProcessing: true,
      },
      id: true,
    });
    console.log(bylineResponse);
    if (bylineResponse.status === 200) {
      setApiResponse(JSON.stringify(bylineResponse.content, null, 4));
    } else {
      alert(`Status code ${bylineResponse.status} returned by enedpoint`);
    }
  };

  const fetchAutocompleteData = async (e) => {
    let t = e.target.value;
    if (t.length > 0) {
      let autocompleteResponse = await callApi({
        path: "authorapi/authors/autocomplete",
        headers: {
          "x-api-key": attributionApiKey,
        },
        params: { s: t },
        id: true,
      });
      if (autocompleteResponse.status === 200) {
        setApiResponse(JSON.stringify(autocompleteResponse.content, null, 4));
      }
    } else {
      setApiResponse("");
    }
  };

  const submitBylineLookupForm = async (e) => {
    e.preventDefault();
    const searchTerm = document.getElementById("aApibylineSearch").value;
    if (!searchTerm) {
      alert("No byline text entered!");
      return;
    }
    let bylineResponse = await callApi({
      path: "authorapi/authors/suggest",
      headers: {
        "x-api-key": attributionApiKey,
      },
      params: {
        byline: searchTerm,
        showProcessing: true,
      },
      id: true,
    });
    setApiResponse(JSON.stringify(bylineResponse.content, null, 4));
  };

  const submitAddAuthorForm = async (e) => {
    e.preventDefault();
    let authorName = document.getElementById("aApiAddUserName").value;
    let authorDescription = document.getElementById("aApiAddUserDescription").value;
    let authorEmail = document.getElementById("aApiAddUserEmail").value;
    let authorAlias = document.getElementById("aApiAddUserAlias").value;
    let authorLicensingRights = document.getElementById("aApiAddUserLicensingRights").value;
    let params = {
      name: authorName,
      licensingRights: authorLicensingRights.length ? authorLicensingRights === "true" : "",
      alias: authorAlias.length ? authorAlias.split(",") : null,
      description: authorDescription,
      authorType: "contributor",
      createdUser: `${context.userAttribs["given_name"]} ${context.userAttribs["family_name"]}`,
    };
    if (authorEmail.trim().length > 0) {
      params["email"] = authorEmail;
    }
    let addAuthorResponse = await callApi({
      path: "authorapi/authors",
      method: "POST",
      headers: {
        "x-api-key": attributionApiKey,
      },
      id: true,
      params: params,
    });
    setApiResponse(JSON.stringify(addAuthorResponse.content, null, 4));
    if (addAuthorResponse.status === 200) {
      document.getElementById("aApiAddUserForm").reset();
    }
  };

  const submitUpdateAuthorForm = async (e) => {
    e.preventDefault();
    let authorId = updateApiAuthor.id;
    let params = Object.assign({}, changedUpdateApiAuthor);
    if ("alias" in params) {
      params.alias = params.alias
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item.length > 0);
    }
    params.updatedUser = `${context.userAttribs["given_name"]} ${context.userAttribs["family_name"]}`;
    let updateAuthorResponse = await callApi({
      path: `authorapi/authors/${authorId}`,
      method: "PUT",
      id: true,
      headers: {
        "x-api-key": attributionApiKey,
      },
      params: params,
    });
    setApiResponse(JSON.stringify(updateAuthorResponse.content, null, 4));
    if (updateAuthorResponse.status === 200) {
      setUpdateApiAuthor({ ...updateApiAuthor, ...params });
      setChangedUpdateApiAuthor({});
    }
  };

  const fetchAuthor = async (e) => {
    e.preventDefault();
    let authorId = document.getElementById("aApiUpdateGetAuthor").value;
    if (authorId === "") {
      alert("Please eneter an Author ID");
      return;
    }
    let getAuthorResponse = await callApi({
      path: `authorapi/authors/${authorId}`,
      method: "GET",
      id: true,
      headers: {
        "x-api-key": attributionApiKey,
      },
    });
    setApiResponse(JSON.stringify(getAuthorResponse.content, null, 4));
    if (getAuthorResponse.status === 200 && "id" in getAuthorResponse.content) {
      setUpdateApiAuthor(getAuthorResponse.content);
      setChangedUpdateApiAuthor({});
    } else {
      setUpdateApiAuthor({});
    }
  };

  const submitDeleteAuthorForm = async (e) => {
    e.preventDefault();
    let authorId = document.getElementById("aApiDeleteAuthorId").value;
    let deleteAuthorResponse = await callApi({
      path: `authorapi/authors/${authorId}`,
      method: "DELETE",
      id: true,
    });

    setApiResponse(JSON.stringify(deleteAuthorResponse.content, null, 4));
  };

  const checkSubmit = (e) => {
    if (e.key === "Enter") {
      fetchAapiSearchData();
    }
  };

  return (
    <div className={`content p-3 d-flex flex-column flex-grow-1`} style={{ height: "calc(100vh - 56px)" }}>
      <div className="row" style={{ height: "100%" }}>
        <div className="col-4 p-3" style={{ height: "100%", overflow: "hidden", overflowY: "auto" }}>
          <label htmlFor="aApiFunction" className="form-label">
            API Function
          </label>
          <select className="form-select" id="aApiFunction" onChange={(e) => setAttributionApiTest(e.target.value)}>
            <option value="autocomplete">Autocomplete</option>
            <option value="addAuthor">Add Author</option>
            <option value="updateAuthor">Update Author</option>
            <option value="deleteAuthor">Delete Author</option>
            <option value="suggest">Byline Suggest</option>
            <option value="listAuthors">List Authors</option>
          </select>
          <hr />
          <div className="mb-5" style={{ display: attributionApiTest === "autocomplete" ? "block" : "none" }}>
            <h4>Attribution API - Autocomplete</h4>
            <div className="form-floating">
              <input type="text" className="form-control" id="autocomplete" style={{ maxWidth: 500 }} onChange={fetchAutocompleteData}></input>
              <label htmlFor="autocomplete">User</label>
            </div>
            <hr />
          </div>
          <div className="mb-5" style={{ display: attributionApiTest === "addAuthor" ? "block" : "none" }}>
            <h4>Attribution API - Add Author</h4>
            <form onSubmit={submitAddAuthorForm} id="aApiAddUserForm">
              <div className="mb-3">
                <label htmlFor="aApiAddUserName" className="form-label">
                  Author Name
                </label>
                <input type="text" className="form-control" id="aApiAddUserName" placeholder="Firstname Lastname"></input>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiAddUserDescription" className="form-label">
                  Author Description
                </label>
                <input type="text" className="form-control" id="aApiAddUserDescription" placeholder="Description"></input>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiAddUserEmail" className="form-label">
                  Author Email
                </label>
                <input type="text" className="form-control" id="aApiAddUserEmail" placeholder="name@domain.com"></input>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiAddUserAlias" className="form-label">
                  Author Alias
                </label>
                <input type="text" className="form-control" id="aApiAddUserAlias" placeholder="Comma separated list eg. Santa Claus, Father Christmas"></input>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiAddUserLicensingRights" className="form-label">
                  Licensing Rights
                </label>
                <select className="form-select" id="aApiAddUserLicensingRights" defaultValue="">
                  <option></option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
            <hr />
          </div>
          <div className="mb-5" style={{ display: attributionApiTest === "updateAuthor" ? "block" : "none" }}>
            <h4>Attribution API - Update Author</h4>
            <form onSubmit={fetchAuthor}>
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Author ID" aria-label="Recipient's username" aria-describedby="button-addon2" id="aApiUpdateGetAuthor" />
                <button className="btn btn-primary" type="submit" id="button-addon2">
                  Get Author
                </button>
              </div>
            </form>
            <hr />
            <form onSubmit={submitUpdateAuthorForm} id="aApiUpdateUserForm">
              <div className="mb-3">
                <label htmlFor="aApiUpdateUserName" className="form-label">
                  Author Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="aApiUpdateUserName"
                  placeholder="Firstname Lastname"
                  value={changedUpdateApiAuthor.name || updateApiAuthor.name || ""}
                  onChange={(e) => setChangedAttribute("name", e.target.value)}
                  disabled={updateApiAuthor.authorSource && updateApiAuthor.authorSource !== "adsync" ? false : true}
                ></input>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiUpdateUserDescription" className="form-label">
                  Author Description
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="aApiUpdateUserDescription"
                  placeholder="Description"
                  value={"description" in changedUpdateApiAuthor ? changedUpdateApiAuthor.description : updateApiAuthor.description || ""}
                  onChange={(e) => setChangedAttribute("description", e.target.value)}
                  disabled={updateApiAuthor.authorSource && updateApiAuthor.authorSource !== "adsync" ? false : true}
                ></input>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiUpdateUserEmail" className="form-label">
                  Author Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="aApiUpdateUserEmail"
                  placeholder="name@domain.com"
                  value={"email" in changedUpdateApiAuthor ? changedUpdateApiAuthor.email : updateApiAuthor.email || ""}
                  onChange={(e) => setChangedAttribute("email", e.target.value)}
                  disabled={updateApiAuthor.authorSource && updateApiAuthor.authorSource !== "adsync" ? false : true}
                ></input>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiUpdateUserAlias" className="form-label">
                  Author Alias
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="aApiUpdateUserAlias"
                  placeholder="Comma separated list eg. Santa Claus, Father Christmas"
                  value={"alias" in changedUpdateApiAuthor ? changedUpdateApiAuthor.alias : updateApiAuthor.alias ? updateApiAuthor.alias.join(", ") : ""}
                  onChange={(e) => setChangedAttribute("alias", e.target.value.length ? e.target.value : "")}
                  disabled={updateApiAuthor.authorSource ? false : true}
                ></input>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiUpdateUserLicensingRights" className="form-label">
                  Licensing Rights
                </label>
                <select
                  className="form-select"
                  id="aApiUpdateUserLicensingRights"
                  value={
                    "licensingRights" in changedUpdateApiAuthor
                      ? changedUpdateApiAuthor.licensingRights.toString()
                      : "licensingRights" in updateApiAuthor
                      ? updateApiAuthor.licensingRights.toString()
                      : ""
                  }
                  onChange={(e) => setChangedAttribute("licensingRights", e.target.value !== "" ? e.target.value === "true" : "")}
                  disabled={updateApiAuthor.authorSource ? false : true}
                >
                  <option></option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary" disabled={Object.keys(changedUpdateApiAuthor).length > 0 ? false : true}>
                Submit
              </button>
            </form>
            <hr />
          </div>
          <div className="mb-5" style={{ display: attributionApiTest === "deleteAuthor" ? "block" : "none" }}>
            <h4>Attribution API - Delete Author</h4>
            <form onSubmit={submitDeleteAuthorForm} id="aApiAddUserForm">
              <div className="mb-3">
                <label htmlFor="aApiDeleteAuthorId" className="form-label">
                  Author ID
                </label>
                <input type="text" className="form-control" id="aApiDeleteAuthorId" placeholder="ID"></input>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
            <hr />
          </div>
          <div className="mb-5" style={{ display: attributionApiTest === "suggest" ? "block" : "none" }}>
            <h4>Attribution API - Byline Lookup</h4>
            <form onSubmit={submitBylineLookupForm}>
              <div className="mb-3">
                <label htmlFor="aApibylineSearch" className="form-label">
                  Byline Text
                </label>
                <textarea id="aApibylineSearch" className="form-control" placeholder="Enter Byline" style={{ height: 100, maxWidth: 500 }}></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
            <hr />
          </div>
          <div className="mb-5" style={{ display: attributionApiTest === "listAuthors" ? "block" : "none" }}>
            <h4>Attribution API - List Authors</h4>
            <form onSubmit={submitListAuthors}>
              <div className="mb-3">
                <label htmlFor="aApiListAuthorsLastUpdated" className="form-label">
                  Last Updated
                </label>
                <input type="text" className="form-control" id="aApiListAuthorsLastUpdated" placeholder="Unix timestamp eg. 1665353645"></input>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiListAuthorsSearch" className="form-label">
                  Search
                </label>
                <input type="text" className="form-control" id="aApiListAuthorsSearch" placeholder="Name, description, alias or email"></input>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiListAuthorsType" className="form-label">
                  Author Type
                </label>
                <select className="form-select" id="aApiListAuthorsType" defaultValue="">
                  <option value="">All</option>
                  <option value="contributor">Contributor</option>
                  <option value="staff">Staff</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiListAuthorsActive" className="form-label">
                  Active
                </label>
                <select className="form-select" id="aApiListAuthorsActive" defaultValue="">
                  <option value="">All</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiListAuthorsAuthorised" className="form-label">
                  Authorised
                </label>
                <select className="form-select" id="aApiListAuthorsAuthorised" defaultValue="">
                  <option value="">All</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="aApiListAuthorsSource" className="form-label">
                  Author Source
                </label>
                <select className="form-select" id="aApiListAuthorsSource" defaultValue="">
                  <option value="">All</option>
                  <option value="adsync">Active Directory</option>
                  <option value="manual">Manual</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              <button type="reset" className="btn btn-danger ms-2">
                Reset
              </button>
            </form>
            <hr />
          </div>
        </div>
        <div className="col-8 p-3" style={{ height: "100%", overflow: "hidden", overflowY: "auto" }}>
          {apiResponse !== null ? (
            <>
              <h4>Attribution API - Results</h4>
              <hr />
              <pre>{apiResponse}</pre>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default DevelopmentTesting;
