const LightBox = (props) => {
  return (
    <>
      <div className="lightbox">
        {props.thumbnailImage && (
          <>
            <img src={props.thumbnailImage} />
          </>
        )}
      </div>
      {props.children}
    </>
  );
};

export default LightBox;
