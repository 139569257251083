import { useEffect, useState, memo, useRef } from "react";
import { callApi } from "../../common/utils";
import { FaFilePdf, FaRegFilePdf, FaCode } from "react-icons/fa";
import { VscOpenPreview } from "react-icons/vsc";
import LightBox from "../common/LightBox";
import { useNavigate } from "react-router-dom";

const DpePage = ({ index, data, pageCount }) => {
  const [showContext, setShowContext] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState([0, 0]);

  const formatDate = (dateString, displayMonth = true) => {
    const dateObj = new Date(dateString);
    let year = dateObj.getFullYear();
    let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    let day = dateObj.getDate().toString().padStart(2, "0");
    let hour = dateObj.getHours();
    let minute = dateObj.getMinutes().toString().padStart(2, "0");
    let second = dateObj.getSeconds().toString().padStart(2, "0");
    let ampm = hour < 12 ? "AM" : "PM";
    if(hour === 0){
      hour = 12;
    }else if(hour > 12){
      hour = hour - 12;
    }
    hour = hour.toString();
    if (displayMonth) {
      return `${hour}:${minute}:${second} ${ampm}, ${day}/${month}`;
    } else {
      return `${hour}:${minute}:${second} ${ampm}`;
    }
  };

  const ContextMenu = (props) => {
    const ref = useRef(null);

    useEffect(() => {
      if (showContext) {
        window.addEventListener("touchmove", closeContextTouch);
        window.addEventListener("touchend", closeContextTouch);
      } else {
        window.removeEventListener("touchmove", closeContextTouch);
        window.removeEventListener("touchend", closeContextTouch);
      }
    }, [showContext]);

    const handleRightClick = (e) => {
      e.preventDefault();
      let contextWidth = ref.current.offsetWidth;
      let contextHeight = ref.current.offsetHeight;
      let clickX = e.pageX;
      let clickY = e.pageY;
      let pageWidth = document.body.clientWidth;
      let pageHeight = document.body.clientHeight;
      let contextXPos = clickX + contextWidth > pageWidth ? clickX - contextWidth : clickX;
      let contextYPos = clickY + contextHeight > pageHeight ? clickY - contextHeight : clickY;
      console.log(clickX + contextWidth, pageWidth);
      // if (e.altKey && props.data.chp_page_name) {
      if (props.data.chp_page_name) {
        setContextMenuPosition([contextXPos, contextYPos]);
        setShowContext(true);
      } else {
      }
    };

    const handleMouseMove = (e) => {
      if (showContext) {
        setShowContext(false);
      }
    };

    // Close context menu when clicked else where
    const closeContextTouch = () => {
      setShowContext(false);
    };

    const fetchContextContent = async (type) => {
      const purlRequest = await callApi({ path: `edition_preview/get_content_purl/${props.data.chp_page_name}/${type}`, params: { source: props.data.source } });
      const purl = purlRequest.content.url;
      window.open(purl, "_blank");
    };

    let navigate = useNavigate();
    const PagePreview = () => {
      navigate(`/edition_preview/page/${props.data.pubdate}/${props.data.edition}/${props.data.page_no}`);
    };

    return (
      <div onContextMenu={handleRightClick} onMouseLeave={handleMouseMove} onClick={() => setShowContext(false)}>
        {props.children}
        <ul
          ref={ref}
          className="contextMenu list-group dropdown-menu dropdown-menu-dark"
          style={{
            boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
            cursor: "pointer",
            position: "absolute",
            zIndex: showContext ? "2000" : "-1000",
            visibility: showContext ? "visible" : "hidden",
            // display: showContext ? "flex" : "none",
            left: contextMenuPosition[0],
            top: contextMenuPosition[1],
          }}
        >
          <li className="dropdown-item p-2 bg-transparent" onTouchStart={() => fetchContextContent("content")} onClick={() => fetchContextContent("content")}>
            <FaRegFilePdf className="me-2" />
            <small className="position-relative" style={{ top: "1px", fontSize: 14 }}>
              Articles &amp; Ads (Coverage)
            </small>
          </li>
          <li className="dropdown-item p-2 bg-transparent" onTouchStart={() => fetchContextContent("xml")} onClick={() => fetchContextContent("xml")}>
            <FaCode className="me-2" />
            <small className="position-relative" style={{ top: "1px", fontSize: 14 }}>
              Page Content XML
            </small>
          </li>
          <li className="dropdown-item p-2 bg-transparent align-middle" onTouchStart={PagePreview} onClick={PagePreview}>
            <VscOpenPreview className="me-2" />
            <small className="position-relative" style={{ top: "1px", fontSize: 14 }}>
              Extraction Preview
            </small>
          </li>
          <div className="dropdown-divider"></div>
          <li className="dropdown-item p-2 bg-transparent align-middle" onTouchStart={() => fetchContextContent("original_pdf")} onClick={() => fetchContextContent("original_pdf")}>
            <FaFilePdf className="me-2" />
            <small className="position-relative" style={{ top: "1px", fontSize: 14 }}>
              Page PDF (Original)
            </small>
          </li>
          <li className="dropdown-item p-2 bg-transparent align-middle" onTouchStart={() => fetchContextContent("pdf")} onClick={() => fetchContextContent("pdf")}>
            <FaFilePdf className="me-2" />
            <small className="position-relative" style={{ top: "1px", fontSize: 14 }}>
              Page PDF (Packaged)
            </small>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div className={`col-6 col-xl-2 col-xxl-2 col-xxxl-1 pageWrapper mb-3 ${data.running_page_no === 1 ? "frontPage" : data.running_page_no === pageCount ? "backPage" : ""}`}>
      <ContextMenu data={data}>
        <div
          className="page"
          style={{
            aspectRatio: data.width / data.height,
            padding: "5% 3%",
            // paddingBottom: "5%",
            display: "flex",
            justifyContent: "space-evenly",
            columnGap: "1%",
            position: "relative",
          }}
        >
          {/* Columns */}
          <div style={{ height: "100%", flexGrow: "1", border: "1px solid #CCC" }}></div>
          <div style={{ height: "100%", flexGrow: "1", border: "1px solid #CCC" }}></div>
          <div style={{ height: "100%", flexGrow: "1", border: "1px solid #CCC" }}></div>
          <div style={{ height: "100%", flexGrow: "1", border: "1px solid #CCC" }}></div>
          <div style={{ height: "100%", flexGrow: "1", border: "1px solid #CCC" }}></div>

          {data.thumbnail ? (
            // <img src={data.thumbnail} className="img-fluid" style={{ maxWidth: "auto", position: "absolute", top: 0, width: "100%", height: "100%" }} onClick={handleShow} />
            <img src={data.thumbnail} className="img-fluid" style={{ maxWidth: "auto", position: "absolute", top: 0, width: "100%", height: "100%" }} />
          ) : (
            ""
          )}
        </div>
      </ContextMenu>
      <div className={`pageInfo mt-2 ${index === 0 ? "" : index % 2 === 0 ? "ms-1" : ""}`}>
        {/* <table className="table table-sm table-striped w-100" style={{ tableLayout: "fixed" }}> */}
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Output</div>
          <div className="text-truncate">{data.output_time && formatDate(data.output_time)}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Section</div>
          <div className="text-truncate">{data.section}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Page No.</div>
          <div className="text-truncate">{data.page_no}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Running Page No.</div>
          <div className="text-truncate">{data.running_page_no}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Edition</div>
          <div className="text-truncate">{data.edition}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Stories</div>
          <div className="text-truncate">{data.edition ? data.story_count || 0 : ""}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Ads</div>
          <div className="text-truncate">{data.edition ? data.ad_count || 0 : ""}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Source</div>
          <div className="text-truncate">{data.edition && !data.source ? "Methode" : data.source}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Output Count</div>
          <div className="text-truncate">{data.output_count}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Text Extraction</div>
          <div className="text-truncate">{data.edition && data.text_extraction_required}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">In CHP?</div>
          <div className="text-truncate">{data.chp_insert_time ? `Yes (${formatDate(data.chp_insert_time, false)})` : data.edition ? "No" : ""}</div>
        </div>
      </div>
      <div className={`pageInfoCompact mt-2 ${index === 0 ? "" : index % 2 === 0 ? "ms-1" : ""}`}>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Section</div>
          <div className="text-truncate">{data.section}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Page No.</div>
          <div className="text-truncate">{data.page_no}</div>
        </div>
      </div>
    </div>
  );
};

// Only re-render when the output_time has changed, otherwise do nothing
const shouldUpdate = (prevProps, nextProps) => {
  if (prevProps.data.output_time === nextProps.data.output_time && prevProps.data.chp_insert_time === nextProps.data.chp_insert_time) {
    return true; // Don't re-render
  }
  return false; // Re-render
};

export default memo(DpePage, shouldUpdate);
