import config from "./config.json";
import { Auth } from "aws-amplify";

const environment = process.env.NODE_ENV; // production or development (start or build)
const scooter_env = process.env.REACT_APP_SCOOTER_ENV; //scooter or dev-scooter

Auth.configure({
  userPoolId: config[scooter_env][environment].cognito.userPool,
  userPoolWebClientId: config[scooter_env][environment].cognito.clientId,
  region: config[scooter_env][environment].cognito.region,
  oauth: {
    domain: config[scooter_env][environment].cognito.userPoolUri,
    scope: config[scooter_env][environment].cognito.tokenScopes,
    redirectSignIn: config[scooter_env][environment].cognito.callbackUri,
    redirectSignOut: config[scooter_env][environment].cognito.signoutUri,
    responseType: "code",
  },
  // storage: CustomChromeStorage
});

const isSignedIn = async () => {
  try {
    let us = await Auth.currentAuthenticatedUser();
    // console.log(us);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

async function federatedSignIn(provider = config[scooter_env][environment].cognito.provider) {
  let params = null;
  if (window.location.pathname.length > 1) {
    params = { provider, customState: window.location.pathname };
  } else {
    params = { provider };
  }
  return await Auth.federatedSignIn(params);
}

function getIdToken() {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((data) => {
        const idToken = data.getIdToken();
        resolve(idToken.jwtToken);
      })
      .catch(() => {
        reject(Error("Not signed in."));
      });
  });
}

function getAccessToken() {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((data) => {
        const accessToken = data.getAccessToken();
        resolve(accessToken.jwtToken);
      })
      .catch(() => {
        reject(Error("Not signed in."));
      });
  });
}

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((data) => {
        const idToken = data.getIdToken();
        const user = idToken.payload;
        resolve(user);
      })
      .catch(() => {
        reject(Error("Not signed in."));
      });
  });
}

async function ssoFederatedSignIn() {
  // const ssoUrl = `https://${config[scooter_env][environment].cognito.userPoolUri}/oauth2/authorize?identity_provider=${}`
  console.log(Auth);
}

// pass in true to sign out from all devices
async function signOut(global = false) {
  return await Auth.signOut({ global });
}

export { federatedSignIn, getCurrentUser, getIdToken, getAccessToken, signOut, isSignedIn };
