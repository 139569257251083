import DateSelector from "../components/common/dateSelector";
import PageHeader from "../components/common/pageHeader";
import DpePicker from "../components/edition_preview/EditionPicker";
import { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate, useParams } from "react-router-dom";
import { callApi } from "../common/utils";
import EditionPages from "../components/edition_preview/EditionPages";
import "../styles/Pages.scss";

const MethodeOutput = ({
  editionDate,
  setEditionDate,
  editionDateEditions,
  setEditionDateEditions,
  selectedEdition,
  setSelectedEdition,
  pageData,
  setPageData,
  defaultEditionView,
  setDefaultEditionView,
}) => {
  const formatDate = () => {
    let year = editionDate.getFullYear();
    let month = (editionDate.getMonth() + 1).toString().padStart(2, "0");
    let day = editionDate.getDate().toString().padStart(2, "0");
    return [year, month, day].join("");
  };

  const toDate = (dateString) => {
    const year = +dateString.substring(0, 4);
    const month = +dateString.substring(4, 6);
    const day = +dateString.substring(6, 8);
    const date = new Date(year, month - 1, day);
    return date;
  };

  const getEdition = async () => {
    if (editionDate && selectedEdition) {
      const editionDataRequest = await callApi({ path: `edition_preview/get_edition/${formatDate(editionDate)}/${selectedEdition}` });
      const editionData = editionDataRequest.content;

      // Only update pageData if checksum has changed
      if (!"checkSum" in pageData || pageData.checkSum !== editionData.checkSum) {
        setPageData(editionData);
        console.log("Something has changed!", pageData, editionData);
      } else {
        console.log("Nothing has changed!");
      }
    }
  };

  const getView = () => {
    switch (defaultEditionView) {
      case "compact":
        return "compactView";
      case "thumbnail":
        return "thumbnailView";
    }
    return "";
  };

  // Update DPE when editionDate or selectedEdition
  useEffect(() => {
    getEdition();
    document.getElementById("mainElement").scrollTop = 0;
  }, [editionDate, selectedEdition]);

  useEffect(() => {
    // Update UI every 30 seconds
    const interval = 120;
    const i = setInterval(() => {
      getEdition();
    }, interval * 1000);

    return () => {
      clearInterval(i);
    };
  }, [editionDate, selectedEdition, pageData]);

  // Load state from local storage
  useEffect(() => {
    const ls = JSON.parse(localStorage.getItem("defaultEditionView"));
    if (ls) {
      setDefaultEditionView(ls);
    }
  }, []);

  const editionViewChange = (view) => {
    localStorage.setItem("defaultEditionView", JSON.stringify(view));
    setDefaultEditionView(view);
  };

  return (
    <div className="p-1">
      <PageHeader
        rightContent={
          <div className="row">
            <div className="col-6 col-lg-auto">
              <DateSelector defaultDate={editionDate} onChange={setEditionDate} label="Edition Date" />
            </div>
            <div className="col-6 col-lg-auto">
              <DpePicker
                editionDate={editionDate}
                editionDateEditions={editionDateEditions}
                setSelectedEdition={setSelectedEdition}
                selectedEdition={selectedEdition}
                setEditionDateEditions={setEditionDateEditions}
              />
            </div>
          </div>
        }
        leftContent={
          <div className="d-none d-lg-block">
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio1"
                autoComplete="off"
                onChange={() => editionViewChange("detailed")}
                checked={defaultEditionView === "detailed" ? true : false}
              />
              <label className="btn btn-outline-dark" htmlFor="btnradio1">
                Details View
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio2"
                id="btnradio2"
                autoComplete="off"
                onChange={() => editionViewChange("compact")}
                checked={defaultEditionView === "compact" ? true : false}
              />
              <label className="btn btn-outline-dark" htmlFor="btnradio2">
                Compact View
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio3"
                id="btnradio3"
                autoComplete="off"
                onChange={() => editionViewChange("thumbnail")}
                checked={defaultEditionView === "thumbnail" ? true : false}
              />
              <label className="btn btn-outline-dark" htmlFor="btnradio3">
                Thumbnail View
              </label>
            </div>
          </div>
        }
      />
      <div className={`content p-1 d-flex flex-column flex-grow-1 ${getView()}`}>
        <EditionPages pageData={pageData} />
      </div>
    </div>
  );
};

export default MethodeOutput;
