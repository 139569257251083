import { callApi } from "../common/utils";
import { useEffect, useState } from "react";

const gridStyle = { minHeight: 500 };

const formatDate = (dateString, displayMonth = true) => {
  const dateObj = new Date(dateString);
  let year = dateObj.getFullYear();
  let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  let day = dateObj.getDate().toString().padStart(2, "0");
  let hour = dateObj.getHours();
  let minute = dateObj.getMinutes().toString().padStart(2, "0");
  let second = dateObj.getSeconds().toString().padStart(2, "0");
  let ampm = hour < 12 ? "AM" : "PM";
  if (hour === 0) {
    hour = 12;
  } else if (hour > 12) {
    hour = hour - 12;
  }
  hour = hour.toString();
  if (displayMonth) {
    return `${hour}:${minute}:${second} ${ampm}, ${day}/${month}`;
  } else {
    return `${hour}:${minute}:${second} ${ampm}`;
  }
};

const PageOutput = ({
  pageOutputData,
  setPageOutputData,
  lastPageDataTimestamp,
  setLastPageDataTimestamp,
}) => {
  //   const [columns, setColumns] = useState([]);
  //   const [rows, setRows] = useState([]);

  const getPageOutput = async () => {
    const pageDataRequest = await callApi({
      path: `edition_preview/get_page_output`,
    });
    const pageData = pageDataRequest.content;
    console.log(pageData);

    // Only update if pageData has changed
    if (pageData.pages && lastPageDataTimestamp != pageData.checkSum) {
      setLastPageDataTimestamp(pageData.checkSum);
      console.log("Yep ... gonna update");
      setPageOutputData(pageData.pages);
    } else {
      console.log("Nothing has changed");
    }
  };

  // Update DOM when summaryData changes
  useEffect(() => {
    getPageOutput();

    // Update UI every 30 seconds
    const interval = 30;
    const i = setInterval(() => {
      getPageOutput();
    }, interval * 1000);

    return () => {
      clearInterval(i);
    };
  }, [lastPageDataTimestamp]);

  return (
    <div className={`outputSummary content p-2 d-flex flex-column flex-grow-1`}>
      <div className="container-fluid">
        <div className="table-responsive">
          <table className="table table-striped table-hover compact">
            <thead>
              <tr>
                <th scope="col" className="text-nowrap">
                  Output Time
                </th>
                <th scope="col" className="text-nowrap">
                  Edition
                </th>
                <th scope="col" className="text-nowrap text-center">
                  Pub Date
                </th>
                <th scope="col" className="text-nowrap text-center">
                  Page No
                </th>
                <th scope="col" className="text-nowrap">
                  Section
                </th>
                <th scope="col" className="text-nowrap text-center">
                  Stories
                </th>
                <th scope="col" className="text-nowrap text-center">
                  Ads
                </th>
                <th scope="col" className="text-nowrap text-center">
                  Text Extraction Required
                </th>
                <th scope="col" className="text-nowrap text-center">
                  In CHP?
                </th>
              </tr>
            </thead>
            <tbody>
              {pageOutputData.map((page, i) => (
                <tr key={page.output_time}>
                  <th scope="row" className="text-nowrap">
                    {formatDate(page.output_time, false)}
                  </th>
                  <td className="text-nowrap">{page.edition}</td>
                  <td className="text-nowrap text-center">
                    {page.pubdate.substring(6, 8)}/
                    {page.pubdate.substring(4, 6)}/
                    {page.pubdate.substring(0, 4)}
                  </td>
                  <td className="text-nowrap text-center">{page.page_no}</td>
                  <td className="text-nowrap">{page.section}</td>
                  <td className="text-nowrap text-center">
                    {page.stories_extracted}
                  </td>
                  <td className="text-nowrap text-center">{page.ad_count}</td>
                  <td className="text-nowrap text-center">
                    {page.text_extraction_required}
                  </td>
                  <td className="text-nowrap text-center">
                    {page.chp_insert_time ? "Yes" : "No"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PageOutput;
