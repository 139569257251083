import { GrFormNext, GrFormPrevious } from "react-icons/gr";

const Pagination = ({ itemsPerPage, totalItems, currentPage, onNextPage, onPrevPage, onPageChange, className }) => {
  const totalPages = [...Array(Math.ceil(totalItems / itemsPerPage)).keys()];

  const pageChange = (event) => {
    const v = event.target.value;
    onPageChange(v);
  };

  if (totalItems > itemsPerPage) {
    return (
      <div className={`btn-toolbar justify-content-center ${className}`} role="toolbar" aria-label="Toolbar with button groups">
        <div className="btn-group me-2" role="group" aria-label="First group">
          <button type="button" className="btn btn-outline-dark" onClick={onPrevPage} disabled={currentPage === 1 ? true : false}>
            <GrFormPrevious style={{ position: "relative", top: -1 }} /> Prev
          </button>
        </div>
        <div className="input-group">
          <label className="input-group-text" htmlFor="inputGroupSelect02">
            Page
          </label>
          <select className="form-select" id="inputGroupSelect02" value={currentPage} onChange={pageChange}>
            {totalPages.map((page, i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
          <label className="input-group-text" htmlFor="inputGroupSelect02">
            of {totalPages.length}
          </label>
        </div>
        <div className="btn-group ms-2" role="group" aria-label="First group">
          <button type="button" className="btn btn-outline-dark" onClick={onNextPage} disabled={currentPage === totalPages.length ? true : false}>
            Next <GrFormNext style={{ position: "relative", top: -1 }} />
          </button>
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export default Pagination;
