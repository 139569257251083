import { FaBars } from "react-icons/fa";
import { Routes, Route, useLocation, location, matchPath, Navigate } from "react-router-dom";
import { dynamicComponent } from "./DynamicComponent";
import { Nav, Navbar, Container } from "react-bootstrap";
import ScooterHeadshot from "./assets/headshot.jpg";
import ScooterXmasHeadshot from "./assets/headshot_xmas.png";
import { useState, useEffect, useContext } from "react";
import { callApi, IsAdmin, IsMember } from "./common/utils";
import { Hub } from "aws-amplify";
import { AppContext } from "./context";

const Main = ({ handleToggleSidebar, menuItems, userAttribs, pageTitle, setCollapsed, collapsed }) => {
  // const location = useLocation();

  // // For unlogged in path redirection
  // useEffect(() => {
  //   Hub.listen("auth", async ({ payload: { event, data } }) => {
  //     console.log("Hub", event, data);
  //     switch (event) {
  //       case "customOAuthState":
  //         const originalUrl = decodeURIComponent(data);
  //         console.log("Hub", originalUrl);
  //         return <Navigate to="/jake" replace={true} />;
  //         break;
  //     }
  //   });
  //   console.log(location);
  // }, []);

  // Date pickers default date
  const defaultDate = (offset = 1) => {
    let d = new Date();
    d.setDate(d.getDate() + offset);
    return d;
  };

  // State - Home
  const [redirectHome, setRedirectHome] = useState(true);

  // State - Edition Preview / DPE Preview
  const [dpeDate, setDpeDate] = useState(defaultDate());
  const [dpeDateEditions, setDpeDateEditions] = useState([]);
  const [selectedDpe, setSelectedDpe] = useState(null);
  const [dpePageData, setDpePageData] = useState({});
  const [defaultDpeView, setDefaultDpeView] = useState("detailed");

  // State - Edition Preview / Edition Preview
  const [editionDate, setEditionDate] = useState(defaultDate());
  const [editionDateEditions, setEditionDateEditions] = useState({});
  const [selectedEdition, setSelectedEdition] = useState(null);
  const [editionPageData, setEditionPageData] = useState({});
  const [defaultEditionView, setDefaultEditionView] = useState("detailed");

  // State - Edition Preview / Output Summary
  const [summaryDate, setSummaryDate] = useState(defaultDate());
  const [summaryDisplay, setSummaryDisplay] = useState("pagesuite");
  const [summaryHideComplete, setSummaryHideComplete] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [defaultSummaryView, setDefaultSummaryView] = useState("list");

  // State - Edition Preview / Page Output
  const [pageOutputData, setPageOutputData] = useState([]);
  const [lastPageDataTimestamp, setLastPageDataTimestamp] = useState(null);

  // State NPA
  const [npaStartDate, setNpaStartDate] = useState(defaultDate(0));
  const [npaEndDate, setNpaEndDate] = useState(defaultDate(0));
  const [npaDateSelection, setNpaDateSelection] = useState("today");
  const [npaPublications, setNpaPublications] = useState([]);
  const [npaEditions, setNpaEditions] = useState([]);
  const [npaSearchRange, setNpaSearchRange] = useState(false);
  const [npaSelectedEdition, setNpaSelectedEdition] = useState("");
  const [npaSelectedPublication, setNpaSelectedPublication] = useState("");
  const [npaPageNoSearch, setNpaPageNoSearch] = useState("");
  const [npaSortBy, setNpaSortBy] = useState("edition_page");
  const [npaSortOrder, setNpaSortOrder] = useState("asc");
  const [npaPageSearchData, setNpaPageSearchData] = useState([]);
  const [npaRecordsPageNo, setNpaRecordsPageNo] = useState(1);
  const [npaCurrentSearch, setNpaCurrentSearch] = useState({});
  const [npaSectionFilter, setNpaSectionFilter] = useState("");
  const [npaAdvertisingSearchType, setNpaAdvertisingSearchType] = useState("search");
  const [npaAdvertisingId, setNpaAdvertisingId] = useState("");
  const [npaAdvertisingSearchRecord, setNpaAdvertisingSearchRecord] = useState({});
  const [advertisingSearchOptions, setAdvertisingSearchOptions] = useState([]);

  // State Attribution API / New Authors
  const [attApiNewAuthorData, setAttApiNewAuthorData] = useState(null);

  // State Attribution API / All Authors
  const [attApiAllAuthorData, setAttApiAllAuthorData] = useState(null);
  const [attApiAllAuthorFilter, setAttApiAllAuthorFilter] = useState("all");
  const [attApiAllAuthorSortBy, setAttApiAllAuthorSortBy] = useState("updated");
  const [attApiAllAuthorSortOrder, setAttApiAllAuthorSortOrder] = useState("desc");
  const [attApiAllAuthorSearch, setAttApiAllAuthorSearch] = useState("");
  const [attApiAllAuthorSearchPage, setAttApiAllAuthorSearchPage] = useState(1);
  const [attApiAllAuthorSearchParams, setAttApiAllAuthorSearchParams] = useState({});
  const [attApiAllAuthorCountTotal, setAttApiAllAuthorCountTotal] = useState("");
  const [attApiAllAuthorCountFilter, setAttApiAllAuthorCountFilter] = useState("");

  // State Attribution API / Log
  const [attApiLogData, setAttApiLogData] = useState(null);
  const [attApiLogFilter, setAttApiLogFilter] = useState("all");
  const [attApiLogSearch, setAttApiLogSearch] = useState("");
  const [attApiLogSearchPage, setAttApiLogSearchPage] = useState(1);

  // Function to determin page title based on path
  const GetPageTitle = () => {
    let currentPath = useLocation().pathname;
    for (let i = 0; i < menuItems.length; i++) {
      let mi = menuItems[i];
      if (typeof mi["url"] !== "undefined") {
        if (matchPath(mi["url"], currentPath)) {
          return mi.title;
        }
      } else {
        if (typeof mi["subMenus"] === "object") {
          for (let j = 0; j < mi.subMenus.length; j++) {
            let sm = mi.subMenus[j];
            if (matchPath(sm.url, currentPath)) {
              return mi.title + " > " + sm["title"] || "";
            }
          }
        }
      }
    }
    return "";
  };

  // To set params on dynamic <Route> objects
  const SetParams = (title) => {
    switch (title) {
      case "Home":
        return {
          redirectHome: redirectHome,
          setRedirectHome: setRedirectHome,
        };
      case "DPE Preview":
        return {
          dpeDate: dpeDate,
          setDpeDate: setDpeDate,
          dpeDateEditions: dpeDateEditions,
          setDpeDateEditions: setDpeDateEditions,
          selectedDpe: selectedDpe,
          setSelectedDpe: setSelectedDpe,
          pageData: dpePageData,
          setPageData: setDpePageData,
          defaultDpeView: defaultDpeView,
          setDefaultDpeView: setDefaultDpeView,
        };
      case "Méthode Output":
        return {
          editionDate: editionDate,
          setEditionDate: setEditionDate,
          editionDateEditions: editionDateEditions,
          setEditionDateEditions: setEditionDateEditions,
          selectedEdition: selectedEdition,
          setSelectedEdition: setSelectedEdition,
          pageData: editionPageData,
          setPageData: setEditionPageData,
          defaultEditionView: defaultEditionView,
          setDefaultEditionView: setDefaultEditionView,
        };
      case "Output Summary":
        return {
          summaryDate: summaryDate,
          setSummaryDate: setSummaryDate,
          summaryDisplay: summaryDisplay,
          setSummaryDisplay: setSummaryDisplay,
          summaryHideComplete: summaryHideComplete,
          setSummaryHideComplete: setSummaryHideComplete,
          summaryData: summaryData,
          setSummaryData: setSummaryData,
          setSelectedEdition: setSelectedEdition,
          setEditionDate: setEditionDate,
          setEditionPageData: setEditionPageData,
          defaultSummaryView: defaultSummaryView,
          setDefaultSummaryView: setDefaultSummaryView,
        };
      case "Page Output":
        return {
          pageOutputData: pageOutputData,
          setPageOutputData: setPageOutputData,
          lastPageDataTimestamp: lastPageDataTimestamp,
          setLastPageDataTimestamp: setLastPageDataTimestamp,
        };
      case "National Page Archive":
        return {
          npaStartDate: npaStartDate,
          setNpaStartDate: setNpaStartDate,
          npaEndDate: npaEndDate,
          setNpaEndDate: setNpaEndDate,
          npaPublications: npaPublications,
          setNpaPublications: setNpaPublications,
          npaEditions: npaEditions,
          setNpaEditions: setNpaEditions,
          npaSearchRange: npaSearchRange,
          setNpaSearchRange: setNpaSearchRange,
          npaSelectedEdition: npaSelectedEdition,
          setNpaSelectedEdition: setNpaSelectedEdition,
          npaSelectedPublication: npaSelectedPublication,
          setNpaSelectedPublication: setNpaSelectedPublication,
          npaPageNoSearch: npaPageNoSearch,
          setNpaPageNoSearch: setNpaPageNoSearch,
          npaSortBy: npaSortBy,
          setNpaSortBy: setNpaSortBy,
          npaSortOrder: npaSortOrder,
          setNpaSortOrder: setNpaSortOrder,
          npaPageSearchData: npaPageSearchData,
          setNpaPageSearchData: setNpaPageSearchData,
          npaPageSearchData: npaPageSearchData,
          setNpaPageSearchData: setNpaPageSearchData,
          npaDateSelection: npaDateSelection,
          setNpaDateSelection: setNpaDateSelection,
          npaRecordsPageNo: npaRecordsPageNo,
          setNpaRecordsPageNo: setNpaRecordsPageNo,
          npaCurrentSearch: npaCurrentSearch,
          setNpaCurrentSearch: setNpaCurrentSearch,
          npaSectionFilter: npaSectionFilter,
          setNpaSectionFilter: setNpaSectionFilter,
          npaAdvertisingSearchType: npaAdvertisingSearchType,
          setNpaAdvertisingSearchType: setNpaAdvertisingSearchType,
          npaAdvertisingId: npaAdvertisingId,
          setNpaAdvertisingId: setNpaAdvertisingId,
          npaAdvertisingSearchRecord: npaAdvertisingSearchRecord,
          setNpaAdvertisingSearchRecord: setNpaAdvertisingSearchRecord,
          advertisingSearchOptions: advertisingSearchOptions,
          setAdvertisingSearchOptions: setAdvertisingSearchOptions,
        };
      case "National Page Archive 2":
        return {
          npaStartDate: npaStartDate,
          setNpaStartDate: setNpaStartDate,
          npaEndDate: npaEndDate,
          setNpaEndDate: setNpaEndDate,
          npaPublications: npaPublications,
          setNpaPublications: setNpaPublications,
          npaEditions: npaEditions,
          setNpaEditions: setNpaEditions,
          npaSearchRange: npaSearchRange,
          setNpaSearchRange: setNpaSearchRange,
          npaSelectedEdition: npaSelectedEdition,
          setNpaSelectedEdition: setNpaSelectedEdition,
          npaSelectedPublication: npaSelectedPublication,
          setNpaSelectedPublication: setNpaSelectedPublication,
          npaPageNoSearch: npaPageNoSearch,
          setNpaPageNoSearch: setNpaPageNoSearch,
          npaSortBy: npaSortBy,
          setNpaSortBy: setNpaSortBy,
          npaSortOrder: npaSortOrder,
          setNpaSortOrder: setNpaSortOrder,
          npaPageSearchData: npaPageSearchData,
          setNpaPageSearchData: setNpaPageSearchData,
          npaPageSearchData: npaPageSearchData,
          setNpaPageSearchData: setNpaPageSearchData,
          npaDateSelection: npaDateSelection,
          setNpaDateSelection: setNpaDateSelection,
          npaRecordsPageNo: npaRecordsPageNo,
          setNpaRecordsPageNo: setNpaRecordsPageNo,
          npaCurrentSearch: npaCurrentSearch,
          setNpaCurrentSearch: setNpaCurrentSearch,
          npaSectionFilter: npaSectionFilter,
          setNpaSectionFilter: setNpaSectionFilter,
          npaAdvertisingSearchType: npaAdvertisingSearchType,
          setNpaAdvertisingSearchType: setNpaAdvertisingSearchType,
          npaAdvertisingId: npaAdvertisingId,
          setNpaAdvertisingId: setNpaAdvertisingId,
          npaAdvertisingSearchRecord: npaAdvertisingSearchRecord,
          setNpaAdvertisingSearchRecord: setNpaAdvertisingSearchRecord,
          advertisingSearchOptions: advertisingSearchOptions,
          setAdvertisingSearchOptions: setAdvertisingSearchOptions,
        };
      case "New Authors":
        return {
          attApiNewAuthorData: attApiNewAuthorData,
          setAttApiNewAuthorData: setAttApiNewAuthorData,
        };
      case "All Authors":
        return {
          attApiAllAuthorData: attApiAllAuthorData,
          setAttApiAllAuthorData: setAttApiAllAuthorData,
          attApiAllAuthorFilter: attApiAllAuthorFilter,
          setAttApiAllAuthorFilter: setAttApiAllAuthorFilter,
          attApiAllAuthorSortBy: attApiAllAuthorSortBy,
          setAttApiAllAuthorSortBy: setAttApiAllAuthorSortBy,
          attApiAllAuthorSortOrder: attApiAllAuthorSortOrder,
          setAttApiAllAuthorSortOrder: setAttApiAllAuthorSortOrder,
          attApiAllAuthorSearch: attApiAllAuthorSearch,
          setAttApiAllAuthorSearch: setAttApiAllAuthorSearch,
          attApiAllAuthorSearchPage: attApiAllAuthorSearchPage,
          setAttApiAllAuthorSearchPage: setAttApiAllAuthorSearchPage,
          attApiAllAuthorSearchParams: attApiAllAuthorSearchParams,
          setAttApiAllAuthorSearchParams: setAttApiAllAuthorSearchParams,
          attApiAllAuthorCountTotal: attApiAllAuthorCountTotal,
          setAttApiAllAuthorCountTotal: setAttApiAllAuthorCountTotal,
          attApiAllAuthorCountFilter: attApiAllAuthorCountFilter,
          setAttApiAllAuthorCountFilter: setAttApiAllAuthorCountFilter,
        };
      case "Activity Log":
        return {
          attApiLogData: attApiLogData,
          setAttApiLogData: setAttApiLogData,
          attApiLogFilter: attApiLogFilter,
          setAttApiLogFilter: setAttApiLogFilter,
          attApiLogSearch: attApiLogSearch,
          setAttApiLogSearch: setAttApiLogSearch,
          attApiLogSearchPage: attApiLogSearchPage,
          setAttApiLogSearchPage: setAttApiLogSearchPage,
        };

      default:
        return {};
    }
  };

  const location = useLocation();

  // Record current user activity (every 5 minutes)
  useEffect(() => {
    const UpdateUserStatus = () => {
      const statusRequest = callApi({ path: "common/user_status", params: { active_page: location.pathname }, id: true });
    };
    UpdateUserStatus();
    const interval = 300;
    const intervalId = setInterval(() => {
      UpdateUserStatus();
    }, interval * 1000);
    return () => clearInterval(intervalId);
  }, [location]);

  // For homepage redirection
  useEffect(() => {
    if (!matchPath("/", location.pathname)) {
      setRedirectHome(false);
    }
  }, []);

  // For Xmas styling
  const { isXmas } = useContext(AppContext);

  return (
    <>
      <main id="mainElement">
        <Navbar id="mainNav" bg="light" sticky="top">
          <Container fluid>
            <Navbar.Brand className="pb-0" style={{ fontSize: "1.15rem" }}>
              <div className="btn float-start ps-2 pe-3 me-3 border-end pe-auto pt-0 hamburger" onClick={() => setCollapsed(!collapsed)}>
                <FaBars />
              </div>
              <div
                className="btn float-start ps-2 pe-3 me-3 border-end pe-auto pt-0 hamburgerSmall"
                onClick={() => {
                  setCollapsed(false);
                  handleToggleSidebar(true);
                }}
              >
                <FaBars />
              </div>
              {GetPageTitle()}
            </Navbar.Brand>
            <Nav className="ml-auto d-none d-lg-flex">
              <div
                className="me-3 text-end"
                style={{
                  fontSize: 13,
                }}
              >
                <span className="fw-bold text-uppercase">
                  {userAttribs.given_name} {userAttribs.family_name}
                </span>
                <br />
                {(userAttribs.profile || "").split(" - ")[0]}
                {/* {userAttribs.profile || ""} */}
              </div>
              {isXmas === true ? (
                <img
                  src={ScooterXmasHeadshot}
                  alt=""
                  width="40"
                  height="40"
                  className="rounded-circle mx-auto d-block"
                  style={{ cursor: "pointer" }}
                  onClick={() => document.body.classList.toggle("hideSnow")}
                  title="Toggle Snow"
                />
              ) : (
                <img src={ScooterHeadshot} alt="" width="40" height="40" className="rounded-circle mx-auto d-block" />
              )}
            </Nav>
          </Container>
        </Navbar>

        {/* Page Routes */}
        {/* <div className="content p-0" style={{ height: "100%" }}> */}
        <Routes>
          {menuItems.map((menuItem, i) =>
            typeof menuItem["subMenus"] === "object" ? (
              menuItem.subMenus.map((subMenu, i) => <Route path={subMenu.url} element={dynamicComponent(subMenu.component, SetParams(subMenu.title))} key={`submenu_${i}`} />)
            ) : (
              <Route path={menuItem.url} element={dynamicComponent(menuItem.component, SetParams(menuItem.title))} key={`menu_${i}`} />
            )
          )}
        </Routes>
        {/* </div> */}
      </main>
    </>
  );
};

export default Main;
