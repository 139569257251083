import DateSelector from "../components/common/dateSelector";
import { useEffect, useState } from "react";
import { callApi, dateToYYYYMMDD } from "../common/utils";
import { FaSearch } from "react-icons/fa";
import Pagination from "../components/common/Pagination";
import "../styles/Pages.scss";
import NpaPage from "../components/npa/NpaPage";
import { Bars } from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/Bars/"
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Npa = ({
  npaStartDate,
  setNpaStartDate,
  npaEndDate,
  setNpaEndDate,
  npaPublications,
  setNpaPublications,
  npaEditions,
  setNpaEditions,
  npaSearchRange,
  setNpaSearchRange,
  npaSelectedEdition,
  setNpaSelectedEdition,
  npaSelectedPublication,
  setNpaSelectedPublication,
  npaPageNoSearch,
  setNpaPageNoSearch,
  npaSortBy,
  setNpaSortBy,
  npaSortOrder,
  setNpaSortOrder,
  npaPageSearchData,
  setNpaPageSearchData,
  npaDateSelection,
  setNpaDateSelection,
  npaRecordsPageNo,
  setNpaRecordsPageNo,
  npaCurrentSearch,
  setNpaCurrentSearch,
  npaSectionFilter,
  setNpaSectionFilter,
}) => {
  // State
  const [disabledPublicationPicker, setDisabledPublicationPicker] = useState(npaPublications.length ? false : true);
  const [disabledEditionPicker, setDisabledEditionPicker] = useState(npaEditions.length ? false : true);
  const [displayNpaLoader, setDisplayNpaLoader] = useState(false);

  const getEditions = async () => {
    const start_date = dateToYYYYMMDD(npaStartDate);
    let editionsResponse = {};
    if (npaSearchRange) {
      const end_date = dateToYYYYMMDD(npaEndDate);
      editionsResponse = await callApi({ path: "npa/get_editions", vpc: true, params: { start_date: start_date, end_date: end_date, publication: npaSelectedPublication } });
    } else {
      editionsResponse = await callApi({ path: "npa/get_editions", vpc: true, params: { start_date: start_date, publication: npaSelectedPublication } });
    }
    const editions = editionsResponse.content;
    if (editionsResponse.status !== 200) {
      alert(`CHP DB Error. Status ${editionsResponse.status} status code retuned,\n\n${editions}`);
    }
    setDisabledEditionPicker(editions.length ? false : true);
    if (!editions.includes(npaSelectedEdition)) {
      setNpaSelectedEdition("");
    }
    setNpaEditions(editions);
  };

  const getPublications = async () => {
    const start_date = dateToYYYYMMDD(npaStartDate);
    let publicationsResponse = {};
    if (npaSearchRange) {
      const end_date = dateToYYYYMMDD(npaEndDate);
      publicationsResponse = await callApi({ path: "npa/get_publications", vpc: true, params: { start_date: start_date, end_date: end_date } });
    } else {
      publicationsResponse = await callApi({ path: "npa/get_publications", vpc: true, params: { start_date: start_date } });
    }
    const publications = publicationsResponse.content;
    setDisabledPublicationPicker(publications.length ? false : true);
    if (!publications.includes(npaSelectedPublication)) {
      setNpaSelectedPublication("");
    }
    setNpaPublications(publications);
  };

  // Load Publications
  useEffect(() => {
    getPublications();
  }, [npaStartDate, npaEndDate, npaSearchRange]);

  // Load Editions
  useEffect(() => {
    if (npaSelectedPublication) {
      getEditions();
    } else {
      setNpaSelectedEdition("");
      setNpaEditions([]);
      setDisabledEditionPicker(true);
    }
  }, [npaSelectedPublication, npaPublications]);

  const validatePageNumber = () => {
    // Strip spaces from page numbers + leading and trailing commas
    const s = npaPageNoSearch.replace(/ /g, "").replace(/^\,+|\,+$/g, "");

    if (s.length) {
      let pageRanges = s.split(",");
      // Loop through comma separated pages / ranges
      for (let i = 0; i < pageRanges.length; i++) {
        let pageRange = pageRanges[i];
        let pageNumbers = pageRange.split("-");
        // Return false if more than 1 "-" character
        if (pageNumbers.length !== 1 && pageNumbers.length !== 2) {
          alert(`Page Range Error!\n\n"${pageRange}" is an invalid range.`);
          return false;
        }
        for (let j = 0; j < pageNumbers.length; j++) {
          let pageNumber = pageNumbers[j];
          if (!Number(pageNumber)) {
            if (pageNumbers.length === 1) {
              alert(`Page Number Error!\n\n"${pageNumber}" is not a number.`);
            } else {
              alert(`Page Range Error!\n\n"${pageRange}" is not a valid range.`);
            }

            return false;
          }
        }
        if (pageNumbers.length === 2) {
          if (Number(pageNumbers[1]) < Number(pageNumbers[0])) {
            alert("Page Range Error!\n\nEnd page number must be greater than start");
            return false;
          }
        }
      }
    }
    setNpaPageNoSearch(s);
    return true;
  };

  const validateSection = () => {
    // Strip spaces from sections + leading and trailing commas
    let s = npaSectionFilter.replace(/ /g, "").replace(/^\,+|\,+$/g, "");
    s = s.split(",").join(", ");
    setNpaSectionFilter(s);
    return true;
  };

  // Perform Search
  const pageSearch = () => {
    if (npaSearchRange && npaStartDate > npaEndDate) {
      alert("End date must be after start date.");
      return;
    }

    if (!validatePageNumber()) {
      document.getElementById("npaPagePicker").focus();
      return;
    }

    validateSection();

    setDisplayNpaLoader(true);

    // Remove spaces around page range
    let pageNoSearch = npaPageNoSearch.trim();
    setNpaPageNoSearch(pageNoSearch);

    // Reset page number to 1
    const pn = 1;
    setNpaRecordsPageNo(pn);

    // Scroll to top
    document.getElementById("npaWrapper").scrollTop = 0;

    const params = {
      start_date: dateToYYYYMMDD(npaStartDate),
      end_date: dateToYYYYMMDD(npaEndDate),
      publication: npaSelectedPublication,
      edition: npaSelectedEdition,
      page_no: pageNoSearch,
      date_range: npaSearchRange,
      sort_by: npaSortBy,
      sort_order: npaSortOrder,
      pagination_no: pn,
      section: npaSectionFilter,
    };

    setNpaCurrentSearch(params);

    queryPages(params);
  };

  // Paginated search
  const gotoPageSearch = (pageNum) => {
    let currentParams = npaCurrentSearch;
    currentParams.pagination_no = pageNum;

    queryPages(currentParams);

    // Update State
    setNpaCurrentSearch(currentParams);
    setNpaRecordsPageNo(Number(pageNum));

    // Scroll to top
    document.getElementById("npaWrapper").scrollTop = 0;

    console.log(pageNum);
  };

  const gotoNextPage = () => {
    const nextPageNumber = npaRecordsPageNo + 1;
    gotoPageSearch(nextPageNumber);
  };

  const gotoPrevPage = () => {
    const prevPageNumber = npaRecordsPageNo - 1;
    gotoPageSearch(prevPageNumber);
  };

  const queryPages = async (params) => {
    const pagesResponse = await callApi({ path: "npa/get_pages", vpc: true, params: params });
    const pages = pagesResponse.content;
    setNpaPageSearchData(pages);
    console.log(pages);
    setDisplayNpaLoader(false);
  };

  const onStartChange = (startDate) => {
    setNpaStartDate(startDate);
    if (npaSearchRange) {
      setNpaDateSelection("range|custom");
    } else {
      setNpaDateSelection("specificDate");
    }
  };

  const onEndChange = (endDate) => {
    setNpaEndDate(endDate);
    setNpaDateSelection("range|custom");
  };

  const getDate = (offset = 0, unit = "day") => {
    let d = new Date();
    console.log(offset, unit);
    if (unit === "week") {
      d.setDate(d.getDate() + offset * 7);
    } else if (unit === "month") {
      d.setMonth(d.getMonth() + offset);
    } else if (unit === "year") {
      d.setFullYear(d.getFullYear() + offset);
    } else {
      d.setDate(d.getDate() + offset);
    }
    console.log(d);
    return d;
  };

  // For Date Selection Change
  const onDateSelection = (d) => {
    const dateParts = d.split("|");
    setNpaSearchRange(dateParts[0] === "range" ? true : false);
    if (d === "today") {
      setNpaStartDate(getDate());
    } else if (d === "yesterday") {
      setNpaStartDate(getDate(-1));
    } else if (dateParts.length > 2 && dateParts[0] === "range" && dateParts[1] !== "custom") {
      setNpaStartDate(getDate(parseInt(dateParts[2]), dateParts[1]));
      setNpaEndDate(getDate());
    }
    setNpaDateSelection(d);
  };

  const submitOnEnter = (e) => {
    if (e.key === "Enter") {
      pageSearch();
    }
  };

  return (
    // Parent div
    <div className="d-flex" style={{ height: "100%", overflowY: "hidden" }}>
      {/* Sidebar */}
      <div className="flex-shrink-0" style={{ width: 230, backgroundColor: "#E7E9EB", overflowY: "auto", padding: 15, boxShadow: "rgb(38, 57, 77) 2px 0px 17px -10px" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 p-1">
              <h6 className="text-center">Select Date</h6>
            </div>
            <div className="col-12 p-1">
              <div className="form-floating">
                <select id="npaDateSelection" className="form-select" value={npaDateSelection} onChange={(e) => onDateSelection(e.target.value)}>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="specificDate">Specific Date</option>
                  <optgroup label="Date Range">
                    <option value="range|custom">Custom Range</option>
                    <option value="range|week|-1">Last Week</option>
                    <option value="range|month|-1">Last Month</option>
                    <option value="range|year|-1">Last Year</option>
                  </optgroup>
                </select>
                <label htmlFor="npaDateSelection">Date Selection</label>
              </div>
            </div>
            <div className="col-12 p-1">
              <DateSelector label={npaSearchRange ? "Start Date" : "Date"} defaultDate={npaStartDate} onChange={onStartChange} />
            </div>
            <div className="col-12 p-1" style={{ display: npaSearchRange ? "block" : "none" }}>
              <DateSelector label="End Date" defaultDate={npaEndDate} onChange={onEndChange} disabled={npaSearchRange ? false : true} />
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-12 p-1">
              <h6 className="text-center">Publication / Edition</h6>
            </div>
            <div className="col-12 p-1">
              <div className="form-floating">
                <select id="npaPubPicker" className="form-select" disabled={disabledPublicationPicker} value={npaSelectedPublication || ""} onChange={(e) => setNpaSelectedPublication(e.target.value)}>
                  <option value="">All</option>
                  {npaPublications.map((pub) => (
                    <option key={pub} value={pub}>
                      {pub}
                    </option>
                  ))}
                </select>
                <label htmlFor="npaPubPicker">Select a Publication</label>
              </div>
            </div>
            <div className="col-12 p-1">
              <div className="form-floating">
                <select id="npaEditionPicker" className="form-select" disabled={disabledEditionPicker} value={npaSelectedEdition || ""} onChange={(e) => setNpaSelectedEdition(e.target.value)}>
                  <option value="">All</option>
                  {npaEditions.map((ed) => (
                    <option key={ed} value={ed}>
                      {ed}
                    </option>
                  ))}
                </select>
                <label htmlFor="npaEditionPicker">Select an Edition</label>
              </div>
            </div>
            <div className="col-12 p-1">
              <div className="form-floating">
                <input type="text" className="form-control" id="npaPagePicker" value={npaPageNoSearch} onChange={(e) => setNpaPageNoSearch(e.target.value)} onKeyDown={submitOnEnter}></input>
                <label htmlFor="npaPagePicker">Page No / Range</label>
              </div>
            </div>
            <div className="col-12 p-1">
              <div className="form-floating">
                <input type="text" className="form-control" id="npaSectionFilter" value={npaSectionFilter} onChange={(e) => setNpaSectionFilter(e.target.value)} onKeyDown={submitOnEnter}></input>
                <label htmlFor="npaPagePicker">Section Name</label>
              </div>
            </div>
            <div className="col-12">
              <hr />
            </div>

            <div className="col-12 p-1">
              <h6 className="text-center">Order By</h6>
            </div>
            <div className="col-12 p-1">
              <div className="form-floating">
                <select id="npaSortOrder" className="form-select" value={npaSortBy} onChange={(e) => setNpaSortBy(e.target.value)}>
                  <option value="page">Page No</option>
                  <option value="edition_page">Edition / Page No</option>
                </select>
                <label htmlFor="npaSortOrder">Sort By</label>
              </div>
            </div>
            <div className="col-12 p-1">
              <div className="form-floating">
                <select id="npaSortOrder" className="form-select" value={npaSortOrder} onChange={(e) => setNpaSortOrder(e.target.value)}>
                  <option value="asc">Ascending</option>
                  <option value="desc">Decending</option>
                </select>
                <label htmlFor="npaSortOrder">Sort Order</label>
              </div>
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-12 p-1 text-center">
              <button type="button" className="btn btn-success" onClick={pageSearch}>
                <FaSearch style={{ position: "relative", top: -1 }}></FaSearch> Search
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Content */}
      <div id="npaWrapper" className="flex-grow-1 p-2" style={{ overflowY: displayNpaLoader ? "hidden" : "auto", position: "relative" }}>
        <div className="container-fluid">
          {displayNpaLoader && (
            <div
              id="npaLoadingOverlay"
              className="d-flex flex-column justify-content-center"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
                backgroundColor: "rgb(231, 233, 235)",
                opacity: 0.9,
              }}
            >
              <Bars height="80" width="80" color="#f8ad17" ariaLabel="bars-loading" wrapperStyle={{ marginLeft: "auto", marginRight: "auto" }} wrapperClass="p-2 loadingBars" visible={true} />
              <h3 className="text-center p-2">Searching</h3>
            </div>
          )}
          <div className="row">
            {"pages" in npaPageSearchData && npaPageSearchData.pages.map((page, i) => <NpaPage page={page} />)}
            {"count" in npaPageSearchData && npaPageSearchData.count > 54 ? (
              <>
                <hr />
                <Pagination
                  className="mb-2"
                  itemsPerPage="54"
                  totalItems={npaPageSearchData.count}
                  currentPage={npaRecordsPageNo}
                  onPageChange={gotoPageSearch}
                  onNextPage={gotoNextPage}
                  onPrevPage={gotoPrevPage}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Npa;
