const PageHeader = ({ title, rightContent, middleContent, leftContent, sticky }) => {
  return (
    <>
      <nav className="navbar justify-content-between pt-3">
        <div className="container-fluid">
          {leftContent || <div></div>}
          {middleContent}
          {rightContent}
        </div>
      </nav>
    </>
  );
};

export default PageHeader;
