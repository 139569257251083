import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from "react-pro-sidebar";
import { MdExitToApp } from "react-icons/md";
import sidebarBg from "./assets/bg2.jpg";
import { NavLink, Link } from "react-router-dom";
// import React from "react";
import { useContext } from "react";
import { dynamicComponent } from "./DynamicComponent";
import ScooterLogo from "./assets/scooter_bg.png";
import ScooterLogoSanta from "./assets/scooter_santa_bg.png";
import { signOut } from "./common/cognitoAuth";
import { IconContext } from "react-icons";
import { AppContext } from "./context";

const SideMenu = ({ toggled, handleToggleSidebar, menuItems, userAttribs, setPageTitle, collapsed }) => {
  const confirmSignout = () => {
    const doSignOut = window.confirm("Are you sure you want to log out?");
    if (doSignOut) {
      signOut();
    }
  };

  // For Xmas styling
  const { isXmas } = useContext(AppContext);

  return (
    <ProSidebar breakPoint="md" toggled={toggled} onToggle={handleToggleSidebar} collapsed={collapsed}>
      <SidebarHeader>
        <div className="menuHeaderContent">
          <Link to="/">
            <img src={isXmas === true ? ScooterLogoSanta : ScooterLogo} alt="" width="100%" className=" mx-auto d-block" />
          </Link>
          {/* <div className="userInfo">
            <span className="text-uppercase fw-bold">
              {userAttribs.given_name} {userAttribs.family_name}
            </span>
            <br />
            <span style={{ fontSize: 12 }}>{userAttribs["custom:title"]}</span>
          </div> */}
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu popperArrow={true}>
          {menuItems.map((menuItem, i) =>
            typeof menuItem["subMenus"] === "object" ? (
              <SubMenu title={menuItem.title} icon={dynamicComponent(menuItem.icon)} key={i} defaultOpen="true">
                {menuItem.subMenus.map(
                  (subMenu, i) =>
                    subMenu.hideFromMenu !== true && (
                      <MenuItem key={i}>
                        <NavLink to={subMenu.url} className={({ isActive }) => (isActive ? "selectedMenu" : undefined)} state={{ title: subMenu.title }} onClick={() => handleToggleSidebar(false)}>
                          {subMenu.title}
                        </NavLink>
                      </MenuItem>
                    )
                )}
              </SubMenu>
            ) : (
              <MenuItem
                key={i}
                icon={dynamicComponent(menuItem.icon)}
                suffix={
                  "message" in menuItem ? (
                    <span className="badge" style={{ background: "#00aafe" }}>
                      {menuItem.message}
                    </span>
                  ) : (
                    ""
                  )
                }
              >
                <NavLink to={menuItem.url} className={({ isActive }) => (isActive ? "selectedMenu" : undefined)} onClick={() => handleToggleSidebar(false)}>
                  {menuItem.title}
                </NavLink>
              </MenuItem>
            )
          )}
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          {/* <a href="https://github.com/azouaoui-med/react-pro-sidebar" target="_blank" className="sidebar-btn" rel="noopener noreferrer"> */}
          <div className="sidebar-btn btn" onClick={confirmSignout}>
            <MdExitToApp />
            <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>Logout</span>
          </div>
          {/* </a> */}
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default SideMenu;
