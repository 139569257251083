import { useEffect, useState, useContext } from "react";
import { callApi, getAttApiKey, epochToDate } from "../../common/utils";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { TbRefresh } from "react-icons/tb";
import { AppContext } from "../../context";

const NewAuthors = ({ attApiNewAuthorData, setAttApiNewAuthorData }) => {
  // Context
  const context = useContext(AppContext);

  // Attribution Api Endpoint
  const attributionApiKey = getAttApiKey();

  // Review Modal
  const [showModal, setShowModal] = useState(false);

  const [authorData, setAuthorData] = useState({});
  const [authorName, setAuthorName] = useState(null);
  const [authorEmail, setAuthorEmail] = useState(null);
  const [authorDescription, setAuthorDescription] = useState(null);
  const [authorAlias, setAuthorAlias] = useState(null);
  const [authorLicensingRights, setAuthorLicensingRights] = useState(null);

  const [similarAuthors, setSimilarAuthors] = useState(null);

  // Get New Authors Function
  const getNewAuthors = async () => {
    const newAuthorsResponse = await callApi({
      path: "authorapi/authors",
      params: { authorised: false, active: true },
      headers: {
        "x-api-key": attributionApiKey,
      },
      jwt: true,
      id: true,
    });

    const newAuthors = newAuthorsResponse.content;

    if (newAuthorsResponse.status !== 200) {
      alert(`Status ${newAuthorsResponse.status} status code retuned,\n\n${newAuthors}`);
      return;
    }

    console.log(newAuthors);
    if ("results" in newAuthors) {
      setAttApiNewAuthorData(newAuthors.results);
    }
  };

  useEffect(() => {
    getNewAuthors();
  }, []);

  const reviewAuthor = async (authorId) => {
    // Get Author from API
    const authorResponse = await callApi({
      path: `authorapi/authors/${authorId}`,
      headers: {
        "x-api-key": attributionApiKey,
      },
      id: true,
    });
    const author = authorResponse.content;
    if (authorResponse.status !== 200) {
      alert(`Status ${authorResponse.status} status code retuned,\n\n${author}`);
      return;
    }

    // Check author still needs authorising
    if (author.authorised === true) {
      alert(`User ${author.name} has already been authorised.`);
      getNewAuthors();
      return;
    }

    setAuthorData(author);
    setAuthorName(author.name);
    setAuthorEmail(author.email);
    setAuthorDescription(author.description);
    setAuthorAlias(author.alias);
    setAuthorLicensingRights(author.licensingRights);

    setShowModal(true);
    getSimilarAuthors(author.id, author.name, author.email);

    console.log(author);
  };

  const getSimilarAuthors = async (id, name, email) => {
    // Get similar authors from API
    console.log(authorName);
    const similarAuthorsResponse = await callApi({
      path: `authorapi/authors/similar`,
      headers: {
        "x-api-key": attributionApiKey,
      },
      params: {
        id: id,
        name: name,
        email: email,
      },
      id: true,
    });
    const similarAuthors = similarAuthorsResponse.content;

    if (similarAuthorsResponse.status !== 200) {
      alert(`Status ${similarAuthorsResponse.status} status code retuned.`);
      return;
    }

    console.log(similarAuthors);

    setSimilarAuthors(similarAuthors);
  };

  const authoriseAuthor = async () => {
    let params = {
      authorised: true,
    };

    // Check if name has changed
    if (authorData.name !== authorName) {
      params.name = authorName;
    }

    // Check if email has changed
    if (authorData.email !== authorEmail) {
      params.email = authorEmail;
    }

    // Check if alias has changed
    if (authorData.alias.join() !== authorAlias.join()) {
      params.alias = authorAlias;
    }

    // Check if licensing rights have changed
    if (authorData.licensingRights !== authorLicensingRights) {
      params.licensingRights = authorLicensingRights;
    }

    // Check if description has changed
    if (authorData.description !== authorDescription) {
      params.description = authorDescription;
    }

    params.updatedUser = `${context.userAttribs["given_name"]} ${context.userAttribs["family_name"]}`;

    const authoriseAuthorResponse = await callApi({
      path: `authorapi/authors/${authorData.id}`,
      id: true,
      method: "PUT",
      params: params,
      headers: {
        "x-api-key": attributionApiKey,
      },
    });

    const authoriseAuthor = authoriseAuthorResponse.content;
    if (authoriseAuthorResponse.status === 200) {
      getNewAuthors();
      closeNewAuthorModal();
    } else {
      alert(`Error updating user. \n\n${authoriseAuthor.message}`);
    }

    console.log(params);
  };

  const deleteAuthor = async () => {
    let c = window.confirm(`Are you sure you want to delete ${authorData.name}`);
    if (!c) return;

    const deleteAuthorResponse = await callApi({
      path: `authorapi/authors/${authorData.id}`,
      id: true,
      method: "DELETE",
    });

    const deleteAuthor = deleteAuthorResponse.content;
    if (deleteAuthorResponse.status === 200) {
      getNewAuthors();
      closeNewAuthorModal();
    } else {
      alert(`Error deleting user. \n\n${deleteAuthor.message}`);
    }
  };

  const closeNewAuthorModal = () => {
    setSimilarAuthors(null);
    setAuthorData({});
    setAuthorName(null);
    setAuthorEmail(null);
    setAuthorDescription(null);
    setAuthorAlias(null);
    setAuthorLicensingRights(null);
    setShowModal(false);
  };

  return (
    <div className="p-3 d-flex flex-column flex-grow-1" style={{ position: "relative" }}>
      {attApiNewAuthorData !== null &&
        (attApiNewAuthorData.length ? (
          <div className="table-responsive">
            <table className="table table-striped align-middle table-hover compact fixed">
              <thead>
                <tr>
                  <th scope="col" className="text-nowrap" style={{ width: 180 }}>
                    Name
                  </th>
                  <th scope="col" className="text-nowrap" style={{ width: 180 }}>
                    Email
                  </th>
                  <th scope="col">Description</th>
                  {/* <th scope="col" className="text-nowrap">
                    Licensing Rights
                  </th> */}
                  <th scope="col" className="text-nowrap" style={{ width: 200 }}>
                    Created By
                  </th>
                  <th scope="col" className="text-nowrap" style={{ width: 200 }}>
                    Created
                  </th>
                  <th scope="col" className="text-nowrap" style={{ width: 180 }}>
                    Author Type
                  </th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {attApiNewAuthorData.map((author, i) => (
                  // New Authors
                  <tr key={author.id} onClick={() => reviewAuthor(author.id)} style={{ cursor: "pointer" }}>
                    <td className="text-nowrap">{author.name}</td>
                    <td className="text-nowrap">{author.email}</td>
                    <td className="text-nowrap text-truncate">{author.description}</td>
                    {/* <td>{author.licensingRights.toString()}</td> */}
                    <td className="text-nowrap">{author.createdUser}</td>
                    <td className="text-nowrap">{epochToDate(author.created).toString()}</td>
                    <td className="text-nowrap">{author.authorType}</td>
                    {/* <td>
                      <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => reviewAuthor(author.id)}>
                        Review
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex flex-column justify-content-top text-center"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              paddingTop: "5%",
            }}
          >
            <h4>No New Authors</h4>
          </div>
        ))}

      {/* Review Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} scrollable="true" size="lg">
        <Modal.Header>
          <Modal.Title>
            <h5>Confirm Author - {authorData.name}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-floating mb-3">
            <input type="text" className="form-control" value={authorName} placeholder="Firstname Lastname" onChange={(e) => setAuthorName(e.target.value)} />
            <label>Name</label>
          </div>
          <div className="form-floating mb-3">
            <input type="email" className="form-control" value={authorEmail} placeholder="name@example.com" onChange={(e) => setAuthorEmail(e.target.value)} />
            <label>Email address</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" className="form-control" defaultValue={authorDescription} placeholder="description" onChange={(e) => setAuthorDescription(e.target.value)} />
            <label>Description</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              defaultValue={authorAlias && authorAlias.join(", ")}
              placeholder="alias"
              onChange={(e) => setAuthorAlias(e.target.value.split(",").map((item) => item.trim()))}
            />
            <label>Alias</label>
          </div>
          <div className="form-check mb-3">
            <input
              id="newAuthorModalLicensingRights"
              className="form-check-input"
              type="checkbox"
              value=""
              checked={authorLicensingRights}
              onChange={(e) => setAuthorLicensingRights(e.target.checked)}
            />
            <label htmlFor="newAuthorModalLicensingRights" className="form-check-label user-select-none">
              Licensing Rights
            </label>
          </div>
          <hr className="mb-4 mt-4" />
          <div className="float-start">
            <h5>Similar Existing Authors</h5>
          </div>
          <div className="float-end">
            <button type="button" className="btn btn-light btn-sm" onClick={() => getSimilarAuthors(authorData.id, authorName, authorEmail)}>
              <TbRefresh className="me-1" style={{ top: -1, position: "relative" }} />
              Refresh
            </button>
          </div>
          <table className="table table-striped align-middle">
            <thead>
              <tr>
                <th scope="col" className="text-nowrap">
                  Name
                </th>
                <th scope="col" className="text-nowrap">
                  Email
                </th>
                <th scope="col" className="text-nowrap">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              {similarAuthors !== null ? (
                similarAuthors.length ? (
                  similarAuthors.map((similarAuthor, i) => (
                    <tr>
                      <td className="text-nowrap">{similarAuthor.name}</td>
                      <td>{similarAuthor.email}</td>
                      <td>{similarAuthor.description}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3} className="text-center">
                      No similar authors found.
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    Checking ...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={authoriseAuthor}>
            Authorise
          </Button>
          <Button variant="danger" onClick={deleteAuthor}>
            Delete
          </Button>
          <Button variant="secondary" onClick={closeNewAuthorModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewAuthors;
