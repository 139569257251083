import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useId, useRef } from "react";
import { filter } from "lodash";

// Date pickers default date
const defaultDate = () => {
  let d = new Date();
  d.setDate(d.getDate() + 1);
  return d;
};

// Outside component to resolve focus issues
// https://github.com/Hacker0x01/react-datepicker/issues/2051#issuecomment-832719303
const CustomDateInputFloating = forwardRef((props, ref) => {
  const label = props.label;
  const filteredProps = Object.fromEntries(Object.entries(props).filter(([key]) => key !== "label"));
  // console.log(filteredProps);
  return (
    <div className="form-floating">
      <input ref={ref} {...filteredProps}></input>
      {label && <label htmlFor={props.id}>{props.label}</label>}
    </div>
  );
});

// Outside component to resolve focus issues
// https://github.com/Hacker0x01/react-datepicker/issues/2051#issuecomment-832719303
const CustomDateInput = forwardRef((props, ref) => {
  const label = props.label;
  const filteredProps = Object.fromEntries(Object.entries(props).filter(([key]) => key !== "label"));
  // console.log(filteredProps);
  return (
    <div>
      {label && (
        <label className="form-label" htmlFor={props.id}>
          {props.label}
        </label>
      )}
      <input ref={ref} {...filteredProps}></input>
    </div>
  );
});

const DateSelector = ({ defaultDate, onChange, label, disabled = false, floating = true, small = false }) => {
  const uid = useId();

  const doOnChange = (date) => {
    if (onChange) {
      onChange(date);
    }
  };

  const CustomDateInputOld = forwardRef((props, ref) => (
    <div className="form-floating">
      <input ref={ref} {...props}></input>
      <label htmlFor={props.id}>{label}</label>
    </div>
  ));

  const inputRef = useRef(null);

  return floating === true ? (
    <DatePicker
      customInput={<CustomDateInputFloating label={label} />}
      id={uid}
      disabled={disabled}
      className={small === true ? "form-control form-control-sm" : "form-control"}
      dateFormat="dd/MM/yyyy"
      selected={defaultDate}
      onChange={(date) => doOnChange(date)}
    ></DatePicker>
  ) : (
    <DatePicker
      customInput={<CustomDateInput label={label} />}
      id={uid}
      disabled={disabled}
      className={small === true ? "form-control form-control-sm" : "form-control"}
      dateFormat="dd/MM/yyyy"
      selected={defaultDate}
      onChange={(date) => doOnChange(date)}
    ></DatePicker>
  );
};

DateSelector.defaultProps = {
  defaultDate: defaultDate(),
  label: "Unknown Label",
};

export default DateSelector;
