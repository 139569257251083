import ScooterHeadshot from "../assets/scooter_sml.png";
import { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../context";

const Home = ({ redirectHome, setRedirectHome }) => {
  const context = useContext(AppContext);

  useEffect(() => {
    if (redirectHome) {
      setRedirectHome(false);
    }
  }, []);

  if (redirectHome) {
    if (
      "custom:groups" in context.userAttribs &&
      (context.userAttribs["custom:groups"].includes("RGRP_okta_Scooter_Editionviewer_User") || context.userAttribs["custom:groups"].includes("RGRP_okta_Scooter_Admin_User"))
    ) {
      return <Navigate to="/npa" replace={true} />;
      // return <Navigate to="/edition_preview/dpe_preview" replace={true} />;
    } else {
      return <Navigate to="/npa" replace={true} />;
    }
  } else {
    return (
      <div>
        <div className="text-center">
          {/* <img src={ScooterHeadshot} className="mx-auto pb-4 pt-5 mt-5 scooterHeadshot" /> */}
          <h5 className="mt-5">Homepage Coming Soon ...</h5>
        </div>
      </div>
    );
  }
};

export default Home;
