import PageHeader from "../../components/common/pageHeaderNew";
import { Bars } from "react-loader-spinner";
import { useState, useEffect, useRef, useContext } from "react";
import { callApi, getAttApiKey, epochToDate } from "../../common/utils";
import { TiArrowSortedDown as SortDesc, TiArrowSortedUp as SortAsc } from "react-icons/ti";
import Pagination from "../../components/common/Pagination";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AppContext } from "../../context";

const AllAuthors = ({
  attApiAllAuthorData,
  setAttApiAllAuthorData,
  attApiAllAuthorFilter,
  setAttApiAllAuthorFilter,
  attApiAllAuthorSortBy,
  setAttApiAllAuthorSortBy,
  attApiAllAuthorSortOrder,
  setAttApiAllAuthorSortOrder,
  attApiAllAuthorSearch,
  setAttApiAllAuthorSearch,
  attApiAllAuthorSearchPage,
  setAttApiAllAuthorSearchPage,
  attApiAllAuthorCountTotal,
  setAttApiAllAuthorCountTotal,
  attApiAllAuthorCountFilter,
  setAttApiAllAuthorCountFilter,
  attApiAllAuthorSearchParams,
  setAttApiAllAuthorSearchParams,
}) => {
  // Context
  const context = useContext(AppContext);

  // Attribution Api Endpoint
  const attributionApiKey = getAttApiKey();

  // Search defaults
  const hits = 100;

  // State
  const [displayAttApiLoader, setDisplayAttApiLoader] = useState(false);
  const [loaderText, setLoaderText] = useState("Loading Authors");
  const [firstRender, setFirstRender] = useState(true);

  // Modal
  const [showModal, setShowModal] = useState(false);

  const [authorData, setAuthorData] = useState({});
  const [authorName, setAuthorName] = useState(null);
  const [authorEmail, setAuthorEmail] = useState(null);
  const [authorDescription, setAuthorDescription] = useState(null);
  const [authorAlias, setAuthorAlias] = useState(null);
  const [authorLicensingRights, setAuthorLicensingRights] = useState(null);
  const [authorSource, setAuthorSource] = useState(null);
  const [authorActive, setAuthorActive] = useState(null);
  const [authorType, setAuthorType] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  // Search Reference
  const searchRef = useRef();

  const capitalizeFirstChar = (str) => {
    if (!str) return str; // Handle empty strings
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const baseSearchParams = () => {
    return {
      limit: hits,
      page: attApiAllAuthorSearchPage,
      orderBy: attApiAllAuthorSortOrder,
      sortBy: attApiAllAuthorSortBy,
      active: true,
    };
  };

  // For pressing enter on Search
  const onAuthorSearch = (e) => {
    e.preventDefault();
    let searchTerm = searchRef.current.value.trim();
    setAttApiAllAuthorSearch(searchTerm);
    let params = { page: 1, search: searchTerm };
    let splitFilter = attApiAllAuthorFilter.split("|");
    if (splitFilter.length > 1) {
      for (let i = 0; i < splitFilter.length; i += 2) {
        params[splitFilter[i]] = splitFilter[i + 1];
      }
    }
    getAuthors(getSearchParams(params));
  };

  const deleteAuthor = async () => {
    let c = window.confirm(`Are you sure you want to delete ${authorName}?`);
    if (c) {
      // Get author id
      const authorId = authorData.id;

      const deleteAuthorResponse = await callApi({
        path: `authorapi/authors/${authorId}`,
        id: true,
        method: "DELETE",
      });

      const deleteAuthorContent = deleteAuthorResponse.content;
      if (deleteAuthorResponse.status === 200) {
        getAuthors(getSearchParams(), true, true);
        closeEditAuthorModal();
      } else {
        alert(`Error deleting user. \n\n${deleteAuthorContent.message}`);
      }
    }
  };

  const editAuthor = async () => {
    let params = {};

    // Editing of these fields only for manual users
    if (authorSource === "manual") {
      // Check if name has changed
      if (authorData.name !== authorName) {
        params.name = authorName;

        // Check if email has changed
        if (authorData.email !== authorEmail) {
          params.email = authorEmail;
        }
      }
    } else {
      // Only for staff
      // Check if authorType has changed
      if (authorData.authorType !== authorType) {
        params.authorType = authorType;
      }
    }

    // Check if alias has changed
    let aliases = authorAlias.filter((item) => item !== "");
    if (authorData.alias.join() !== aliases.join()) {
      params.alias = aliases;
    }

    // Check if licensing rights have changed
    if (authorData.licensingRights !== authorLicensingRights) {
      params.licensingRights = authorLicensingRights;
    }

    // Check if description has changed
    if (authorData.description !== authorDescription) {
      params.description = authorDescription;
    }

    params.updatedUser = `${context.userAttribs["given_name"]} ${context.userAttribs["family_name"]}`;

    const editAuthorResponse = await callApi({
      path: `authorapi/authors/${authorData.id}`,
      id: true,
      method: "PUT",
      params: params,
      headers: {
        "x-api-key": attributionApiKey,
      },
    });

    const editAuthorContent = editAuthorResponse.content;
    if (editAuthorResponse.status === 200) {
      getAuthors(getSearchParams());
      closeEditAuthorModal();
    } else {
      alert(`Error updating user. \n\n${editAuthorContent.message}`);
    }

    console.log(params);
  };

  const onAuthorFilter = (filter) => {
    setAttApiAllAuthorFilter(filter);
    let splitFilter = filter.split("|");
    let params = { page: 1 };
    // if (splitFilter.length === 2) {
    //   params[splitFilter[0]] = splitFilter[1];
    // }
    if (splitFilter.length > 1) {
      for (let i = 0; i < splitFilter.length; i += 2) {
        params[splitFilter[i]] = splitFilter[i + 1];
      }
    }
    getAuthors(getSearchParams(params, true));
  };

  // Column Sorting
  const onColumnSort = (col) => {
    let sortOrder = "asc";
    if (col === attApiAllAuthorSortBy) {
      sortOrder = attApiAllAuthorSortOrder === "desc" ? "asc" : "desc";
      setAttApiAllAuthorSortOrder(sortOrder);
    } else {
      setAttApiAllAuthorSortBy(col);
      setAttApiAllAuthorSortOrder(sortOrder);
    }
    getAuthors(getSearchParams({ orderBy: sortOrder, sortBy: col }));
  };

  const onSetPage = (pageNum) => {
    setAttApiAllAuthorSearchPage(pageNum);
    getAuthors(getSearchParams({ page: pageNum }));
  };

  const getSearchParams = (params = {}, updateFilter = false) => {
    let currentParams = baseSearchParams();

    if (updateFilter === false) {
      if (attApiAllAuthorFilter.split("|").length === 2) {
        let v = attApiAllAuthorFilter.split("|");
        currentParams[v[0]] = v[1];
      }
    }

    if (attApiAllAuthorSearch.trim().length > 0) {
      currentParams["search"] = attApiAllAuthorSearch.trim();
    }

    // Merge new params
    console.log(currentParams, params);
    let newParams = { ...currentParams, ...params };
    console.log(newParams);

    // Remove search if empty
    if ("search" in newParams && newParams.search.length === 0) {
      delete newParams.search;
    }

    return newParams;
  };

  // Get New Authors Function
  const getAuthors = async (params, displayLoader = true, forceCountUpdate = false) => {
    if (displayLoader) setDisplayAttApiLoader(true);
    const authorsResponse = await callApi({
      path: "authorapi/authors",
      params: params,
      headers: {
        "x-api-key": attributionApiKey,
      },
      id: true,
    });

    const authors = authorsResponse.content;

    if (authorsResponse.status !== 200) {
      alert(`Status ${authorsResponse.status} status code retuned,\n\n${authors}`);
      return;
    }

    console.log(authors);
    if ("results" in authors) {
      setAttApiAllAuthorData(authors);
      getAuthorTotals(authors.count, true, forceCountUpdate);
    }
    if (displayLoader) setDisplayAttApiLoader(false);
  };

  const getAuthorTotals = async (filterCount, forceUpdate = false) => {
    // First render
    if (attApiAllAuthorCountTotal === "") {
      setAttApiAllAuthorCountTotal(filterCount);
    } else {
      setAttApiAllAuthorCountFilter(filterCount);
    }
    if (forceUpdate === true) {
      const countResponse = await callApi({
        path: "authorapi/authors/count",
        headers: {
          "x-api-key": attributionApiKey,
        },
        id: true,
      });
      if (countResponse.status === 200) {
        setAttApiAllAuthorCountTotal(countResponse.content.active);
      }
    }
    setAttApiAllAuthorCountFilter(filterCount);
  };

  const openEditAuthor = async (authorId) => {
    // Get Author from API
    const authorResponse = await callApi({
      path: `authorapi/authors/${authorId}`,
      headers: {
        "x-api-key": attributionApiKey,
      },
      id: true,
    });
    const author = authorResponse.content;
    if (authorResponse.status !== 200) {
      alert(`Status ${authorResponse.status} status code retuned,\n\n${author}`);
      return;
    }

    setAuthorData(author);
    setAuthorName(author.name);
    setAuthorEmail(author.email);
    setAuthorDescription(author.description);
    setAuthorAlias(author.alias);
    setAuthorLicensingRights(author.licensingRights);
    setAuthorSource(author.authorSource);
    setAuthorType(author.authorType);
    setAuthorActive(author.active);
    setShowDeleteButton(author.authorSource === "adsync" ? false : author.active ? true : false);


    setShowModal(true);

    console.log(author);
  };

  const closeEditAuthorModal = () => {
    setAuthorData({});
    setAuthorName("");
    setAuthorEmail("");
    setAuthorDescription("");
    setAuthorAlias("");
    setAuthorLicensingRights("");
    setAuthorSource("");
    setAuthorType("");
    setShowModal(false);
  };

  // Fetch authors on page load
  useEffect(() => {
    if (attApiAllAuthorData === null) {
      getAuthors(baseSearchParams());
    }
  }, []);

  return (
    <div className="d-flex" style={{ height: "100%", overflowY: "hidden" }}>
      <div className="p1 d-flex flex-column flex-grow-1">
        <PageHeader
          leftContent={
            <div className="row">
              <div className="col-6 col-lg-auto ps-4">
                <strong style={{ fontSize: "0.9rem" }}>
                  Total Authors: {attApiAllAuthorCountTotal}{" "}
                  {(attApiAllAuthorFilter !== "all" || (attApiAllAuthorFilter === "all" && attApiAllAuthorSearch !== "")) &&
                    attApiAllAuthorCountTotal !== attApiAllAuthorCountFilter &&
                    attApiAllAuthorCountFilter !== "" && <>({attApiAllAuthorCountFilter} filtered)</>}
                </strong>
              </div>
            </div>
          }
          rightContent={
            <form onSubmit={onAuthorSearch}>
              <div className="row">
                <div className="col-6 col-lg-auto">
                  <div className="form-floating">
                    <select id="attApiAuthorFilter" className="form-select" onChange={(e) => onAuthorFilter(e.target.value)} value={attApiAllAuthorFilter}>
                      <option value="all">All Authors</option>
                      <optgroup label="Author Type">
                        <option value="authorType|staff">Staff</option>
                        <option value="authorType|contributor">Contributors</option>
                      </optgroup>
                      <optgroup label="Licensed">
                        <option value="licensingRights|true">Licensed</option>
                        <option value="licensingRights|false">Unlicensed</option>
                      </optgroup>
                      <optgroup label="Author Source">
                        <option value="authorSource|adsync">Active Directory</option>
                        <option value="authorSource|manual">Manually Added</option>
                      </optgroup>
                      <optgroup label="Deleted Authors">
                        <option value="active|false|authorType|staff">Deleted Staff</option>
                        <option value="active|false|authorType|contributor">Deleted Contributors</option>
                        <option value="active|false">Deleted All</option>
                      </optgroup>
                    </select>
                    <label htmlFor="attApiAuthorFilter">Filter Authors</label>
                  </div>
                </div>
                <div className="col-6 col-lg-auto">
                  <div className="form-floating">
                    <input type="text" className="form-control" ref={searchRef} id="attApiAuthorSearch" placeholder="Search" defaultValue={attApiAllAuthorSearch}></input>
                    <label htmlFor="attApiAuthorSearch">Search</label>
                  </div>
                </div>
              </div>
            </form>
          }
        />
        <div className={`content p-3 d-flex flex-column flex-grow-1 overflow-auto`} style={{ position: "relative" }}>
          {displayAttApiLoader && (
            <div
              className="d-flex flex-column justify-content-center"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
                backgroundColor: "rgb(231, 233, 235)",
                opacity: 0.9,
              }}
            >
              <Bars height="80" width="80" color="#f8ad17" ariaLabel="bars-loading" wrapperStyle={{ marginLeft: "auto", marginRight: "auto" }} wrapperClass="p-2 loadingBars" visible={true} />
              <h3 className="text-center p-2">{loaderText}</h3>
            </div>
          )}
          {attApiAllAuthorData !== null ? (
            attApiAllAuthorData.results && attApiAllAuthorData.results.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-striped align-middle table-hover fixed compact">
                  <thead>
                    <tr>
                      <th scope="col" className="text-nowrap" style={{ cursor: "pointer", width: 250 }} onClick={() => onColumnSort("name")}>
                        Name {attApiAllAuthorSortBy === "name" ? attApiAllAuthorSortOrder === "desc" ? <SortDesc style={{ float: "right" }} /> : <SortAsc style={{ float: "right" }} /> : ""}
                      </th>
                      <th scope="col" className="text-nowrap" style={{ cursor: "pointer", width: 210 }} onClick={() => onColumnSort("updated")}>
                        Updated {attApiAllAuthorSortBy === "updated" ? attApiAllAuthorSortOrder === "desc" ? <SortDesc style={{ float: "right" }} /> : <SortAsc style={{ float: "right" }} /> : ""}
                      </th>
                      <th scope="col" className="text-nowrap" style={{ width: 270 }}>
                        Email
                      </th>
                      <th scope="col">Description</th>
                      <th scope="col" style={{ width: 140 }}>
                        Aliases
                      </th>
                      <th scope="col" className="text-nowrap" style={{ width: 120 }}>
                        Author Type
                      </th>
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {attApiAllAuthorData.results.map((author, i) => (
                      <tr key={author.id} style={{ cursor: "pointer" }} onClick={() => openEditAuthor(author.id)} className={author.authorised === false ? "inactiveAuthor" : ""}>
                        <td className="text-nowrap" title={author.name}>
                          {author.name}
                        </td>
                        <td className="text-nowrap" title={epochToDate(author.updated).toString()}>
                          {epochToDate(author.updated).toString()}
                        </td>
                        <td className="text-nowrap" title={author.email}>
                          {author.email}
                        </td>
                        <td className="text-nowrap" title={author.description}>
                          {author.description}
                        </td>
                        <td className="text-truncate" style={{ maxWidth: 200 }} title={author.alias.join(", ")}>
                          {author.alias.join(", ")}
                        </td>
                        <td className="text-nowrap" title={author.authorType}>
                          {author.authorType}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {attApiAllAuthorData.count > hits && (
                  <>
                    <Pagination
                      className="mb-2 mt-4"
                      itemsPerPage={hits}
                      totalItems={attApiAllAuthorData.count}
                      currentPage={attApiAllAuthorSearchPage}
                      onPageChange={(e) => onSetPage(e)}
                      onNextPage={() => onSetPage(attApiAllAuthorSearchPage + 1)}
                      onPrevPage={() => onSetPage(attApiAllAuthorSearchPage - 1)}
                    />
                  </>
                )}
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-top text-center"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                  paddingTop: "5%",
                }}
              >
                <h4>No Authors Found</h4>
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} scrollable="true" size="md">
        <Modal.Header>
          <Modal.Title>
            <h5>Edit Author - {authorData.name}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-floating mb-3">
            {authorSource === "adsync" && authorActive === true ? (
              <select className="form-select" value={authorType} disabled={authorSource === "adsync" ? false : true} onChange={(e) => setAuthorType(e.target.value)}>
                <option value="contributor">Contributor</option>
                <option value="staff">Staff</option>
              </select>
            ) : (
              <input type="text" readOnly className="form-control-plaintext" placeholder="" value={capitalizeFirstChar(authorType)} />
            )}
            <label>Author Type</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" readOnly className="form-control-plaintext" placeholder="" value={authorSource === "adsync" ? "Active Directory" : "Manually Added"} />
            <label>Author Source</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" readOnly className="form-control-plaintext" placeholder="" value={authorData.id || ""} />
            <label>Author ID</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className={authorSource === "adsync" || authorActive === false ? "form-control-plaintext" : "form-control"}
              value={authorName}
              placeholder="Firstname Lastname"
              onChange={(e) => setAuthorName(e.target.value)}
              readOnly={authorSource === "adsync" ? true : false}
            />
            <label>Name</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="email"
              className={authorSource === "adsync" || authorActive === false ? "form-control-plaintext" : "form-control"}
              value={authorEmail}
              placeholder="name@example.com"
              onChange={(e) => setAuthorEmail(e.target.value)}
              readOnly={authorSource === "adsync" ? true : false}
            />
            <label>Email address</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className={authorSource === "adsync" || authorActive === false ? "form-control-plaintext" : "form-control"}
              defaultValue={authorDescription}
              placeholder="description"
              onChange={(e) => setAuthorDescription(e.target.value)}
              readOnly={authorSource === "adsync" ? true : false}
            />
            <label>Description</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" className={authorActive === false ? "form-control-plaintext" : "form-control"} defaultValue={authorAlias} placeholder="alias" disabled={authorActive === false} onChange={(e) => setAuthorAlias(e.target.value.split(",").map((item) => item.trim()))} />
            <label>Alias</label>
          </div>
          <div className="mb-3">
            <div className="form-check form-check-inline">
              <input
                id="newAuthorModalLicensingRights"
                className="form-check-input"
                type="checkbox"
                value=""
                checked={authorLicensingRights}
                onChange={(e) => setAuthorLicensingRights(e.target.checked)}
                disabled={authorActive === false}
              />
              <label htmlFor="newAuthorModalLicensingRights" className="form-check-label user-select-none">
                Licensing Rights
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input id="newAuthorModalAuthorised" className="form-check-input" type="checkbox" value="" checked={authorData.authorised} disabled />
              <label htmlFor="newAuthorModalAuthorised" className="form-check-label user-select-none" >
                Authorised
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {authorActive === true && (
          <Button variant="success" onClick={editAuthor}>
            Save
          </Button>
          )}
          {showDeleteButton === true && (
            <Button variant="danger" onClick={deleteAuthor}>
              Delete
            </Button>
          )}
          <Button variant="secondary" onClick={closeEditAuthorModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllAuthors;
