import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { callApi } from "../common/utils";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const PagePreview = () => {
  const previewCanvas = useRef();
  const previewModal = useRef();
  const previewModalWrapper = useRef();

  let params = useParams();
  const [articlePaths, setArticlePaths] = useState([]);
  const [stories, setStories] = useState([]);

  // Story State
  const [headline, setHeadline] = useState("");
  const [subhead, setSubhead] = useState("");
  const [bylineName, setBylineName] = useState("");
  const [bylineTitle, setBylineTitle] = useState("");
  const [bodyText, setBodyText] = useState("");

  const [showModal, setShowModal] = useState(false);

  const openModal = (storyIndex) => {
    console.log(stories);
    console.log(storyIndex);
    let story = stories[storyIndex];
    console.log(story);
    setHeadline(story.content.headline);
    setSubhead(story.content.subhead);
    setBylineName(story.content["byline-name"]);
    setBylineTitle(story.content["byline-title"]);
    setBodyText(story.content.text);
    setShowModal(true);
  };

  const pxToPercentage = (pagePixels, elementPixels) => {
    return (elementPixels / pagePixels) * 100;
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.14.305/build/pdf.min.js";
    document.body.appendChild(script);
    script.onload = async () => {
      console.log("Script Loaded ...");

      // Fetch xml and page psurls
      const pageDataRequest = await callApi({ path: `edition_preview/page/${params["pubdate"]}/${params["edition"]}/${params["page_no"]}`, id: true });
      const pageData = pageDataRequest.content;
      console.log(pageData);
      setStories(pageData.stories);
      console.log(stories);
      const page_psurl = pageData.page_url;

      console.log(pageData);

      window.pdfjsLib.getDocument(page_psurl).promise.then((doc) => {
        doc.getPage(1).then((page) => {
          var scale = 1;
          var viewport = page.getViewport({ scale: scale });
          // Support HiDPI-screens.
          var outputScale = window.devicePixelRatio || 1;

          var canvas = previewCanvas.current;
          var context = canvas.getContext("2d");

          canvas.width = Math.floor(viewport.width * outputScale);
          canvas.height = Math.floor(viewport.height * outputScale);
          canvas.style.width = Math.floor(viewport.width) + "px";
          canvas.style.height = Math.floor(viewport.height) + "px";
          console.log(canvas.style.width, canvas.style.height);

          var transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

          var renderContext = {
            canvasContext: context,
            transform: transform,
            viewport: viewport,
          };
          page.render(renderContext);
          canvas.classList.add("pagePreview");

          // Plot stories
          let rects = [];
          for (let i = 0; i < pageData.stories.length; i++) {
            let story = pageData.stories[i];
            // rects.push([story.x, story.y, story.width, story.height]);
            rects.push([
              pxToPercentage(Math.floor(viewport.width), story.x),
              pxToPercentage(Math.floor(viewport.height), story.y),
              pxToPercentage(Math.floor(viewport.width), story.width),
              pxToPercentage(Math.floor(viewport.height), story.height),
            ]);
          }
          setArticlePaths(rects);
        });
      });
    };
  }, []);

  return (
    <div className={`outputSummary content p-1 d-flex flex-column flex-grow-1`}>
      <div className="container-fluid">
        <div id="pagePreviewWrapper" ref={previewModalWrapper} style={{ position: "relative", display: "inline-block" }}>
          <canvas ref={previewCanvas}></canvas>
          {articlePaths.map((articlePath, i) => (
            <div
              key={i}
              onClick={() => openModal(i)}
              style={{
                position: "absolute",
                left: articlePath[0] + "%",
                top: articlePath[1] + "%",
                width: articlePath[2] + "%",
                height: articlePath[3] + "%",
                zIndex: 1050,
                opacity: 0.5,
                cursor: "pointer",
              }}
            ></div>
          ))}
        </div>
      </div>

      {/* Preview Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl" scrollable="true">
        <Modal.Header>
          <Modal.Title>
            <h2 dangerouslySetInnerHTML={{ __html: headline }}></h2>
            <h5 dangerouslySetInnerHTML={{ __html: subhead }}></h5>
            <div className="fs-6" dangerouslySetInnerHTML={{ __html: bylineName }}></div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: bodyText }}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PagePreview;
