import { useState, useEffect, useRef } from "react";
import SideMenu from "./SideMenu";
import Main from "./Main";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./styles/App.scss";
import { BrowserRouter, useLocation, Navigate, redirect } from "react-router-dom";
import { getMenuItems } from "./api/User";
import { callApi } from "./common/utils";
import { AppContext } from "./context";
import { federatedSignIn, isSignedIn, getCurrentUser, isAdmin, getAccessToken } from "./common/cognitoAuth";
import Xmas from "./Xmas";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

const App = () => {
  const [toggled, setToggled] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [userAttribs, setUserAttribs] = useState({});
  const [collapsed, setCollapsed] = useState(false);
  const [pageTitle, setPageTitle] = useState(" ");
  const [menuItems, setMenuItems] = useState([]);

  // Xmas
  const [isXmas, setIsXmas] = useState(false);

  // User auth / login
  useEffect(() => {
    const checkSignedIn = async () => {
      let si = await isSignedIn();

      if (si === false) {
        federatedSignIn();
      } else {
        // Set user attributes
        const userAtts = await getCurrentUser();

        // Get Menu Items
        let params = window.location.hostname === "localhost" ? { localdev: true } : {};
        const menuItemsRequest = await callApi({ path: "common/get_menu", id: true, params: params });
        const menuItems = menuItemsRequest.content;

        setMenuItems(menuItems);
        setUserAttribs(userAtts);
      }
      setAuthenticated(si);
    };

    checkSignedIn();
  }, []);

  // User auth / login
  // useEffect(() => {
  //   const checkSignedIn = async () => {
  //     const userAtts = getCurrentUser()
  //       .then(async (user) => {
  //         // Get Menu Items
  //         let params = window.location.hostname === "localhost" ? { localdev: true } : {};
  //         const menuItemsRequest = await callApi({ path: "common/get_menu", id: true, params: params });
  //         const menuItems = menuItemsRequest.content;

  //         setMenuItems(menuItems);
  //         setUserAttribs(user);
  //         setAuthenticated(true);
  //       })
  //       .catch((message) => {
  //         console.log("Not Logged In", message);
  //         federatedSignIn();
  //       });
  //   };

  //   checkSignedIn();
  // }, []);

  // Turn on Xmas in December
  useEffect(() => {
    const d = new Date();
    let month = d.getMonth();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (month === 11 || params.xmas === "true") {
      setIsXmas(true);
    }
  }, []);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const handleCollapsedChange = (checked) => {
    setCollapsed(true);
  };

  // Return nothing if user is not authenticated
  if (authenticated === false) {
    return "";
  }

  // Return nothing if menus not loaded
  if (menuItems.length == 0) {
    return "";
  }

  return (
    <AppContext.Provider value={{ userAttribs, isXmas }}>
      <BrowserRouter>
        <div className={`app ${isXmas === true ? "xmas" : ""}`}>
          <SideMenu toggled={toggled} handleToggleSidebar={handleToggleSidebar} menuItems={menuItems} userAttribs={userAttribs} setPageTitle={setPageTitle} collapsed={collapsed} />
          <Main handleToggleSidebar={handleToggleSidebar} menuItems={menuItems} userAttribs={userAttribs} pageTitle={pageTitle} setCollapsed={setCollapsed} collapsed={collapsed} />
          {isXmas === true && <Xmas />}
        </div>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default App;
