// File used for mapping strings to React elements

// Page Imports (Edition Preview)
import Home from "./pages/Home";
import DpePreview from "./pages/DpePreview";
import Npa from "./pages/Npa";
import Npa2 from "./pages/Npa2";
import OutputSummary from "./pages/OutputSummary";
import MethodeOutput from "./pages/MethodeOutput";
import PageOutput from "./pages/PageOutput";
import PagePreview from "./pages/PagePreview";
import DevelopmentTesting from "./pages/DevelopmentTesting";
import NewAuthors from "./pages/attribution_api/NewAuthors";
import AllAuthors from "./pages/attribution_api/AllAuthors";
import ActivityLog from "./pages/attribution_api/ActivityLog";
import AttributionAdminTools from "./pages/attribution_api/AdminTools";
import FeedTransform from "./pages/newsroom_tools/FeedTransform";

// Icon Imports (Edition Preview)
import { FaHome } from "react-icons/fa";
import { VscPreview } from "react-icons/vsc";
import { HiLibrary } from "react-icons/hi";
import { MdHome, MdViewQuilt, MdLocalLibrary } from "react-icons/md";
import { GrTest } from "react-icons/gr";
import { BsFillPersonLinesFill } from "react-icons/bs";
import {FaTools} from "react-icons/fa";

const Map = {
  // Pages
  Home: Home,
  DpePreview: DpePreview,
  Npa: Npa,
  Npa2: Npa2,
  OutputSummary: OutputSummary,
  MethodeOutput: MethodeOutput,
  PageOutput: PageOutput,
  PagePreview: PagePreview,
  DevelopmentTesting: DevelopmentTesting,
  NewAuthors: NewAuthors,
  AllAuthors: AllAuthors,
  ActivityLog: ActivityLog,
  AttributionAdminTools: AttributionAdminTools,
  FeedTransform: FeedTransform,

  // Icons
  FaHome: FaHome,
  VscPreview: VscPreview,
  HiLibrary: HiLibrary,
  MdHome: MdHome,
  MdViewQuilt: MdViewQuilt,
  MdLocalLibrary: MdLocalLibrary,
  GrTest: GrTest,
  BsFillPersonLinesFill: BsFillPersonLinesFill,
  FaTools: FaTools,
};

export const dynamicComponent = (name, params) => {
  let TagName = Map[name];
  return <TagName {...params} />;
};
