// import config from "./config_prod.json";
import config from "./config.json";
import { getIdToken, getAccessToken } from "./cognitoAuth";
import { getCurrentUser } from "./cognitoAuth";
import { useContext } from "react";
import { AppContext } from "../context";
import { head } from "lodash";

const environment = process.env.NODE_ENV; // production or development (start or build)
const scooter_env = process.env.REACT_APP_SCOOTER_ENV; //scooter or dev-scooter

async function callApi({
  path,
  params = {},
  vpc = false,
  id = false,
  endpoint = null,
  method = "GET",
  jwt = true,
  headers = null,
} = {}) {
  // Construct url
  const apiRoute = vpc ? "v" : "nv";
  const basePath = endpoint
    ? endpoint
    : config[scooter_env][environment].api.endpoint;

  let url = endpoint
    ? `${basePath}/${path}`
    : `${basePath}/${apiRoute}/${path}`;

  let httpHeaders = {};

  // Use access token if id not required
  if (jwt === true) {
    const token = id ? getIdToken() : getAccessToken();
    httpHeaders.Authorization = await token;
  }

  if (headers !== null) {
    httpHeaders = { ...httpHeaders, ...headers };
  }

  // console.log(httpHeaders);

  let options = {
    method: method,
    headers: httpHeaders,
  };

  // Add params if present
  if (Object.keys(params).length > 0) {
    if (method === "GET") {
      url += "?" + new URLSearchParams(params);
    } else {
      options.body = JSON.stringify(params);
    }
  }

  //  fetch data
  const response = await fetch(url, options);

  const jsonResponse = await response.json();
  // console.log("API", jsonResponse);
  return {
    status: response.status,
    statusText: response.statusText,
    content: jsonResponse,
  };
}

const IsAdmin = () => {
  return IsMember("RGRP_okta_Scooter_Admin_User");
};

const IsMember = (group) => {
  const { userAttribs } = useContext(AppContext);
  if ("groups:custom" in userAttribs) {
    if (userAttribs["groups:custom"].includes(group)) {
      return true;
    }
  }
  return false;
};

const dateToYYYYMMDD = (d) => {
  let year = d.getFullYear();
  let month = (d.getMonth() + 1).toString().padStart(2, "0");
  let day = d.getDate().toString().padStart(2, "0");
  let formattedDate = [year, month, day].join("");
  return formattedDate;
};

const formatDateString = (dateString) => {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

const getAttApiKey = () => {
  return config[scooter_env][environment].attributionApi.key;
};

const epochToDate = (epoch) => {
  let d = new Date(0);
  d.setUTCSeconds(epoch);
  return d.toLocaleString();
};

function formatBytes(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Bytes";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
}

export {
  callApi,
  IsAdmin,
  IsMember,
  dateToYYYYMMDD,
  getAttApiKey,
  epochToDate,
  formatDateString,
  formatBytes,
};
