import DateSelector from "../components/common/dateSelector";
import { useEffect, useState, useRef } from "react";
import { callApi, dateToYYYYMMDD, formatDateString } from "../common/utils";
import { FaSearch } from "react-icons/fa";
import Pagination from "../components/common/Pagination";
import "../styles/Pages.scss";
import NpaPage from "../components/npa/NpaPage2";
import { Bars } from "react-loader-spinner";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Accordion from "react-bootstrap/Accordion";
import { Modal } from "react-bootstrap";
// import "react-loader-spinner/dist/loader/Bars/"
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Npa2 = ({
  npaStartDate,
  setNpaStartDate,
  npaEndDate,
  setNpaEndDate,
  npaPublications,
  setNpaPublications,
  npaEditions,
  setNpaEditions,
  npaSearchRange,
  setNpaSearchRange,
  npaSelectedEdition,
  setNpaSelectedEdition,
  npaSelectedPublication,
  setNpaSelectedPublication,
  npaPageNoSearch,
  setNpaPageNoSearch,
  npaSortBy,
  setNpaSortBy,
  npaSortOrder,
  setNpaSortOrder,
  npaPageSearchData,
  setNpaPageSearchData,
  npaDateSelection,
  setNpaDateSelection,
  npaRecordsPageNo,
  setNpaRecordsPageNo,
  npaCurrentSearch,
  setNpaCurrentSearch,
  npaSectionFilter,
  setNpaSectionFilter,
  npaAdvertisingSearchType,
  setNpaAdvertisingSearchType,
  npaAdvertisingId,
  setNpaAdvertisingId,
  npaAdvertisingSearchRecord,
  setNpaAdvertisingSearchRecord,
  advertisingSearchOptions,
  setAdvertisingSearchOptions,
}) => {
  // State
  const [disabledPublicationPicker, setDisabledPublicationPicker] = useState(npaPublications.length ? false : true);
  const [disabledEditionPicker, setDisabledEditionPicker] = useState(npaEditions.length ? false : true);
  const [displayNpaLoader, setDisplayNpaLoader] = useState(false);
  const [npaExpandedMenus, setNpaExpandedMenus] = useState(["0", "1"]);

  // State for Advertising Search
  const [isAdvertsingSearchLoading, setIsAdvertsingSearchIsLoading] = useState(false);
  const advertisingApiTimer = useRef(null); // To make sure only last api call is processed
  const advertisingSearchRef = useRef();

  // State for copyright modal
  const [showCopyrightModal, setShowCopyrightModal] = useState(false);

  // Preview + Ad Info State
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewImageInfo, setPreviewImageInfo] = useState({});
  const [adDetailsInfo, setAdDetailsInfo] = useState({});
  const [showAdDetailsModal, setShowAdDetailsModal] = useState(false);

  // For Advertising Searching
  const filterBy = () => true;
  const handleSearch = async (query, timer = advertisingApiTimer) => {
    setIsAdvertsingSearchIsLoading(true);
    const start_date = dateToYYYYMMDD(npaStartDate);
    callApi({ path: "npa/get_advertising_autocomplete", vpc: true, params: { start_date: start_date, s: query } }).then((resp) => {
      if (resp.status === 200) {
        setAdvertisingSearchOptions(resp.content);
      } else {
        setAdvertisingSearchOptions([]);
      }
      setIsAdvertsingSearchIsLoading(false);
    });
  };

  const setAdvertisingSearch = (option) => {
    // console.log(option);
    if (option.length > 0) {
      setNpaAdvertisingSearchRecord(option[0]);
    } else {
      setNpaAdvertisingSearchRecord({});
    }
  };

  const onAdvertisingLeave = (e) => {
    // console.log(advertisingSearchRef);

    if (!("id_val" in npaAdvertisingSearchRecord)) {
      advertisingSearchRef.current.clear();
    }
  };

  const getEditions = async () => {
    const start_date = dateToYYYYMMDD(npaStartDate);
    let editionsResponse = {};
    if (npaSearchRange) {
      const end_date = dateToYYYYMMDD(npaEndDate);
      editionsResponse = await callApi({ path: "npa/get_editions", vpc: true, params: { start_date: start_date, end_date: end_date, publication: npaSelectedPublication } });
    } else {
      editionsResponse = await callApi({ path: "npa/get_editions", vpc: true, params: { start_date: start_date, publication: npaSelectedPublication } });
    }
    const editions = editionsResponse.content;
    if (editionsResponse.status !== 200) {
      alert(`CHP DB Error. Status ${editionsResponse.status} status code retuned,\n\n${editions}`);
    }
    setDisabledEditionPicker(editions.length ? false : true);
    if (!editions.includes(npaSelectedEdition)) {
      setNpaSelectedEdition("");
    }
    setNpaEditions(editions);
  };

  const getPublications = async () => {
    const start_date = dateToYYYYMMDD(npaStartDate);
    let publicationsResponse = {};
    if (npaSearchRange) {
      const end_date = dateToYYYYMMDD(npaEndDate);
      publicationsResponse = await callApi({ path: "npa/get_publications", vpc: true, params: { start_date: start_date, end_date: end_date } });
    } else {
      publicationsResponse = await callApi({ path: "npa/get_publications", vpc: true, params: { start_date: start_date } });
    }
    const publications = publicationsResponse.content;
    setDisabledPublicationPicker(publications.length ? false : true);
    if (!publications.includes(npaSelectedPublication)) {
      setNpaSelectedPublication("");
    }
    setNpaPublications(publications);
  };

  // Load Publications
  useEffect(() => {
    getPublications();
  }, [npaStartDate, npaEndDate, npaSearchRange]);

  // Load Editions
  useEffect(() => {
    if (npaSelectedPublication) {
      getEditions();
    } else {
      setNpaSelectedEdition("");
      setNpaEditions([]);
      setDisabledEditionPicker(true);
    }
  }, [npaSelectedPublication, npaPublications]);

  const validatePageNumber = () => {
    // Strip spaces from page numbers + leading and trailing commas
    const s = npaPageNoSearch.replace(/ /g, "").replace(/^\,+|\,+$/g, "");

    if (s.length) {
      let pageRanges = s.split(",");
      // Loop through comma separated pages / ranges
      for (let i = 0; i < pageRanges.length; i++) {
        let pageRange = pageRanges[i];
        let pageNumbers = pageRange.split("-");
        // Return false if more than 1 "-" character
        if (pageNumbers.length !== 1 && pageNumbers.length !== 2) {
          alert(`Page Range Error!\n\n"${pageRange}" is an invalid range.`);
          return false;
        }
        for (let j = 0; j < pageNumbers.length; j++) {
          let pageNumber = pageNumbers[j];
          if (!Number(pageNumber)) {
            if (pageNumbers.length === 1) {
              alert(`Page Number Error!\n\n"${pageNumber}" is not a number.`);
            } else {
              alert(`Page Range Error!\n\n"${pageRange}" is not a valid range.`);
            }

            return false;
          }
        }
        if (pageNumbers.length === 2) {
          if (Number(pageNumbers[1]) < Number(pageNumbers[0])) {
            alert("Page Range Error!\n\nEnd page number must be greater than start");
            return false;
          }
        }
      }
    }
    setNpaPageNoSearch(s);
    return true;
  };

  const validateSection = () => {
    // Strip spaces from sections + leading and trailing commas
    let s = npaSectionFilter.replace(/ /g, "").replace(/^\,+|\,+$/g, "");
    s = s.split(",").join(", ");
    setNpaSectionFilter(s);
    return true;
  };

  // Perform Search
  const pageSearch = () => {
    if (npaSearchRange && npaStartDate > npaEndDate) {
      alert("End date must be after start date.");
      return;
    }

    if (!validatePageNumber()) {
      document.getElementById("npaPagePicker").focus();
      return;
    }

    validateSection();

    setDisplayNpaLoader(true);

    // Remove spaces around page range
    let pageNoSearch = npaPageNoSearch.trim();
    setNpaPageNoSearch(pageNoSearch);

    // Reset page number to 1
    const pn = 1;
    setNpaRecordsPageNo(pn);

    // Scroll to top
    document.getElementById("npaWrapper").scrollTop = 0;

    const params = {
      start_date: dateToYYYYMMDD(npaStartDate),
      end_date: dateToYYYYMMDD(npaEndDate),
      publication: npaSelectedPublication,
      edition: npaSelectedEdition,
      page_no: pageNoSearch,
      date_range: npaSearchRange,
      sort_by: npaSortBy,
      sort_order: npaSortOrder,
      pagination_no: pn,
      section: npaSectionFilter,
    };

    if (npaAdvertisingSearchType === "search" && "id_val" in npaAdvertisingSearchRecord) {
      params["advertising_id"] = npaAdvertisingSearchRecord["id_val"];
      params["advertising_search_type"] = npaAdvertisingSearchType;
      params["advertising_search_name"] = npaAdvertisingSearchRecord["display_name"];
      params["advertising_search_name_type"] = npaAdvertisingSearchRecord["ac_type"];
    } else if (npaAdvertisingSearchType === "id" && npaAdvertisingId.trim().length > 0) {
      params["advertising_id"] = npaAdvertisingId.trim();
      params["advertising_search_type"] = npaAdvertisingSearchType;
    }

    // console.log(npaAdvertisingSearchType, npaAdvertisingSearchRecord, npaAdvertisingId);

    setNpaCurrentSearch(params);

    queryPages(params);
  };

  // Paginated search
  const gotoPageSearch = (pageNum) => {
    let currentParams = npaCurrentSearch;
    currentParams.pagination_no = pageNum;

    queryPages(currentParams);

    // Update State
    setNpaCurrentSearch(currentParams);
    setNpaRecordsPageNo(Number(pageNum));

    // Scroll to top
    document.getElementById("npaWrapper").scrollTop = 0;

    // console.log(pageNum);
  };

  const gotoNextPage = () => {
    const nextPageNumber = npaRecordsPageNo + 1;
    gotoPageSearch(nextPageNumber);
  };

  const gotoPrevPage = () => {
    const prevPageNumber = npaRecordsPageNo - 1;
    gotoPageSearch(prevPageNumber);
  };

  const queryPages = async (params) => {
    let pagesResponse = null;
    if ("advertising_id" in params) {
      pagesResponse = await callApi({ path: "npa/get_pages_advertising", vpc: true, params: params });
    } else {
      pagesResponse = await callApi({ path: "npa/get_pages", vpc: true, params: params });
    }

    const pages = pagesResponse.content;
    setNpaPageSearchData(pages);
    // console.log(pages);
    setDisplayNpaLoader(false);
  };

  const onStartChange = (startDate) => {
    setNpaStartDate(startDate);
    if (npaSearchRange) {
      setNpaDateSelection("range|custom");
    } else {
      setNpaDateSelection("specificDate");
    }
  };

  const onEndChange = (endDate) => {
    setNpaEndDate(endDate);
    setNpaDateSelection("range|custom");
  };

  const getDate = (offset = 0, unit = "day") => {
    let d = new Date();
    // console.log(offset, unit);
    if (unit === "week") {
      d.setDate(d.getDate() + offset * 7);
    } else if (unit === "month") {
      d.setMonth(d.getMonth() + offset);
    } else if (unit === "year") {
      d.setFullYear(d.getFullYear() + offset);
    } else {
      d.setDate(d.getDate() + offset);
    }
    // console.log(d);
    return d;
  };

  // For Date Selection Change
  const onDateSelection = (d) => {
    const dateParts = d.split("|");
    setNpaSearchRange(dateParts[0] === "range" ? true : false);
    if (d === "today") {
      setNpaStartDate(getDate());
    } else if (d === "yesterday") {
      setNpaStartDate(getDate(-1));
    } else if (dateParts.length > 2 && dateParts[0] === "range" && dateParts[1] !== "custom") {
      setNpaStartDate(getDate(parseInt(dateParts[2]), dateParts[1]));
      setNpaEndDate(getDate());
    }
    setNpaDateSelection(d);
  };

  const submitOnEnter = (e) => {
    if (e.key === "Enter") {
      pageSearch();
    }
  };

  const setExpandedLocalStorage = (items) => {
    localStorage.setItem("npaExpandedMenus", JSON.stringify(items));
    setNpaExpandedMenus(items);
  };

  const copyrightNoticeCheck = async (update = false) => {
    let copyrightResponse = null;
    if (update === false) {
      copyrightResponse = await callApi({ path: "npa/copyright_notice_check", vpc: true });
      if (copyrightResponse.status === 200) {
        if (copyrightResponse.content === true) {
          setShowCopyrightModal(true);
        }
      }
    } else {
      copyrightResponse = callApi({ path: "npa/copyright_notice_check", vpc: true, method: "POST" });
      setShowCopyrightModal(false);
    }
  };

  useEffect(() => {
    // Set expanded search state
    const items = JSON.parse(localStorage.getItem("npaExpandedMenus"));
    if (items) {
      setNpaExpandedMenus(items);
    }
    // Check for copyright notice
    copyrightNoticeCheck();
  }, []);

  return (
    // Parent div
    <div className="d-flex" style={{ height: "100%", overflowY: "hidden" }}>
      {/* Sidebar */}
      <div id="npaSidebar" className="flex-shrink-0" style={{ width: 270, boxShadow: "rgb(38, 57, 77) 2px 0px 17px -10px", backgroundColor: "#E7E9EB" }}>
        <div className="npaSideBarWrapper">
          <div className="npaSideBarContent">
            <div className="container">
              <div className="row">
                {/* Start */}
                <Accordion onSelect={setExpandedLocalStorage} style={{ paddingLeft: 0, paddingRight: 0 }} activeKey={npaExpandedMenus} alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Select Date</Accordion.Header>
                    <Accordion.Body>
                      <div className="col-12 p-1">
                        <div className="form-floating">
                          <select id="npaDateSelection" className="form-select" value={npaDateSelection} onChange={(e) => onDateSelection(e.target.value)}>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="specificDate">Specific Date</option>
                            <optgroup label="Date Range">
                              <option value="range|custom">Custom Range</option>
                              <option value="range|week|-1">Last Week</option>
                              <option value="range|month|-1">Last Month</option>
                              <option value="range|day|-90">Last 90 Days</option>
                              <option value="range|year|-1">Last Year</option>
                            </optgroup>
                          </select>
                          <label htmlFor="npaDateSelection">Date Selection</label>
                        </div>
                      </div>
                      <div className="col-12 p-1">
                        <DateSelector label={npaSearchRange ? "Start Date" : "Date"} defaultDate={npaStartDate} onChange={onStartChange} />
                      </div>
                      <div className="col-12 p-1" style={{ display: npaSearchRange ? "block" : "none" }}>
                        <DateSelector label="End Date" defaultDate={npaEndDate} onChange={onEndChange} disabled={npaSearchRange ? false : true} />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Publication / Edition</Accordion.Header>
                    <Accordion.Body>
                      <div className="col-12 p-1">
                        <div className="form-floating">
                          <select
                            id="npaPubPicker"
                            className="form-select"
                            disabled={disabledPublicationPicker}
                            value={npaSelectedPublication || ""}
                            onChange={(e) => setNpaSelectedPublication(e.target.value)}
                          >
                            <option value="">All</option>
                            {npaPublications.map((pub) => (
                              <option key={pub} value={pub}>
                                {pub}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="npaPubPicker">Select a Publication</label>
                        </div>
                      </div>
                      <div className="col-12 p-1">
                        <div className="form-floating">
                          <select
                            id="npaEditionPicker"
                            className="form-select"
                            disabled={disabledEditionPicker}
                            value={npaSelectedEdition || ""}
                            onChange={(e) => setNpaSelectedEdition(e.target.value)}
                          >
                            <option value="">All</option>
                            {npaEditions.map((ed) => (
                              <option key={ed} value={ed}>
                                {ed}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="npaEditionPicker">Select an Edition</label>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Page Filter</Accordion.Header>
                    <Accordion.Body>
                      <div className="col-12 p-1">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="npaPagePicker" value={npaPageNoSearch} onChange={(e) => setNpaPageNoSearch(e.target.value)} onKeyDown={submitOnEnter}></input>
                          <label htmlFor="npaPagePicker">Page No / Range</label>
                        </div>
                      </div>
                      <div className="col-12 p-1">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="npaSectionFilter"
                            value={npaSectionFilter}
                            onChange={(e) => setNpaSectionFilter(e.target.value)}
                            onKeyDown={submitOnEnter}
                          ></input>
                          <label htmlFor="npaPagePicker">Section Name</label>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Order By</Accordion.Header>
                    <Accordion.Body>
                      <div className="col-12 p-1">
                        <div className="form-floating">
                          <select id="npaSortOrder" className="form-select" value={npaSortBy} onChange={(e) => setNpaSortBy(e.target.value)}>
                            <option value="edition_page">Edition / Page No</option>
                            <option value="page">Page No</option>
                          </select>
                          <label htmlFor="npaSortOrder">Sort By</label>
                        </div>
                      </div>
                      <div className="col-12 p-1">
                        <div className="form-floating">
                          <select id="npaSortOrder" className="form-select" value={npaSortOrder} onChange={(e) => setNpaSortOrder(e.target.value)}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                          </select>
                          <label htmlFor="npaSortOrder">Sort Order</label>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Advertising Search</Accordion.Header>
                    <Accordion.Body>
                      <div className="col-12 p-1" style={npaAdvertisingSearchType !== "search" ? { display: "None" } : {}}>
                        <AsyncTypeahead
                          ref={advertisingSearchRef}
                          filterBy={() => true}
                          id="async-example"
                          isLoading={isAdvertsingSearchLoading}
                          labelKey="display_name"
                          minLength={2}
                          onSearch={handleSearch}
                          options={advertisingSearchOptions}
                          flip={true}
                          useCache={false}
                          // onInputChange={(e) => console.log(e)}
                          onChange={setAdvertisingSearch}
                          onBlur={onAdvertisingLeave}
                          defaultInputValue={"id_val" in npaAdvertisingSearchRecord ? npaAdvertisingSearchRecord["display_name"] : ""}
                          renderMenuItemChildren={(option) => (
                            <span style={{ fontSize: "0.8rem" }} title={option.display_name}>
                              {/* {option.id_val} - {option.display_name} */}
                              {option.display_name}
                            </span>
                          )}
                          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                            <div className="form-floating">
                              <input
                                id="npaAdvertisingSearch"
                                {...inputProps}
                                ref={(input) => {
                                  inputRef(input);
                                  referenceElementRef(input);
                                }}
                                className="form-control"
                              />
                              {/* <small class="text-muted">Search for clients, ad reps, teams or agencies.</small> */}
                              <label htmlFor="npaAdvertisingSearch">Booking Search</label>
                            </div>
                          )}
                        />
                      </div>

                      <div className="col-12 p-1" style={npaAdvertisingSearchType === "search" ? { display: "None" } : {}}>
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="npaAdvertisingId"
                            value={npaAdvertisingId !== null ? npaAdvertisingId : ""}
                            onChange={(e) => setNpaAdvertisingId(e.target.value)}
                          ></input>
                          {/* <small class="text-muted">Supports OrderItemId, OrderId, MaterialId, CustomerId, SalesRepId, TeamId and AgencyId</small> */}
                          <label htmlFor="npaPagePicker">Insertion Id</label>
                        </div>
                      </div>

                      <div className="col-12 p-1 text-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="advertisingSearchSelect"
                            id="inlineCheckbox2"
                            value="search"
                            checked={npaAdvertisingSearchType === "search" ? true : false}
                            onChange={(e) => setNpaAdvertisingSearchType("search")}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox2" style={{ fontSize: "0.9rem" }}>
                            Search
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="advertisingSearchSelect"
                            id="inlineCheckbox1"
                            value="id"
                            checked={npaAdvertisingSearchType === "id" ? true : false}
                            onChange={(e) => setNpaAdvertisingSearchType("id")}
                          />
                          <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ fontSize: "0.9rem" }}>
                            By Id
                          </label>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {/* End */}

                {/* <div className="col-12 p-1 text-center">
                  <button type="button" className="btn btn-success" onClick={pageSearch}>
                    <FaSearch style={{ position: "relative", top: -1 }}></FaSearch> Search
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="npaSideBarFooter">
            <div className="col-12 p-3 text-center">
              <hr />
              <button type="button" className="btn btn-success" onClick={pageSearch}>
                <FaSearch style={{ position: "relative", top: -1 }}></FaSearch> Search
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Content */}
      <div id="npaWrapper" className="flex-grow-1 p-2" style={{ overflowY: displayNpaLoader ? "hidden" : "auto", position: "relative", overflowX: "hidden" }}>
        <div className="container-fluid">
          {displayNpaLoader && (
            <div
              id="npaLoadingOverlay"
              className="d-flex flex-column justify-content-center"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
                backgroundColor: "rgb(231, 233, 235)",
                opacity: 0.9,
              }}
            >
              <Bars height="80" width="80" color="#f8ad17" ariaLabel="bars-loading" wrapperStyle={{ marginLeft: "auto", marginRight: "auto" }} wrapperClass="p-2 loadingBars" visible={true} />
              <h3 className="text-center p-2">Searching</h3>
            </div>
          )}
          <div className="row">
            {"pages" in npaPageSearchData ? (
              npaPageSearchData.pages.length > 0 ? (
                npaPageSearchData.pages.map((page, i) => (
                  <NpaPage
                    key={page.FILENAME}
                    page={page}
                    setPreviewImageInfo={setPreviewImageInfo}
                    setShowPreviewModal={setShowPreviewModal}
                    setShowAdDetailsModal={setShowAdDetailsModal}
                    setAdDetailsInfo={setAdDetailsInfo}
                  />
                ))
              ) : (
                <div
                  className="d-flex flex-column justify-content-top text-center"
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    paddingTop: "5%",
                  }}
                >
                  <h4>No Pages Found</h4>
                </div>
              )
            ) : (
              ""
            )}
            {/* {"pages" in npaPageSearchData && npaPageSearchData.pages.length > 0 ? npaPageSearchData.pages.map((page, i) => <NpaPage page={page} />) : "Jake"} */}

            {"count" in npaPageSearchData && npaPageSearchData.count > 54 ? (
              <>
                <hr />
                <Pagination
                  className="mb-2"
                  itemsPerPage="54"
                  totalItems={npaPageSearchData.count}
                  currentPage={npaRecordsPageNo}
                  onPageChange={gotoPageSearch}
                  onNextPage={gotoNextPage}
                  onPrevPage={gotoPrevPage}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Page Preview Modal */}
        <Modal aria-labelledby="contained-modal-title-vcenter" centered show={showPreviewModal} onHide={() => setShowPreviewModal(false)} className="npaPreviewModal">
          <Modal.Body style={{ padding: "none !important" }}>
            <img
              className="pagePreview"
              style={{ display: "block", width: "100%", height: "auto", backgroundColor: "white" }}
              src={previewImageInfo.url || ""}
              width={previewImageInfo.width || "0"}
              height={previewImageInfo.height || "0"}
            />
          </Modal.Body>
        </Modal>

        {/* Copyright Modal */}
        <Modal backdrop="static" keyboard={false} centered show={showCopyrightModal}>
          <Modal.Body>
            <h5>PDF Archive - Terms of Use</h5>
            <hr />
            <p>
              The PDF archive is provided for internal reference. The content of PDFs are copyright and should not be shared externally without prior permission. Approved external uses include the
              following:
            </p>
            <ul>
              <li>Editorial & Marketing promotional use</li>
              <li>Advertising tear sheets and sales support</li>
              <li>Finance account queries</li>
              <li>Syndication partnerships</li>
            </ul>
            <p>
              All other external use should be approved by News Corp Content Licensing by contacting{" "}
              <a href="mailto:licensing@news.com.au" target="_blank">
                licensing@news.com.au
              </a>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-success btn-sm" onClick={() => copyrightNoticeCheck(true)}>
              Understood
            </button>
          </Modal.Footer>
        </Modal>

        {/* Ad Details Modal */}
        {/* <Modal size="xl" aria-labelledby="contained-modal-title-vcenter" centered show={showAdDetailsModal} onHide={() => setShowAdDetailsModal(false)} className="">
          <Modal.Body style={{ padding: "none !important" }}>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <img
                    className="pagePreview mb-3"
                    style={{ display: "block", width: "100%", height: "auto", backgroundColor: "white", border: "1px solid #dbd7d7" }}
                    src={previewImageInfo.url || ""}
                    width={previewImageInfo.width || "0"}
                    height={previewImageInfo.height || "0"}
                  />
                </div>
                <div className="col-12 col-lg-8">
                  <div className="container">
                    <div className="row gx-1 gy-2">
                      <div className="col-12">
                        <h4>Ad Details</h4>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-floating">
                          <input type="text" readonly className="form-control" placeholder={adDetailsInfo.BOOKING_SOURCE} value={adDetailsInfo.BOOKING_SOURCE} />
                          <label>Source</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-floating">
                          <input type="text" readonly className="form-control" placeholder={adDetailsInfo.ORDERID} value={adDetailsInfo.ORDERID} />
                          <label>Order ID</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-floating">
                          <input type="text" readonly className="form-control" placeholder={adDetailsInfo.ORDERITEMID} value={adDetailsInfo.ORDERITEMID} />
                          <label>Order Item ID</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-floating">
                          <input type="text" readonly className="form-control" placeholder={adDetailsInfo.MATERIALID} value={adDetailsInfo.MATERIALID} />
                          <label>Material ID</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            readonly
                            className="form-control"
                            placeholder={`${adDetailsInfo.CUSTOMERID} - ${adDetailsInfo.CUSTOMERNAME}`}
                            value={`${adDetailsInfo.CUSTOMERID} - ${adDetailsInfo.CUSTOMERNAME}`}
                          />
                          <label>Customer</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            readonly
                            className="form-control"
                            placeholder={`${adDetailsInfo.SALESREPID} - ${adDetailsInfo.SALESREP}`}
                            value={`${adDetailsInfo.SALESREPID} - ${adDetailsInfo.SALESREP}`}
                          />
                          <label>Sales Rep</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            readonly
                            className="form-control"
                            placeholder={`${adDetailsInfo.TEAMID} - ${adDetailsInfo.TEAM}`}
                            value={`${adDetailsInfo.TEAMID} - ${adDetailsInfo.TEAM}`}
                          />
                          <label>Team</label>
                        </div>
                      </div>
                      {adDetailsInfo.AGENCYNAME && (
                        <div className="col-12 col-lg-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              readonly
                              className="form-control"
                              placeholder={`${adDetailsInfo.AGENCYID} - ${adDetailsInfo.AGENCYNAME}`}
                              value={`${adDetailsInfo.AGENCYID} - ${adDetailsInfo.AGENCYNAME}`}
                            />
                            <label>Agency</label>
                          </div>
                        </div>
                      )}
                      <div className="col-12 mt-3">
                        <h4>Page Details</h4>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-floating">
                          <input type="text" readonly className="form-control" placeholder={adDetailsInfo.PUBLICATION} value={adDetailsInfo.PUBLICATION} />
                          <label>Publication</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-floating">
                          <input type="text" readonly className="form-control" placeholder={adDetailsInfo.EDITION} value={adDetailsInfo.EDITION} />
                          <label>Edition</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-floating">
                          <input type="text" readonly className="form-control" placeholder={formatDateString(adDetailsInfo.PUBDATE)} value={formatDateString(adDetailsInfo.PUBDATE)} />
                          <label>Pubdate</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            readonly
                            className="form-control"
                            placeholder={`${adDetailsInfo.PAGE_NO} of ${adDetailsInfo.PAGE_COUNT}`}
                            value={`${adDetailsInfo.PAGE_NO} of ${adDetailsInfo.PAGE_COUNT}`}
                          />
                          <label>Page No</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-floating">
                          <input type="text" readonly className="form-control" placeholder={adDetailsInfo.SECTION} value={adDetailsInfo.SECTION} />
                          <label>Section</label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            readonly
                            className="form-control"
                            placeholder={`${adDetailsInfo.PAGE_WIDTH} x ${adDetailsInfo.PAGE_HEIGHT}mm`}
                            value={`${adDetailsInfo.PAGE_WIDTH} x ${adDetailsInfo.PAGE_HEIGHT}mm`}
                          />
                          <label>Page Size</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal> */}
      </div>
    </div>
  );
};

export default Npa2;
