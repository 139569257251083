import { useEffect, memo } from "react";
import DpePage from "./DpePage";

const DpePages = ({ pageData, index }) => {
  // Used to get page size for title block
  const getPageSize = (pages) => {
    for (let i = 0; i < pages.length; i++) {
      if (pages[i].width && pages[i].height) {
        return pages[i].width / pages[i].height;
      }
    }
    return 100 / 100;
  };

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    let year = dateObj.getFullYear();
    let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    let day = dateObj.getDate().toString().padStart(2, "0");
    let hour = dateObj.getHours();
    let minute = dateObj.getMinutes().toString().padStart(2, "0");
    let second = dateObj.getSeconds().toString().padStart(2, "0");
    let ampm = hour < 12 ? "AM" : "PM";
    if(hour === 0){
      hour = 12;
    }else if(hour > 12){
      hour = hour - 12;
    }
    hour = hour.toString();
    return `${hour}:${minute}:${second} ${ampm}, ${day}/${month}`;
  };

  return (
    <div id="pages">
      {"main_edition" in pageData &&
        pageData.main_edition.map((edition, i) => (
          <div className="container-fluid" key={edition.edition + i}>
            <div className="row">
              <div className={`col-6 col-xl-2 col-xxl-2 col-xxxl-1 dpeTitle mb-3`}>
                <div
                  className="dpeBlock"
                  style={{
                    aspectRatio: getPageSize(edition.pages),
                  }}
                >
                  <h6 className="text-truncate" title={edition.friendly_name}>
                    {edition.friendly_name}
                  </h6>
                  <p className="text-truncate" style={{ fontSize: "0.8rem" }} title={edition.edition}>
                    {edition.edition}
                  </p>
                </div>
                <div className={`pageInfo mt-2 ${index === 0 ? "" : index % 2 === 0 ? "ms-1" : "me-1"}`}>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Last Output</div>
                    <div className="text-truncate">{formatDate(edition.last_output.output_time)}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Last Page No.</div>
                    <div className="text-truncate">{edition.last_output.page_no}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                    <div className="me-2 fw-bold text-nowrap">Pages (Total)</div>
                    <div className="text-truncate">{edition.page_count}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                    <div className="me-2 fw-bold text-nowrap">Pages (Output)</div>
                    <div className="text-truncate">{edition.pages_output}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Pages (CHP)</div>
                    <div className="text-truncate">{edition.pages_in_chp}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Text Extraction</div>
                    <div className="text-truncate">{edition.text_extraction_required}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Total Stories</div>
                    <div className="text-truncate">{edition.story_count || 0}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Stories Extracted</div>
                    <div className="text-truncate">{edition.extracted_story_count || 0}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Total Ads</div>
                    <div className="text-truncate">{edition.ad_count || 0}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">&nbsp;</div>
                    <div className="text-truncate">&nbsp;</div>
                  </div>
                </div>
                <div className={`pageInfoCompact mt-2 ${index === 0 ? "" : index % 2 === 0 ? "ms-1" : ""}`}>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                    <div className="me-2 fw-bold text-nowrap">Pages (Total)</div>
                    <div className="text-truncate">{edition.page_count}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                    <div className="me-2 fw-bold text-nowrap">Pages (Output)</div>
                    <div className="text-truncate">{edition.pages_output}</div>
                  </div>
                </div>
              </div>
              {edition.pages.map((page, i) => (
                <DpePage key={`${edition.edition}-${page.page_no}-${page.output_time}`} data={page} index={i} pageCount={edition.page_count} />
              ))}
            </div>
          </div>
        ))}
      {"liftouts" in pageData &&
        pageData.liftouts.length &&
        pageData.liftouts.map((edition, i) => (
          <div className="container-fluid" key={edition.edition}>
            {i == 0 ? <h3 className="mt-4 mb-3">Liftouts &amp; Magazines</h3> : ""}
            <div className="row">
              <div className={`col-6 col-xl-2 col-xxl-2 col-xxxl-1 dpeTitle mb-3`}>
                <div
                  className="dpeBlock"
                  style={{
                    aspectRatio: getPageSize(edition.pages),
                  }}
                >
                  <h6 className="text-truncate" title={edition.friendly_name}>
                    {edition.friendly_name}
                  </h6>
                  <p className="text-truncate" style={{ fontSize: "0.8rem" }} title={edition.edition}>
                    {edition.edition}
                  </p>
                </div>
                <div className={`pageInfo mt-2 ${index === 0 ? "" : index % 2 === 0 ? "ms-1" : "me-1"}`}>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Last Output</div>
                    <div className="text-truncate">{formatDate(edition.last_output.output_time)}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Last Page No.</div>
                    <div className="text-truncate">{edition.last_output.page_no}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                    <div className="me-2 fw-bold text-nowrap">Pages (Total)</div>
                    <div className="text-truncate">{edition.page_count}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                    <div className="me-2 fw-bold text-nowrap">Pages (Output)</div>
                    <div className="text-truncate">{edition.pages_output}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Pages (CHP)</div>
                    <div className="text-truncate">{edition.pages_in_chp}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Text Extraction</div>
                    <div className="text-truncate">{edition.text_extraction_required}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Total Stories</div>
                    <div className="text-truncate">{edition.story_count || 0}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Stories Extracted</div>
                    <div className="text-truncate">{edition.extracted_story_count || 0}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">Total Ads</div>
                    <div className="text-truncate">{edition.ad_count || 0}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                    <div className="me-2 fw-bold text-nowrap">&nbsp;</div>
                    <div className="text-truncate">&nbsp;</div>
                  </div>
                </div>
                <div className={`pageInfoCompact mt-2 ${index === 0 ? "" : index % 2 === 0 ? "ms-1" : ""}`}>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                    <div className="me-2 fw-bold text-nowrap">Pages (Total)</div>
                    <div className="text-truncate">{edition.page_count}</div>
                  </div>
                  <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                    <div className="me-2 fw-bold text-nowrap">Pages (Output)</div>
                    <div className="text-truncate">{edition.pages_output}</div>
                  </div>
                </div>
              </div>
              {edition.pages.map((page, i) => (
                <DpePage key={`${edition.edition}-${page.page_no}-${page.output_time}`} data={page} index={i} pageCount={edition.page_count} />
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default DpePages;
