import { useEffect } from "react";
import DateSelector from "../components/common/dateSelector";
import PageHeader from "../components/common/pageHeader";
import { callApi } from "../common/utils";
import "../styles/Pages.scss";
import { useNavigate } from "react-router-dom";

const formatDateString = (dateString) => {
  const dateObj = new Date(dateString);
  let year = dateObj.getFullYear();
  let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  let day = dateObj.getDate().toString().padStart(2, "0");
  let hour = dateObj.getHours();
  let minute = dateObj.getMinutes().toString().padStart(2, "0");
  let second = dateObj.getSeconds().toString().padStart(2, "0");
  let ampm = hour < 12 ? "AM" : "PM";
  if (hour === 0) {
    hour = 12;
  } else if (hour > 12) {
    hour = hour - 12;
  }
  hour = hour.toString();
  return `${day}/${month}, ${hour}:${minute}:${second} ${ampm}`;
};

const toDate = (dateString) => {
  const year = +dateString.substring(0, 4);
  const month = +dateString.substring(4, 6);
  const day = +dateString.substring(6, 8);
  const date = new Date(year, month - 1, day);
  return date;
};

const OutputSummary = ({
  summaryDate,
  setSummaryDate,
  summaryDisplay,
  setSummaryDisplay,
  summaryHideComplete,
  setSummaryHideComplete,
  summaryData,
  setSummaryData,
  setEditionDate,
  setSelectedEdition,
  setEditionPageData,
  defaultSummaryView,
  setDefaultSummaryView,
}) => {
  const formatDate = () => {
    let year = summaryDate.getFullYear();
    let month = (summaryDate.getMonth() + 1).toString().padStart(2, "0");
    let day = summaryDate.getDate().toString().padStart(2, "0");
    return [year, month, day].join("");
  };

  let navigate = useNavigate();
  const gotoEdition = (edition, editionDate) => {
    setEditionDate(editionDate);
    setSelectedEdition(edition);
    setEditionPageData({});
    navigate("../edition_preview/methode_output");
    console.log(`Setting edition = ${edition}, date = ${editionDate}`);
  };

  const getSummary = async () => {
    const sumDataRequest = await callApi({
      path: `edition_preview/get_summary/${formatDate(summaryDate)}`,
    });
    const sumData = sumDataRequest.content;
    console.log(sumData);

    // Only update summaryData if checksum has changed
    if (
      !"checkSum" in summaryData ||
      summaryData.checkSum !== sumData.checkSum
    ) {
      setSummaryData(sumData);
    }
  };

  // Update DOM when summaryData changes
  useEffect(() => {
    getSummary();

    // Update UI every 30 seconds
    const interval = 30;
    const i = setInterval(() => {
      getSummary();
    }, interval * 1000);

    return () => {
      clearInterval(i);
    };
  }, [summaryDate]);

  // Load state from local storage
  useEffect(() => {
    const ls = JSON.parse(localStorage.getItem("defaultSummaryView"));
    if (ls) {
      setDefaultSummaryView(ls);
    }
  }, []);

  const summaryViewChange = (view) => {
    localStorage.setItem("defaultSummaryView", JSON.stringify(view));
    setDefaultSummaryView(view);
  };

  const setCurrentView = (value) => {
    const displayPlusView = value.split("_");

    // Update summary view
    displayPlusView[0] === "methode"
      ? setSummaryDisplay("methode")
      : setSummaryDisplay("pagesuite");

    // Update hide complete
    displayPlusView[1] === "all"
      ? setSummaryHideComplete(false)
      : setSummaryHideComplete(true);

    console.log(displayPlusView);
  };

  return (
    <div className="p-1">
      <PageHeader
        rightContent={
          <div className="row">
            <div className="col-6 col-lg-auto">
              <div className="form-floating">
                <select
                  id="summaryHideComplete"
                  className="form-select"
                  // onChange={(e) => setSummaryHideComplete(e.target.value === "all" ? false : true)}
                  onChange={(e) => setCurrentView(e.target.value)}
                  defaultValue={summaryHideComplete ? "incomplete" : "all"}
                >
                  <optgroup label="PageSuite Editions">
                    <option value="pagesuite_all">PageSuite All</option>
                    <option value="pagesuite_incomplete">
                      PageSuite Incomplete
                    </option>
                  </optgroup>
                  <optgroup label="Méthode Editions">
                    <option value="methode_all">Méthode All</option>
                    <option value="methode_incomplete">
                      Méthode Incomplete
                    </option>
                  </optgroup>
                </select>
                {/* <select
                  id="summaryHideComplete"
                  className="form-select"
                  onChange={(e) => setSummaryHideComplete(e.target.value === "all" ? false : true)}
                  defaultValue={summaryHideComplete ? "incomplete" : "all"}
                >
                  <option value="all">All</option>
                  <option value="incomplete">Incomplete</option>
                </select> */}
                <label htmlFor="summaryHideComplete">Display</label>
              </div>
            </div>
            <div className="col-6 col-lg-auto">
              <DateSelector
                defaultDate={summaryDate}
                onChange={setSummaryDate}
                label="Summary Date"
              />
            </div>
          </div>
        }
        leftContent={
          <div className="d-none d-lg-block">
            <div
              className="btn-group"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio2"
                autoComplete="off"
                onChange={() => summaryViewChange("list")}
                checked={defaultSummaryView === "list" ? true : false}
              />
              <label className="btn btn-outline-dark" htmlFor="btnradio2">
                List View
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio1"
                autoComplete="off"
                onChange={() => summaryViewChange("thumbnail")}
                checked={defaultSummaryView === "thumbnail" ? true : false}
              />
              <label className="btn btn-outline-dark" htmlFor="btnradio1">
                Thumbnail View
              </label>
            </div>
          </div>
        }
      />
      <div
        className={`outputSummary content p-1 d-flex flex-column flex-grow-1 ${
          summaryDisplay === "pagesuite" ? "showPageSuite" : ""
        } ${summaryHideComplete ? "hideComplete" : ""}`}
      >
        <div className="container-fluid">
          <div
            style={
              defaultSummaryView === "thumbnail" ? { display: "none" } : {}
            }
          >
            <div className="table-responsive">
              <table className="table compact">
                <thead>
                  <tr>
                    <th scope="col">Edition</th>
                    <th scope="col" className="text-nowrap">
                      Page Count
                    </th>
                    <th scope="col" className="text-nowrap">
                      Pages Output
                    </th>
                    <th scope="col" className="text-nowrap">
                      Last Page Time
                    </th>
                    <th scope="col" className="text-nowrap">
                      Last Page
                    </th>
                    <th scope="col" className="text-nowrap">
                      Missing Pages
                    </th>
                    <th scope="col" className="text-nowrap">
                      Not in CHP
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {"editions" in summaryData &&
                    summaryData.editions.map((edition, i) => (
                      <tr
                        key={edition.publication + edition.edition + i}
                        className={`${
                          edition.missing_pages.length > 0
                            ? "table-danger"
                            : edition.missing_from_chp.length > 0
                            ? "table-warning"
                            : "table-success"
                        } ${
                          edition.pagesuite_edition
                            ? "pageSuite"
                            : "noPageSuite"
                        } ${
                          edition.versioned_edition ? "versionedEdition" : ""
                        } ${
                          edition.missing_from_chp.length === 0 &&
                          edition.missing_pages.length === 0
                            ? "complete"
                            : ""
                        }`}
                      >
                        <th
                          scope="row"
                          className="text-nowrap"
                          onClick={() =>
                            gotoEdition(
                              edition.edition,
                              toDate(edition.pubdate)
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {edition.edition}
                        </th>
                        <td className="text-center">{edition.page_count}</td>
                        <td className="text-center">{edition.pages_output}</td>
                        <td className="text-nowrap">
                          {formatDateString(edition.last_output_time)}
                        </td>
                        <td className="text-center">{edition.last_page}</td>
                        <td>{edition.missing_pages.join(", ")}</td>
                        <td>{edition.missing_from_chp.join(", ")}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="row"
            style={defaultSummaryView === "list" ? { display: "none" } : {}}
          >
            {"editions" in summaryData &&
              summaryData.editions.map((edition, i) => (
                <div
                  className={`col-6 col-xl-2 col-xxl-2 col-xxxl-1 mb-3 pageWrapper ${
                    edition.missing_from_chp.length === 0 &&
                    edition.missing_pages.length === 0
                      ? "complete"
                      : ""
                  } ${
                    edition.pagesuite_edition ? "pageSuite" : "noPageSuite"
                  } ${edition.versioned_edition ? "versionedEdition" : ""}`}
                  key={edition.publication + edition.edition + i}
                  onClick={() =>
                    gotoEdition(edition.edition, toDate(edition.pubdate))
                  }
                >
                  <div
                    className={`pageInner ${
                      edition.missing_pages.length === 0 &&
                      edition.missing_from_chp.length === 0
                        ? "complete"
                        : edition.missing_pages.length !== 0
                        ? "missing"
                        : "missing_from_chp"
                    }`}
                  >
                    <div>
                      <h6 className="text-center fw-bold text-truncate">
                        {edition.edition}
                      </h6>
                      <div
                        className="page"
                        style={{
                          aspectRatio:
                            parseInt(edition.width) / parseInt(edition.height),
                          padding: "5% 3%",
                          display: "flex",
                          justifyContent: "space-evenly",
                          columnGap: "1%",
                          position: "relative",
                        }}
                      >
                        {/* Columns */}
                        <div
                          style={{
                            height: "100%",
                            flexGrow: "1",
                            border: "1px solid #CCC",
                          }}
                        ></div>
                        <div
                          style={{
                            height: "100%",
                            flexGrow: "1",
                            border: "1px solid #CCC",
                          }}
                        ></div>
                        <div
                          style={{
                            height: "100%",
                            flexGrow: "1",
                            border: "1px solid #CCC",
                          }}
                        ></div>
                        <div
                          style={{
                            height: "100%",
                            flexGrow: "1",
                            border: "1px solid #CCC",
                          }}
                        ></div>
                        <div
                          style={{
                            height: "100%",
                            flexGrow: "1",
                            border: "1px solid #CCC",
                          }}
                        ></div>

                        {edition.thumbnail ? (
                          <img
                            src={edition.thumbnail}
                            className="img-fluid"
                            style={{
                              maxWidth: "auto",
                              position: "absolute",
                              top: 0,
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className={`pageInfo mt-2 `}>
                      <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                        <div className="me-2 fw-bold text-nowrap">
                          Publication
                        </div>
                        <div className="text-truncate">
                          {edition.publication}
                        </div>
                      </div>
                      <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                        <div className="me-2 fw-bold text-nowrap">
                          Page Count
                        </div>
                        <div className="text-truncate">
                          {edition.page_count}
                        </div>
                      </div>
                      <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                        <div className="me-2 fw-bold text-nowrap">
                          Pages Output
                        </div>
                        <div className="text-truncate">
                          {edition.pages_output}
                        </div>
                      </div>
                      <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                        <div className="me-2 fw-bold text-nowrap">
                          Last Page Time
                        </div>
                        <div className="text-truncate">
                          {formatDateString(edition.last_output_time)}
                        </div>
                      </div>
                      <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                        <div className="me-2 fw-bold text-nowrap">
                          Last Page Output
                        </div>
                        <div className="text-truncate">{edition.last_page}</div>
                      </div>
                      <div
                        className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow"
                        title={
                          edition.missing_pages.length
                            ? edition.missing_pages.join(", ")
                            : ""
                        }
                      >
                        <div className="me-2 fw-bold text-nowrap">
                          Missing Pages
                        </div>
                        <div className="text-truncate">
                          {edition.missing_pages.length
                            ? edition.missing_pages.join(", ")
                            : "Complete"}
                        </div>
                      </div>
                      <div
                        className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow"
                        title={
                          edition.missing_from_chp.length
                            ? edition.missing_from_chp.join(", ")
                            : ""
                        }
                      >
                        <div className="me-2 fw-bold text-nowrap">
                          Missing from CHP
                        </div>
                        <div className="text-truncate">
                          {edition.missing_from_chp.length
                            ? edition.missing_from_chp.join(", ")
                            : "Complete"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutputSummary;
