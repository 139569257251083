import { useState, useEffect } from "react";
import { callApi } from "../../common/utils";

const EditionPicker = ({ editionDate, editionDateEditions, setSelectedEdition, selectedEdition, setEditionDateEditions }) => {
  const [disabledEditionPicker, setDisabledEditionPicker] = useState(editionDateEditions.length ? false : true);
  const [firstOptionText, setFirstOptionText] = useState("");

  const fetchEditionList = async () => {
    //  Format date
    let year = editionDate.getFullYear();
    let month = (editionDate.getMonth() + 1).toString().padStart(2, "0");
    let day = editionDate.getDate().toString().padStart(2, "0");
    let formattedDate = [year, month, day].join("");

    const editionListRequest = await callApi({ path: `edition_preview/list_editions/${formattedDate}` });
    const editionList = editionListRequest.content;

    setEditionDateEditions(editionList);
    if (!editionList.all.includes(selectedEdition)) {
      setSelectedEdition(null);
    }
    return editionList;
  };

  useEffect(() => {
    // Disable Edition Picker if no editions found for that date
    const runFetchEditionList = async () => {
      const editionList = await fetchEditionList();
      setDisabledEditionPicker(editionList.all && editionList.all.length > 0 ? false : true);
      setFirstOptionText(editionList.all && editionList.all.length > 0 ? "" : "No Editions Available");
    };
    runFetchEditionList();

    const interval = 300;
    const intervalId = setInterval(() => {
      runFetchEditionList();
    }, interval * 1000);
    return () => clearInterval(intervalId);
  }, [editionDate, selectedEdition]);

  return (
    <div className="form-floating">
      <select
        id="dpePicker"
        className="form-select w-100"
        onChange={(e) => setSelectedEdition(e.target.value)}
        value={selectedEdition || "placeholder"}
        disabled={disabledEditionPicker}
        style={{ minWidth: 200 }}
      >
        <option value="placeholder" disabled>
          {firstOptionText}
        </option>
        {editionDateEditions.external && editionDateEditions.external.length > 0 ? (
          <optgroup label="External">
            {editionDateEditions.external.map((edition) => (
              <option key={edition} value={`${edition}`}>
                {edition.replace(/^ext_/, "").replace(/_[a-zA-Z ]+$/, "")}
              </option>
            ))}
          </optgroup>
        ) : (
          ""
        )}
        {editionDateEditions.methode &&
          editionDateEditions.methode.map((edition) => (
            <option key={edition} value={`${edition}`}>
              {edition}
            </option>
          ))}
      </select>
      <label htmlFor="dpePicker">Select an Edition</label>
    </div>
  );
};

export default EditionPicker;
