import PageHeader from "../../components/common/pageHeaderNew";
import { useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import { callApi, getAttApiKey, epochToDate } from "../../common/utils";
import Pagination from "../../components/common/Pagination";

const ActivityLog = ({ attApiLogData, setAttApiLogData, attApiLogFilter, setAttApiLogFilter, attApiLogSearch, setAttApiLogSearch, attApiLogSearchPage, setAttApiLogSearchPage }) => {
  // Attribution Api Endpoint
  const attributionApiKey = getAttApiKey();

  // State
  const [displayAttApiLoader, setDisplayAttApiLoader] = useState(false);
  const [loaderText, setLoaderText] = useState("Loading Log Data");

  // Search defaults
  const hits = 100;

  // Get log data
  const getLogData = async (page_no = 1, displayLoader = true) => {
    if (displayLoader) setDisplayAttApiLoader(true);
    let params = {
      filter: attApiLogFilter,
      page: page_no,
    };
    if (attApiLogSearch.trim().length > 0) {
      params.search = attApiLogSearch.trim();
    }
    const logResponse = await callApi({
      path: "authorapi/authors/log",
      params: params,
      headers: {
        "x-api-key": attributionApiKey,
      },
      id: true,
    });

    const logData = logResponse.content;
    if (logResponse.status !== 200) {
      alert(`Status ${logResponse.status} status code retuned,\n\n${logData}`);
      if (displayLoader) setDisplayAttApiLoader(false);
      return;
    }

    if ("results" in logData) {
      setAttApiLogData(logData);
    }
    console.table(logData.results);
    if (displayLoader) setDisplayAttApiLoader(false);
  };

  const onLogSearch = (e) => {
    e.preventDefault();
    setAttApiLogSearchPage(1);
    getLogData();
  };

  const onSetPage = (pageNum) => {
    setAttApiLogSearchPage(pageNum);
    getLogData(pageNum);
  };

  useEffect(() => {
    setAttApiLogSearchPage(1);
    getLogData();
  }, [attApiLogFilter]);

  return (
    <div className="d-flex" style={{ height: "100%", overflowY: "hidden" }}>
      <div className="p1 d-flex flex-column flex-grow-1">
        <PageHeader
          rightContent={
            <form onSubmit={onLogSearch}>
              <div className="row">
                <div className="col-6 col-lg-auto">
                  <div className="form-floating">
                    <select id="attApiAuthorFilter" className="form-select" value={attApiLogFilter} onChange={(e) => setAttApiLogFilter(e.target.value)}>
                      <option value="all">All</option>
                      {/* <option value="range|custom">Active Directory Sync</option> */}
                      <optgroup label="Create Author">
                        <option value="create_all">All Create Events</option>
                        <option value="create_manual">Manual Create Events</option>
                        <option value="create_adsync">Active Directory Create Events</option>
                      </optgroup>
                      <optgroup label="Update Author">
                        <option value="update_all">All Update Events</option>
                        <option value="update_manual">Manual Update Events</option>
                        <option value="update_adsync">Active Directory Update Events</option>
                      </optgroup>
                      <optgroup label="Delete Author">
                        <option value="delete_all">All Delete Events</option>
                        <option value="delete_manual">Manual Delete Events</option>
                        <option value="delete_adsync">Active Directory Delete Events</option>
                      </optgroup>
                      <optgroup label="Active Directory">
                        <option value="sync">Sync Summary</option>
                      </optgroup>
                    </select>
                    <label htmlFor="attApiAuthorFilter">Log Events</label>
                  </div>
                </div>
                <div className="col-6 col-lg-auto">
                  <div className="form-floating">
                    <input type="text" className="form-control" id="attApiAuthorSearch" placeholder="Search" value={attApiLogSearch} onChange={(e) => setAttApiLogSearch(e.target.value)}></input>
                    <label htmlFor="npaPagePicker">Search</label>
                  </div>
                </div>
              </div>
            </form>
          }
        />
        <div className={`content p-3 d-flex flex-column flex-grow-1 overflow-auto`} style={{ position: "relative" }}>
          {displayAttApiLoader && (
            <div
              className="d-flex flex-column justify-content-center"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
                backgroundColor: "rgb(231, 233, 235)",
                opacity: 0.9,
              }}
            >
              <Bars height="80" width="80" color="#f8ad17" ariaLabel="bars-loading" wrapperStyle={{ marginLeft: "auto", marginRight: "auto" }} wrapperClass="p-2 loadingBars" visible={true} />
              <h3 className="text-center p-2">{loaderText}</h3>
            </div>
          )}
          {attApiLogData !== null ? (
            attApiLogData.results && attApiLogData.results.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-striped align-middle table-hover fixed compact">
                  <thead>
                    <tr>
                      {attApiLogFilter === "all" ? (
                        <>
                          <th className="text-nowrap" scope="col" style={{ width: 210 }}>
                            Date Time
                          </th>
                          <th className="text-nowrap" scope="col" style={{ width: 150 }}>
                            Event Type
                          </th>
                          <th className="text-nowrap" scope="col" style={{ width: 180 }}>
                            User
                          </th>
                          <th className="text-nowrap" scope="col">
                            Message
                          </th>
                        </>
                      ) : attApiLogFilter === "sync" ? (
                        <>
                          <th className="text-nowrap" scope="col" style={{ width: 210 }}>
                            Date Time
                          </th>
                          <th className="text-nowrap" scope="col" style={{ width: 250 }}>
                            Sync ID
                          </th>
                          <th className="text-nowrap" scope="col">
                            Message
                          </th>
                          <th className="text-nowrap text-center" scope="col" style={{ width: 100 }}>
                            Created
                          </th>
                          <th className="text-nowrap text-center" scope="col" style={{ width: 100 }}>
                            Updated
                          </th>
                          <th className="text-nowrap text-center" scope="col" style={{ width: 100 }}>
                            Deleted
                          </th>
                        </>
                      ) : (
                        <>
                          <th className="text-nowrap" scope="col" style={{ width: 210 }}>
                            Date Time
                          </th>
                          {attApiLogFilter.split("_")[1] === "adsync" ? (
                            <th className="text-nowrap" style={{ width: 250 }}>
                              Sync ID
                            </th>
                          ) : (
                            ""
                          )}
                          <th className="text-nowrap" scope="col" style={{ width: 180 }}>
                            Author Name
                          </th>
                          {attApiLogFilter.split("_")[1] !== "adsync" ? (
                            <th className="text-nowrap" style={{ width: 180 }}>
                              {attApiLogFilter.split("_")[0] === "create" ? "Created By" : attApiLogFilter.split("_")[0] === "update" ? "Updated By" : "Deleted By"}
                            </th>
                          ) : (
                            ""
                          )}
                          <th className="text-nowrap" scope="col">
                            Message
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {attApiLogData.results.map((logEntry, i) => (
                      <tr key={logEntry.sk}>
                        {attApiLogFilter === "all" ? (
                          <>
                            <td className="text-nowrap pe-5">{epochToDate(logEntry.updated).toString()}</td>
                            <td className="text-nowrap pe-5">{logEntry.log_type}</td>
                            <td className="text-nowrap pe-5">{logEntry.user || logEntry.log_source}</td>
                            <td className="text-nowrap" title={logEntry.message}>
                              {logEntry.message}
                            </td>
                          </>
                        ) : attApiLogFilter === "sync" ? (
                          <>
                            <td className="text-nowrap pe-5">{epochToDate(logEntry.updated).toString()}</td>
                            <td className="text-nowrap">{logEntry.sync_id}</td>
                            <td className="text-nowrap pe-5" title={logEntry.message}>
                              {logEntry.message}
                            </td>
                            <td className="text-nowrap text-center">{logEntry.new_authors}</td>
                            <td className="text-nowrap text-center">{logEntry.updated_authors}</td>
                            <td className="text-nowrap text-center">{logEntry.deleted_authors}</td>
                          </>
                        ) : (
                          <>
                            <td className="text-nowrap pe-5">{epochToDate(logEntry.updated).toString()}</td>
                            {attApiLogFilter.split("_")[1] === "adsync" ? <td className="text-nowrap pe-5">{logEntry.sync_id}</td> : ""}
                            <td className="text-nowrap pe-5">{logEntry.name}</td>
                            {attApiLogFilter.split("_")[1] !== "adsync" ? <td className="text-nowrap pe-5">{logEntry.user || logEntry.log_source}</td> : ""}
                            <td className="text-nowrap">{logEntry.message}</td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {attApiLogData.count > hits && (
                  <>
                    <Pagination
                      className="mb-2 mt-4"
                      itemsPerPage={hits}
                      totalItems={attApiLogData.count}
                      currentPage={attApiLogSearchPage}
                      onPageChange={(e) => onSetPage(e)}
                      onNextPage={() => onSetPage(attApiLogSearchPage + 1)}
                      onPrevPage={() => onSetPage(attApiLogSearchPage - 1)}
                    />
                  </>
                )}
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-top text-center"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                  paddingTop: "5%",
                }}
              >
                <h4>No Log Entries Found</h4>
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;
