import DateSelector from "../components/common/dateSelector";
import PageHeader from "../components/common/pageHeader";
import DpePicker from "../components/edition_preview/DpePicker";
import { useEffect, useRef } from "react";
import { Routes, Route, useLocation, useNavigate, useParams } from "react-router-dom";
import { callApi } from "../common/utils";
import DpePages from "../components/edition_preview/DpePages";
import "../styles/Pages.scss";

const DpePreview = ({ dpeDate, setDpeDate, dpeDateEditions, setDpeDateEditions, selectedDpe, setSelectedDpe, pageData, setPageData, defaultDpeView, setDefaultDpeView }) => {
  const formatDate = () => {
    let year = dpeDate.getFullYear();
    let month = (dpeDate.getMonth() + 1).toString().padStart(2, "0");
    let day = dpeDate.getDate().toString().padStart(2, "0");
    return [year, month, day].join("");
  };

  const toDate = (dateString) => {
    const year = +dateString.substring(0, 4);
    const month = +dateString.substring(4, 6);
    const day = +dateString.substring(6, 8);
    const date = new Date(year, month - 1, day);
    return date;
  };

  const getDpe = async () => {
    if (dpeDate && selectedDpe) {
      const dpeDataRequest = await callApi({ path: `edition_preview/get_dpe/${formatDate(dpeDate)}/${selectedDpe}` });
      const dpeData = dpeDataRequest.content;

      // Only update pageData if checksum has changed
      if (!"checkSum" in pageData || pageData.checkSum !== dpeData.checkSum) {
        setPageData(dpeData);
        console.log("Something has changed!", pageData, dpeData);
      } else {
        console.log("Nothing has changed!");
      }
    }
  };

  const getView = () => {
    switch (defaultDpeView) {
      case "compact":
        return "compactView";
      case "thumbnail":
        return "thumbnailView";
    }
    return "";
  };

  // Update DPE when dpeDate or selectedDpe
  useEffect(() => {
    getDpe();
    document.getElementById("mainElement").scrollTop = 0;
  }, [dpeDate, selectedDpe]); // Removed pageData

  useEffect(() => {
    // Update UI every $interval seconds
    const interval = 120;
    const i = setInterval(() => {
      getDpe();
    }, interval * 1000);

    return () => {
      clearInterval(i);
    };
  }, [dpeDate, selectedDpe, pageData]);

  // Load state from local storage
  useEffect(() => {
    const ls = JSON.parse(localStorage.getItem("defaultDpeView"));
    if (ls) {
      setDefaultDpeView(ls);
    }
  }, []);

  const dpeViewChange = (view) => {
    localStorage.setItem("defaultDpeView", JSON.stringify(view));
    setDefaultDpeView(view);
  };

  return (
    <div className="p-1">
      <PageHeader
        rightContent={
          <div className="row">
            <div className="col-6 col-lg-auto">
              <DateSelector defaultDate={dpeDate} onChange={setDpeDate} label="DPE Date" />
            </div>
            <div className="col-6 col-lg-auto">
              <DpePicker dpeDate={dpeDate} dpeDateEditions={dpeDateEditions} setSelectedDpe={setSelectedDpe} selectedDpe={selectedDpe} setDpeDateEditions={setDpeDateEditions} />
            </div>
          </div>
        }
        leftContent={
          <div className="d-none d-lg-block">
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio1"
                autoComplete="off"
                onChange={() => dpeViewChange("detailed")}
                checked={defaultDpeView === "detailed" ? true : false}
              />
              <label className="btn btn-outline-dark" htmlFor="btnradio1">
                Details View
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio2"
                autoComplete="off"
                onChange={() => dpeViewChange("compact")}
                checked={defaultDpeView === "compact" ? true : false}
              />
              <label className="btn btn-outline-dark" htmlFor="btnradio2">
                Compact View
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio3"
                autoComplete="off"
                onChange={() => dpeViewChange("thumbnail")}
                checked={defaultDpeView === "thumbnail" ? true : false}
              />
              <label className="btn btn-outline-dark" htmlFor="btnradio3">
                Thumbnail View
              </label>
            </div>
          </div>
        }
      />
      <div className={`content p-1 d-flex flex-column flex-grow-1 ${getView()}`}>
        <DpePages pageData={pageData} />
      </div>
    </div>
  );
};

export default DpePreview;
