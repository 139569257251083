import { useState, useEffect } from "react";
import { callApi } from "../../common/utils";

const DpePicker = ({ dpeDate, dpeDateEditions, setSelectedDpe, selectedDpe, setDpeDateEditions }) => {
  const [disabledEditionPicker, setDisabledEditionPicker] = useState(dpeDateEditions.length ? false : true);
  const [firstOptionText, setFirstOptionText] = useState("");

  const fetchDpeList = async () => {
    //  Format date
    let year = dpeDate.getFullYear();
    let month = (dpeDate.getMonth() + 1).toString().padStart(2, "0");
    let day = dpeDate.getDate().toString().padStart(2, "0");
    let formattedDate = [year, month, day].join("");

    const dpeListRequest = await callApi({ path: `edition_preview/list_dpes/${formattedDate}` });
    const dpeList = dpeListRequest.content;

    setDpeDateEditions(dpeList);
    if (!dpeList.includes(selectedDpe)) {
      setSelectedDpe(null);
    }
    return dpeList;
  };

  useEffect(() => {
    // Disable DPE Picker if no editions found for that date
    const runFetchDpeList = async () => {
      const dpeList = await fetchDpeList();
      setDisabledEditionPicker(dpeList.length > 0 ? false : true);
      setFirstOptionText(dpeList.length > 0 ? "" : "No DPEs Available");
    };
    runFetchDpeList();

    const interval = 300;
    const intervalId = setInterval(() => {
      runFetchDpeList();
    }, interval * 1000);
    return () => clearInterval(intervalId);
  }, [dpeDate, selectedDpe]);

  return (
    <div className="form-floating">
      <select id="dpePicker" className="form-select" onChange={(e) => setSelectedDpe(e.target.value)} value={selectedDpe || "placeholder"} disabled={disabledEditionPicker} style={{ minWidth: 200 }}>
        <option value="placeholder" disabled>
          {firstOptionText}
        </option>
        {dpeDateEditions.map((dpe) => (
          <option key={dpe} value={`${dpe}`}>
            {dpe}
          </option>
        ))}
      </select>
      <label htmlFor="dpePicker">Select a DPE</label>
    </div>
  );
};

export default DpePicker;
