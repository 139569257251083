import { useState, useEffect } from "react";
import { callApi, formatBytes } from "../../common/utils";
import PageHeader from "../../components/common/pageHeaderNew";
import { Bars } from "react-loader-spinner";
import Pagination from "../../components/common/Pagination";
import { MdDownload } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";

const FeedTransform = () => {
  // State
  const [displayTransformerLoader, setDisplayTransformerLoader] =
    useState(false);
  const [loaderText, setLoaderText] = useState("Loading Log Data");
  const [transformData, setTransformData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState("");

  // Search defaults
  const hits = 100;

  const formatDate = (dateString, displayMonth = true, displayYear = false) => {
    const dateObj = new Date(dateString);
    let year = dateObj.getFullYear();
    let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    let day = dateObj.getDate().toString().padStart(2, "0");
    let hour = dateObj.getHours();
    let minute = dateObj.getMinutes().toString().padStart(2, "0");
    let second = dateObj.getSeconds().toString().padStart(2, "0");
    let ampm = hour < 12 ? "AM" : "PM";
    if (hour === 0) {
      hour = 12;
    } else if (hour > 12) {
      hour = hour - 12;
    }
    hour = hour.toString();
    if (displayYear) {
      return `${hour}:${minute}:${second} ${ampm}, ${day}/${month}/${year}`;
    } else if (displayMonth) {
      return `${hour}:${minute}:${second} ${ampm}, ${day}/${month}`;
    } else {
      return `${hour}:${minute}:${second} ${ampm}`;
    }
  };

  const downloadTransform = async (transformId, download = true) => {
    const purlResponse = await callApi({
      path: `transformer/get_output`,
      params: {
        id: transformId,
        download: download,
      },
    });
    if (purlResponse.status !== 200) {
      alert(
        `Status ${purlResponse.status} status code retuned,\n\n${purlResponse.content}`
      );
      return;
    }
    const purlData = purlResponse.content;
    if ("url" in purlData) {
      if (download) {
        window.location.replace(purlData.url);
      } else {
        window.open(purlData.url, "_blank");
      }
    }
  };

  // Get transform data
  const getTransformData = async (page_no = 1, displayLoader = true) => {
    if (displayLoader) setDisplayTransformerLoader(true);
    const transformResponse = await callApi({
      path: "transformer/processing_log",
    });

    const transformData = transformResponse.content;
    if (transformResponse.status !== 200) {
      alert(
        `Status ${transformResponse.status} status code retuned,\n\n${transformData}`
      );
      if (displayLoader) setDisplayTransformerLoader(false);
      return;
    }

    if ("results" in transformData) {
      setTransformData(transformData);
    }
    console.table(transformData.results);
    if (displayLoader) setDisplayTransformerLoader(false);
    setLastUpdated(formatDate(new Date()));
  };

  useEffect(() => {
    getTransformData();
  }, []);

  useEffect(() => {
    // Update UI every $interval seconds
    const interval = 30;
    const i = setInterval(() => {
      getTransformData(1, false);
    }, interval * 1000);

    return () => {
      clearInterval(i);
    };
  }, []);

  return (
    <div className="d-flex" style={{ height: "100%", overflowY: "hidden" }}>
      <div className="p1 d-flex flex-column flex-grow-1">
        <PageHeader
          rightContent={
            <div className="row pe-2">
              <div className="col-12 col-lg-auto">
                <button
                  type="button"
                  className="btn btn-outline-dark btn-sm"
                  onClick={() => getTransformData()}
                >
                  <TbRefresh
                    className="me-1"
                    style={{ top: -1, position: "relative" }}
                  />
                  Refresh Data
                </button>
              </div>
            </div>
          }
          leftContent={
            <div className="row ps-2">
              <div
                className="col-12 col-lg-auto"
                style={{ fontSize: "0.9rem", fontWeight: "bold" }}
              >
                {lastUpdated !== "" && <>Last Updated {lastUpdated}</>}
              </div>
            </div>
          }
        />
        <div
          className={`content p-3 d-flex flex-column flex-grow-1 overflow-auto`}
          style={{ position: "relative" }}
        >
          {displayTransformerLoader && (
            <div
              className="d-flex flex-column justify-content-center"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: 10,
                backgroundColor: "rgb(231, 233, 235)",
                opacity: 0.9,
              }}
            >
              <Bars
                height="80"
                width="80"
                color="#f8ad17"
                ariaLabel="bars-loading"
                wrapperStyle={{ marginLeft: "auto", marginRight: "auto" }}
                wrapperClass="p-2 loadingBars"
                visible={true}
              />
              <h3 className="text-center p-2">{loaderText}</h3>
            </div>
          )}
          {transformData !== null ? (
            transformData?.results.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-striped align-middle table-hover fixed compact">
                  <thead>
                    <tr>
                      <>
                        <th
                          className="text-nowrap"
                          scope="col"
                          style={{ width: 180 }}
                        >
                          Date / Time
                        </th>
                        <th
                          className="text-nowrap"
                          scope="col"
                          style={{ width: 250 }}
                        >
                          Channel
                        </th>
                        <th className="text-nowrap" scope="col">
                          Filename
                        </th>
                        <th
                          className="text-nowrap"
                          scope="col"
                          style={{ width: 130 }}
                        >
                          Input Size
                        </th>
                        <th
                          className="text-nowrap"
                          scope="col"
                          style={{ width: 130 }}
                        >
                          Output Size
                        </th>
                        <th
                          className="text-nowrap"
                          scope="col"
                          style={{ width: 130 }}
                        >
                          Result
                        </th>
                        <th
                          className="text-nowrap"
                          scope="col"
                          style={{ width: 100 }}
                        >
                          Download
                        </th>
                      </>
                    </tr>
                  </thead>
                  <tbody>
                    {transformData.results.map((logEntry, i) => (
                      <tr key={logEntry.sk}>
                        <td
                          className="text-nowrap pe-5"
                          title={formatDate(logEntry.processed, true, true)}
                        >
                          {formatDate(logEntry.processed)}
                        </td>
                        <td className="text-nowrap pe-5">{logEntry.channel}</td>
                        <td
                          className="text-nowrap pe-5"
                          title={logEntry.input.split("/").pop()}
                        >
                          {logEntry.input.split("/").pop()}
                        </td>
                        <td className="text-nowrap pe-5">
                          {formatBytes(logEntry.input_size)}
                        </td>
                        <td className="text-nowrap pe-5">
                          {formatBytes(logEntry.output_size)}
                        </td>
                        <td className="text-nowrap pe-5">{logEntry.result}</td>
                        <td className="text-nowrap pe-5">
                          <button
                            className="btn"
                            onClick={() => downloadTransform(logEntry.sk)}
                          >
                            <MdDownload />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {transformData.count > hits && (
                  <>
                    <Pagination
                      className="mb-2 mt-4"
                      itemsPerPage={hits}
                      totalItems={transformData.count}
                      currentPage={1}
                      onPageChange={(e) => console.log(e)}
                      onNextPage={(e) => console.log(e)}
                      onPrevPage={(e) => console.log(e)}
                    />
                  </>
                )}
              </div>
            ) : (
              <div
                className="d-flex flex-column justify-content-top text-center"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                  paddingTop: "5%",
                }}
              >
                <h4>No Log Entries Found</h4>
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedTransform;
