import { callApi } from "../../common/utils";
import ReactTooltip from "react-tooltip";
import { useState, useEffect } from "react";
import { MdMenuBook, MdDownload } from "react-icons/md";
import { BsNewspaper } from "react-icons/bs";

// Start

import { useRef } from "react";
import { VscPreview } from "react-icons/vsc";
import { HiOutlineMagnifyingGlassPlus } from "react-icons/hi2";
import { FaFilePdf } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";

import { VscOpenPreview } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";

// End

const NpaPage = ({ page, setPreviewImageInfo, setShowPreviewModal, setShowAdDetailsModal, setAdDetailsInfo }) => {
  const [legacyThumbnail, setLegacyThumbnail] = useState(null);

  const getPage = async (cookie, filename, download = false) => {
    const pageResponse = await callApi({ path: "npa/get_page", vpc: true, params: { cookie: cookie, filename: filename, download: download } });

    // Exit if not returned
    if (pageResponse.status !== 200) {
      alert(pageResponse.content);
      return;
    }

    const purl = pageResponse.content;
    if (download === true) {
      window.location.replace(purl);
    } else {
      window.open(purl, "_blank");
    }
  };

  const getPageJpg = async (cookie, filename, download = false) => {
    const pageResponse = await callApi({ path: "npa/get_page_jpg", vpc: true, params: { cookie: cookie, filename: filename, download: download } });

    // Exit if not returned
    if (pageResponse.status !== 200) {
      alert(pageResponse.content);
      return;
    }

    const purl = pageResponse.content;
    if (download === true) {
      window.location.replace(purl);
    } else {
      window.open(purl, "_blank");
    }
  };

  const getEditionPage = async (page, related_page = 1, download = false) => {
    if (related_page !== 1) {
      // Get facing page
      if (page.PAGE_NO % 2) {
        related_page = page.PAGE_NO - 1;
      } else {
        related_page = page.PAGE_NO + 1;
      }
    }
    const pageResponse = await callApi({
      path: "npa/get_edition_page",
      vpc: true,
      params: { publication: page.PUBLICATION, edition: page.EDITION, pubdate: page.PUBDATE, page_no: related_page, download: download },
    });

    // Exit if not returned
    if (pageResponse.status !== 200) {
      alert(pageResponse.content);
      return;
    }

    const purl = pageResponse.content;
    if (download === true) {
      window.location.replace(purl);
    } else {
      window.open(purl, "_blank");
    }
  };

  const renderLegacyThumb = async () => {
    const thumbResponse = await callApi({ path: "npa/get_legacy_thumb", vpc: true, params: { s3path: page.thumbnail } });
    setLegacyThumbnail(thumbResponse.content);
  };

  // Start

  const [showContext, setShowContext] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState([0, 0]);

  const ContextMenu = (props) => {
    const ref = useRef(null);
    const wrapperRef = useRef();

    useEffect(() => {
      if (showContext) {
        window.addEventListener("touchmove", closeContextTouch);
        window.addEventListener("touchend", closeContextTouch);
      } else {
        window.removeEventListener("touchmove", closeContextTouch);
        window.removeEventListener("touchend", closeContextTouch);
      }
    }, [showContext]);

    const handleRightClick = (e) => {
      e.preventDefault();
      let pr = wrapperRef.current.getBoundingClientRect();
      let clickX = e.clientX - pr.left;
      let clickY = e.clientY - pr.top;
      let contextWidth = ref.current.offsetWidth;
      let contextHeight = ref.current.offsetHeight;
      let pageWidth = document.body.clientWidth;
      let pageHeight = document.body.clientHeight;
      let contextXPos = pr.left + clickX + contextWidth > pageWidth ? clickX - contextWidth : clickX;
      let contextYPos = pr.top + clickY + contextHeight > pageHeight ? clickY - contextHeight : clickY;

      if (props.data) {
        setContextMenuPosition([contextXPos, contextYPos]);
        setShowContext(true);
      } else {
      }
    };

    const handleMouseMove = (e) => {
      if (showContext) {
        setShowContext(false);
      }
    };

    // Close context menu when clicked else where
    const closeContextTouch = () => {
      setShowContext(false);
    };

    return (
      <div onContextMenu={handleRightClick} onMouseLeave={handleMouseMove} onClick={() => setShowContext(false)} ref={wrapperRef} style={{ position: "relative" }}>
        {props.children}
        <ul
          ref={ref}
          className="contextMenu list-group dropdown-menu dropdown-menu-dark pt-0 pb-0"
          style={{
            boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
            cursor: "pointer",
            position: "absolute",
            zIndex: showContext ? "2000" : "-1000",
            visibility: showContext ? "visible" : "hidden",
            // display: showContext ? "flex" : "none",
            left: contextMenuPosition[0],
            top: contextMenuPosition[1],
          }}
        >
          {props.data.CUSTOMERNAME && props.data.PAGE_NO !== 1 && (
            <>
              <li className="dropdown-item p-2 bg-transparent pt-2 pb-2" onTouchStart={() => getEditionPage(props.data, 1, true)} onClick={() => getEditionPage(props.data, 1, true)}>
                <MdDownload className="me-2" />
                <small className="position-relative" style={{ top: "1px", fontSize: 13 }}>
                  Download Front Page PDF
                </small>
              </li>
              <li
                className="dropdown-item p-2 bg-transparent pt-2 pb-2"
                style={{ borderBottom: "1px solid #585858" }}
                onTouchStart={() => getEditionPage(props.data, 1)}
                onClick={() => getEditionPage(props.data, 1)}
              >
                <VscPreview className="me-2" />
                <small className="position-relative" style={{ top: "1px", fontSize: 13 }}>
                  Open Front Page PDF
                </small>
              </li>
            </>
          )}
          {props.data.CUSTOMERNAME && page.PAGE_NO !== page.PAGE_COUNT && page.PAGE_NO !== 1 && (
            <>
              <li
                className="dropdown-item p-2 bg-transparent pt-2 pb-2"
                onTouchStart={() => getEditionPage(props.data, props.data.PAGENO % 2 ? props.data.PAGE_NO - 1 : props.data.PAGE_NO + 1, true)}
                onClick={() => getEditionPage(props.data, props.data.PAGENO % 2 ? props.data.PAGE_NO - 1 : props.data.PAGE_NO + 1, true)}
              >
                <MdDownload className="me-2" />
                <small className="position-relative" style={{ top: "1px", fontSize: 13 }}>
                  Download Facing Page PDF
                </small>
              </li>
              <li
                className="dropdown-item p-2 bg-transparent pt-2 pb-2"
                style={{ borderBottom: "1px solid #585858" }}
                onTouchStart={() => getEditionPage(props.data, props.data.PAGENO % 2 ? props.data.PAGE_NO - 1 : props.data.PAGE_NO + 1)}
                onClick={() => getEditionPage(props.data, props.data.PAGENO % 2 ? props.data.PAGE_NO - 1 : props.data.PAGE_NO + 1)}
              >
                <MdMenuBook className="me-2" />
                <small className="position-relative" style={{ top: "1px", fontSize: 13 }}>
                  Open Facing Page PDF
                </small>
              </li>
            </>
          )}
          <li
            className="dropdown-item p-2 bg-transparent pt-2 pb-2"
            onTouchStart={() => getPage(props.data.COOKIE, props.data.FILENAME, true)}
            onClick={() => getPage(props.data.COOKIE, props.data.FILENAME, true)}
          >
            <MdDownload className="me-2" />
            <small className="position-relative" style={{ top: "1px", fontSize: 13 }}>
              Download PDF
            </small>
          </li>
          <li
            className="dropdown-item p-2 bg-transparent pt-2 pb-2"
            style={{ borderBottom: "1px solid #585858" }}
            onTouchStart={() => getPage(props.data.COOKIE, props.data.FILENAME)}
            onClick={() => getPage(props.data.COOKIE, props.data.FILENAME)}
          >
            <FaFilePdf className="me-2" />
            <small className="position-relative" style={{ top: "1px", fontSize: 13 }}>
              Open PDF
            </small>
          </li>
          <li
            className="dropdown-item p-2 bg-transparent pt-2 pb-2"
            onTouchStart={() => getPageJpg(props.data.COOKIE, props.data.FILENAME, true)}
            onClick={() => getPageJpg(props.data.COOKIE, props.data.FILENAME, true)}
          >
            <MdDownload className="me-2" />
            <small className="position-relative" style={{ top: "1px", fontSize: 13 }}>
              Download as JPG
            </small>
          </li>
          <li className="dropdown-item p-2 bg-transparent pt-2 pb-2" onTouchStart={() => getHiresPreview(props.data.COOKIE)} onClick={() => getHiresPreview(props.data.COOKIE)}>
            <HiOutlineMagnifyingGlassPlus className="me-2" />
            <small className="position-relative" style={{ top: "1px", fontSize: 13 }}>
              Preview
            </small>
          </li>
          {/* {props.data.CUSTOMERNAME && (
            <li
              className="dropdown-item p-2 bg-transparent pt-2 pb-2"
              onTouchStart={() => openAdDetailsModal(props.data)}
              onClick={() => openAdDetailsModal(props.data)}
              style={{ borderTop: "1px solid #585858" }}
            >
              <TbListDetails className="me-2" />
              <small className="position-relative" style={{ top: "1px", fontSize: 13 }}>
                Ad Details
              </small>
            </li>
          )} */}
        </ul>
      </div>
    );
  };

  const openAdDetailsModal = async (page) => {
    const thumbResponse = await callApi({ path: "npa/get_hires_thumb", vpc: true, params: { cookie: page.COOKIE } });
    if (thumbResponse.status !== 200) {
      alert("Something went wrong!");
      return;
    }
    setPreviewImageInfo(thumbResponse.content);
    setAdDetailsInfo(page);
    setShowAdDetailsModal(true);
  };

  const getHiresPreview = async (cookie) => {
    const thumbResponse = await callApi({ path: "npa/get_hires_thumb", vpc: true, params: { cookie: cookie } });
    if (thumbResponse.status !== 200) {
      alert("Something went wrong!");
      return;
    }
    setPreviewImageInfo(thumbResponse.content);
    setShowPreviewModal(true);
    // console.log(thumbResponse);
  };

  // End

  useEffect(() => {
    // console.log(page.thumbnail);
    if (page.ASSETFORMAT !== 0) {
      //   console.log(page.thumbnail);
      renderLegacyThumb();
    }
  }, [page.thumbnail]);

  return (
    <div key={page.FILENAME} className={`col-4 col-lg-3 col-xxl-2 col-xxxl-1 mb-3 pageWrapper ${page.LEGALNOTICE ? "legalNotice" : ""}`}>
      <ContextMenu data={page}>
        <div
          // {...(page.LEGALNOTICE && { "data-tip": `This page contains a legal warning. \n ${page.LEGALNOTICE}` })} // Legal Notice - Tooltip text
          {...(page.LEGALNOTICE && { "data-tip": `<h6>This page contains a legal warning and cannot be downloaded.</h6><p class="mb-0">Details : ${page.LEGALNOTICE}</p>` })} // Legal Notice - Tooltip text
          data-for={page.FILENAME}
          className="page"
          style={{
            aspectRatio: page.WIDTH / page.HEIGHT,
            padding: "5% 3%",
            // paddingBottom: "5%",
            display: "flex",
            justifyContent: "space-evenly",
            columnGap: "1%",
            position: "relative",
          }}
        >
          {/* Columns */}
          <div style={{ height: "100%", flexGrow: "1", border: "1px solid #CCC" }}></div>
          <div style={{ height: "100%", flexGrow: "1", border: "1px solid #CCC" }}></div>
          <div style={{ height: "100%", flexGrow: "1", border: "1px solid #CCC" }}></div>
          <div style={{ height: "100%", flexGrow: "1", border: "1px solid #CCC" }}></div>
          <div style={{ height: "100%", flexGrow: "1", border: "1px solid #CCC" }}></div>

          {/* Legal Notice - Ribbon + Tooltip */}
          {page.LEGALNOTICE ? (
            <>
              <ReactTooltip id={page.FILENAME} effect="solid" place="bottom" html={true} className="npaTooltip" />
              <div className="legal-warning">Legal Warning</div>
            </>
          ) : (
            ""
          )}
          {/* {page.AD_HEIGHT & page.ZZZ ? (
          <>
            <img
              onClick={() => getPage(page.COOKIE, page.FILENAME)}
              src={page.ASSETFORMAT === 0 ? page.thumbnail : legacyThumbnail}
              className="img-fluid"
              style={{
                maxWidth: "auto",
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
                filter: "brightness(0.7)",
              }}
            />
            <div
              style={{
                top: `${(page.AD_Y_POS / page.PAGE_HEIGHT) * 100}%`,
                right: `${((page.PAGE_WIDTH - page.AD_X_POS - page.AD_WIDTH) / page.PAGE_WIDTH) * 100}%`,
                bottom: `${((page.PAGE_HEIGHT - page.AD_Y_POS - page.AD_HEIGHT) / page.PAGE_HEIGHT) * 100}%`,
                left: `${(page.AD_X_POS / page.PAGE_WIDTH) * 100}%`,
                position: "absolute",
                boxShadow: "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0)",
              }}
            ></div>
            <img
              onClick={() => getPage(page.COOKIE, page.FILENAME)}
              src={page.ASSETFORMAT === 0 ? page.thumbnail : legacyThumbnail}
              className="img-fluid"
              style={{
                maxWidth: "auto",
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
                clipPath: `inset(${(page.AD_Y_POS / page.PAGE_HEIGHT) * 100}% ${((page.PAGE_WIDTH - page.AD_X_POS - page.AD_WIDTH) / page.PAGE_WIDTH) * 100}% ${
                  ((page.PAGE_HEIGHT - page.AD_Y_POS - page.AD_HEIGHT) / page.PAGE_HEIGHT) * 100
                }% ${(page.AD_X_POS / page.PAGE_WIDTH) * 100}%)`,
              }}
            />
          </>
        ) : ( */}
          <img
            {...(!page.LEGALNOTICE && { onClick: () => getPage(page.COOKIE, page.FILENAME) })} // Legal Notice - Remove onclick
            // onClick={() => getPage(page.COOKIE, page.FILENAME)}
            src={page.ASSETFORMAT === 0 ? page.thumbnail : legacyThumbnail}
            className="img-fluid"
            title="Right Click For More Options"
            style={{
              maxWidth: "auto",
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
              cursor: page.LEGALNOTICE ? "default" : "pointer",
              filter: page.LEGALNOTICE ? "grayscale(100%)" : "none",
            }}
          />
          {/* )} */}
          {/* {page.CUSTOMERNAME && (page.PAGE_NO !== page.PAGE_COUNT || page.PAGE_NO !== 1) && (
          <div style={{ position: "absolute", right: "5px", top: "5px" }}>
            {page.PAGE_NO !== 1 && (
              <button
                title="Download Front Page"
                type="button"
                className="btn btn-dark btn p-2 me-1"
                style={{
                  boxShadow: "black 0px 3px 5px",
                  lineHeight: "10px",
                  borderRadius: "0",
                  backgroundColor: "#0c1e35",
                  border: "1px solid #4a4a4a",
                  color: "#f8ad17",
                }}
                onClick={(e) => getEditionPage(page)}
              >
                <BsNewspaper size={15} />
              </button>
            )}
            {page.PAGE_NO !== page.PAGE_COUNT && page.PAGE_NO !== 1 && (
              <button
                title="Download Facing Page"
                type="button"
                className="btn btn-dark btn p-2 me-1"
                style={{
                  boxShadow: "black 0px 3px 5px",
                  lineHeight: "10px",
                  borderRadius: "0",
                  backgroundColor: "#0c1e35",
                  border: "1px solid #4a4a4a",
                  color: "#f8ad17",
                }}
                onClick={(e) => getEditionPage(page, page.PAGENO % 2 ? page.PAGE_NO - 1 : page.PAGE_NO + 1)}
              >
                <MdMenuBook size={15} />
              </button>
            )}
          </div>
        )} */}
        </div>
      </ContextMenu>
      <div className={`pageInfo mt-2`}>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Date</div>
          <div className="text-truncate">{page.PUBDATE}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Publication</div>
          <div className="text-truncate">{page.PUBLICATION}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Edition</div>
          <div className="text-truncate">{page.EDITION}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Page No.</div>
          <div className="text-truncate">{page.PAGE_NO}</div>
        </div>
        <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
          <div className="me-2 fw-bold text-nowrap">Section</div>
          <div className="text-truncate">{page.SECTION}</div>
        </div>
        {page.CUSTOMERNAME && (
          <>
            <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
              <div className="me-2 fw-bold text-nowrap">Customer</div>
              <div className="text-truncate">{page.CUSTOMERNAME}</div>
            </div>
            <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
              <div className="me-2 fw-bold text-nowrap">Sales Rep</div>
              <div className="text-truncate">{page.SALESREP}</div>
            </div>
            <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
              <div className="me-2 fw-bold text-nowrap">Order ID</div>
              <div className="text-truncate">{page.ORDERID}</div>
            </div>
            <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
              <div className="me-2 fw-bold text-nowrap">Order Item ID</div>
              <div className="text-truncate">{page.ORDERITEMID}</div>
            </div>
            <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
              <div className="me-2 fw-bold text-nowrap">Material ID</div>
              <div className="text-truncate">{page.MATERIALID}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NpaPage;
