import { useEffect, memo, useState } from "react";
import EditionPage from "./EditionPage";
import { FaFilePdf, FaRegFilePdf, FaCode, FaSpellCheck, FaCloudUploadAlt } from "react-icons/fa";

const EditionPages = ({ pageData, index }) => {
  const [showContext, setShowContext] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState([0, 0]);

  // Used to get page size for title block
  const getPageSize = (pages) => {
    for (let i = 0; i < pages.length; i++) {
      if (pages[i].width && pages[i].height) {
        return pages[i].width / pages[i].height;
      }
    }
    return 100 / 100;
  };

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    let year = dateObj.getFullYear();
    let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    let day = dateObj.getDate().toString().padStart(2, "0");
    let hour = dateObj.getHours();
    let minute = dateObj.getMinutes().toString().padStart(2, "0");
    let second = dateObj.getSeconds().toString().padStart(2, "0");
    let ampm = hour < 12 ? "AM" : "PM";
    if(hour === 0){
      hour = 12;
    }else if(hour > 12){
      hour = hour - 12;
    }
    hour = hour.toString();
    return `${hour}:${minute}:${second} ${ampm}, ${day}/${month}`;
  };

  const ContextMenu = (props) => {
    const handleRightClick = (e) => {
      e.preventDefault();
      if (e.altKey) {
        setContextMenuPosition([e.pageX, e.pageY]);
        setShowContext(true);
      } else {
      }
    };

    const handleMouseMove = (e) => {
      if (showContext) {
        setShowContext(false);
      }
    };

    const fetchContextContent = async (type) => {
      // const purlRequest = await callApi(`edition_preview/get_content_purl/${props.chp_page_name}/${type}`);
      // const purl = purlRequest.content.url;
      // window.open(purl, "_blank");
      switch (type) {
        case "resend":
          alert("Would this help Matt?");
          break;

        case "rename_resend":
          alert(
            "The idea here is that we could rename zips + PDFs inside of them, then send to PageSuite for editions we recognise are not included in PageSuite's config. Would only be used as a last minute fix. Thoughts?"
          );
          break;

        default:
          break;
      }
    };

    return (
      <div onContextMenu={handleRightClick} onMouseLeave={handleMouseMove} onClick={() => setShowContext(false)}>
        {props.children}
        <ul
          className="contextMenu list-group dropdown-menu-dark"
          style={{
            boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
            cursor: "pointer",
            position: "absolute",
            zIndex: "2000",
            display: showContext ? "flex" : "none",
            left: contextMenuPosition[0],
            top: contextMenuPosition[1],
          }}
        >
          <li className="list-group-item list-group-item-action dropdown-item p-2 bg-transparent" onClick={() => fetchContextContent("resend")}>
            <FaCloudUploadAlt className="me-2" />
            <small className="position-relative" style={{ top: "1px", fontSize: 14 }}>
              Resend Edition
            </small>
          </li>
          <li className="list-group-item list-group-item-action dropdown-item p-2 bg-transparent" onClick={() => fetchContextContent("rename_resend")}>
            <FaSpellCheck className="me-2" />
            <small className="position-relative" style={{ top: "1px", fontSize: 14 }}>
              Rename + Resend Edition
            </small>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div id="pages">
      {"pages" in pageData && (
        <div className="container-fluid">
          <div className="row">
            <div className={`col-6 col-xl-2 col-xxl-2 col-xxxl-1 dpeTitle mb-3`}>
              <ContextMenu>
                <div
                  className="dpeBlock"
                  style={{
                    aspectRatio: getPageSize(pageData.pages),
                  }}
                >
                  <h6>{pageData.friendly_name}</h6>
                  <p style={{ fontSize: "0.8rem" }}>{pageData.edition}</p>
                </div>
              </ContextMenu>
              <div className={`pageInfo mt-2 `}>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                  <div className="me-2 fw-bold text-nowrap">Last Output</div>
                  <div className="text-truncate">{formatDate(pageData.last_output.output_time)}</div>
                </div>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                  <div className="me-2 fw-bold text-nowrap">Last Page No.</div>
                  <div className="text-truncate">{pageData.last_output.page_no}</div>
                </div>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                  <div className="me-2 fw-bold text-nowrap">Pages (Total)</div>
                  <div className="text-truncate">{pageData.page_count}</div>
                </div>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                  <div className="me-2 fw-bold text-nowrap">Pages (Output)</div>
                  <div className="text-truncate">{pageData.pages_output}</div>
                </div>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                  <div className="me-2 fw-bold text-nowrap">Pages (CHP)</div>
                  <div className="text-truncate">{pageData.pages_in_chp}</div>
                </div>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                  <div className="me-2 fw-bold text-nowrap">Text Extraction</div>
                  <div className="text-truncate">{pageData.text_extraction_required}</div>
                </div>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                  <div className="me-2 fw-bold text-nowrap">Total Stories</div>
                  <div className="text-truncate">{pageData.story_count || 0}</div>
                </div>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                  <div className="me-2 fw-bold text-nowrap">Stories Extracted</div>
                  <div className="text-truncate">{pageData.extracted_story_count || 0}</div>
                </div>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                  <div className="me-2 fw-bold text-nowrap">Total Ads</div>
                  <div className="text-truncate">{pageData.ad_count || 0}</div>
                </div>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow">
                  <div className="me-2 fw-bold text-nowrap">&nbsp;</div>
                  <div className="text-truncate">&nbsp;</div>
                </div>
              </div>
              <div className={`pageInfoCompact mt-2 ${index === 0 ? "" : index % 2 === 0 ? "ms-1" : ""}`}>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                  <div className="me-2 fw-bold text-nowrap">Pages (Total)</div>
                  <div className="text-truncate">{pageData.page_count}</div>
                </div>
                <div className="flex-row d-flex justify-content-between flex-nowrap p-1 pageInfoRow showOnCompact">
                  <div className="me-2 fw-bold text-nowrap">Pages (Output)</div>
                  <div className="text-truncate">{pageData.pages_output}</div>
                </div>
              </div>
            </div>
            {pageData.pages.map((page, i) => (
              <EditionPage key={`${pageData.edition}-${page.page_no}`} data={page} index={i} pageCount={pageData.page_count} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditionPages;
